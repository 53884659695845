import axios, { AxiosError } from 'axios'
import { UnauthorizedException } from './UnauthorizedException'
import { NoPermissionException } from './NoPermissionException'
import { ErrorCodeException } from './ErrorCodeException'

interface ErrorListener {
    errorCode: number,
    listener: (error: AxiosError) => void,
}

let listeners: ErrorListener[] = []

export const unovisionClient = axios.create()

unovisionClient.defaults.headers.post['Content-Type'] = 'application/json'

unovisionClient.interceptors.response.use(undefined, (err: any) => {
    console.error(err.message)
    if(!err.isAxiosError) return

    const error = err as AxiosError
    if(!error.response) return

    listeners.filter(e => e.errorCode === error.response?.status)
        .forEach(e => setTimeout(() => e.listener(error), 0))

    if(error.response.status === 401) {
        throw new UnauthorizedException('User is not authorized')
    } else if(error.response.status === 403) {
        throw new NoPermissionException('Insufficient permissions')
    } else {
        throw new ErrorCodeException(error.response.status, error.response.data)
    }
})

export function addErrorListener(errorCode: number, listener: (error: AxiosError) => void): () => void {
    listeners.push({errorCode, listener})
    return () => removeErrorListener(listener, errorCode)
}

export function removeErrorListener(listener: (error: AxiosError) => void, errorCode?: number): void {
    listeners = listeners.filter(e => listener !== e.listener && (!errorCode || errorCode !== e.errorCode))
}

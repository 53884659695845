import React, { ReactNode, useEffect, useState } from 'react'
import {getStoredCurrentUser, JwtAccessTokenType, JwtType, logout} from '../api/common'
import { addErrorListener } from '../api/unovisionClient'
import { getTenantInfo } from '../api/tenant'
import { AddressModel } from '../models/AddressModel'
import {decodeJWT} from "../helpers/JWTParser";

export type UserContextType = [JwtType | null, (response: JwtType | null) => void, JwtAccessTokenType | undefined]

export const UserContext = React.createContext<UserContextType>([null, () => {
}, undefined])

export const TenantContext = React.createContext<AddressModel | undefined>(undefined)

export type StorePropType = {
    children: ReactNode
}

export const Store = ({children}: StorePropType) => {
    const [tenant, setTenant] = useState<AddressModel>()
    const [currentUser, setCurrentUser] = useState<JwtType | null>(null)
    const [currentUserToken, setCurrentUserToken] = useState<JwtAccessTokenType | undefined>(undefined)
    const storedCurrentUser = getStoredCurrentUser()

    useEffect(() => {
        if(storedCurrentUser && (currentUser == null || storedCurrentUser.access_token !== currentUser.access_token)) {
            setCurrentUser(storedCurrentUser)
        }
    }, [currentUser, storedCurrentUser])

    useEffect(() => {
        if (currentUser) {
            setCurrentUserToken(decodeJWT<JwtAccessTokenType>(currentUser.access_token))
        } else {
            setCurrentUserToken(undefined)
        }
    }, [currentUser])

    useEffect(() => {
        return addErrorListener(401, () => {
            logout()
            setCurrentUser(null)
        })
    }, [setCurrentUser])

    useEffect(() => {
        getTenantInfo().then(response => {
            setTenant(response)
        })
    }, [])

    return (
        <TenantContext.Provider value={tenant}>
            <UserContext.Provider value={[currentUser, setCurrentUser, currentUserToken]}>
                {children}
            </UserContext.Provider>
        </TenantContext.Provider>
    )
}

import {Box, Button, Form, FormField, Layer, Text,} from 'grommet'
import React, {useCallback, useState} from 'react'
import {UnauthorizedException} from '../../../api/UnauthorizedException'
import {LabeledFormDialog} from '../../../components/common/LabeledFormDialog'
import {useCurrentUser} from '../../../hooks/useCurrentUser'
import {required} from '../../../components/inputs/validation'
import {ControlButtons} from '../../../components/buttons/ControlButtons'
import {CampaignType, createCampaign, createChatbot} from '../../../api/campaigns'
import {isUnovision} from '../../../api/roles'

type FormTypes = 'edit' | 'create'

type ChatbotCreationStatus = undefined | 'loading' | 'created'

interface FormProps {
    onHide: () => void
    reload: () => void
    type: FormTypes
    formValues?: CampaignType
}

const buildInitialValueFromFormValues = (formValues: CampaignType) => ({
    ...formValues,
})

const FormView =
    ({
         onHide,
         reload,
         type,
         formValues,
     }: FormProps) => {

        const [currentUser, setCurrentUser] = useCurrentUser()

        const [value, setValue] = useState<CampaignType>((formValues && buildInitialValueFromFormValues(formValues)) as CampaignType)
        const [isCreatingChatbot, setIsCreatingChatbot] = useState<ChatbotCreationStatus>(undefined)

        const handleSubmit = useCallback(async({value}: { value: CampaignType }) => {
            try {
                const {name} = value
                if(name) {
                    // TODO currently we only create
                    // if(formValues && type === 'edit') {
                    //     // await updateCampaign(value)
                    // } else {
                        await createCampaign({
                            campaignName: value.name,
                            campaignAvatarUrl: value.avatarUrl,
                            campaignDescription: value.description
                        })
                    // }
                    reload()
                    onHide()
                }
            } catch(e) {
                if(e instanceof UnauthorizedException) {
                    setCurrentUser(null)
                }
            }
        }, [reload, onHide, formValues, setCurrentUser, type])

        const handleCancel = useCallback(async() => {
            setValue((formValues && buildInitialValueFromFormValues(formValues)) as CampaignType)
            onHide()
        }, [onHide, formValues])

        const onCreateChatbot = async() => {
            setIsCreatingChatbot("loading")
            try {
                const newValue = value
                const chatbot = await createChatbot(value.id)
                newValue.chatbotId = chatbot.id
                setValue(newValue)
                reload()
            } catch(e) {
                alert("Der Chatbot für die Anzeigenregion " + value.name + " konnte nicht erzeugt werden")
                if(e instanceof UnauthorizedException) {
                    setCurrentUser(null)
                }
            }
            setIsCreatingChatbot("created")
        }

        const ChatbotFormField = () => {
            if (isUnovision(currentUser)!! && type === 'edit') {
                return (
                    <>
                        <FormField name={'chatbotId'} label={'ChatbotId'} disabled={true}/>
                        {!isCreatingChatbot && <Button type={'button'} label={'Erzeuge Chatbot'} onClick={onCreateChatbot}/>}
                        {isCreatingChatbot === "loading" && <Text size={'small'}>Der Chatbot wird im Hintergrund erzeugt. Sie können diese Seite verlassen</Text>}
                        {isCreatingChatbot === "created" && <Text size={'small'}>Der Chatbot wurde erfolgreich erzeugt. Sie können diese Seite verlassen</Text>}
                    </>
                )
            } else {
                return null
            }
        }

        return (
            <Layer>
                <LabeledFormDialog label={type === 'edit' ? 'Anzeigenregion bearbeiten' : 'Anzeigenregion erstellen'}>
                    <Form<CampaignType>
                        value={value}
                        onChange={(nextValue: CampaignType) => setValue(nextValue)}
                        onSubmit={handleSubmit}
                        onReset={handleCancel}>
                        <FormField name={'name'} label={'Name'} validate={required}
                                   disabled={!!(formValues && formValues.name)}/>
                        <FormField name={'description'} label={'Beschreibung'}/>
                        <FormField name={'avatarUrl'} label={'Avatar URL'} validate={required}/>
                        <ChatbotFormField/>
                        <ControlButtons>
                            <Button type={'submit'} primary label={'Submit'} disabled={type === 'edit'}/>
                            <Button type={'reset'} label={'Cancel'}/>
                        </ControlButtons>
                        {//TODO Remove when Edit is implemented
                        }
                        {type === "edit" &&
                            <Box width="100%" margin={{top: "small"}}>
                                <Text size="small" alignSelf="end">Editieren von Anzeigeregionen ist derzeit deaktiviert</Text>
                            </Box>
                        }
                    </Form>
                </LabeledFormDialog>
            </Layer>
        )
    }

interface DialogProps {
    reload: () => void
    type: FormTypes
    formValues?: CampaignType
    render: (handler: () => void) => JSX.Element
}

export const CampaignDialog =
    ({
         reload,
         type,
         formValues,
         render,
     }: DialogProps) => {
        const [isFormVisible, setFormVisible] = useState(false)

        const handleShow = useCallback(() => {
            setFormVisible(!isFormVisible)
        }, [setFormVisible, isFormVisible])

        return (
            <>
                {isFormVisible && (
                    <FormView formValues={formValues} type={type} onHide={handleShow} reload={reload}/>
                )}
                {render(handleShow)}
            </>
        )
    }

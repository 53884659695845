export class ErrorCodeException {

    code: number
    name: string
    data?: { errorCode: string, message: string }

    constructor(code: number, data?: { errorCode: string, message: string }) {
        this.code = code
        this.name = 'ErrorCodeException'
        this.data = data
    }
}

import { unovisionClient } from './unovisionClient'
import { Dispatch, SetStateAction } from 'react'
import { ErrorCodeException } from './ErrorCodeException'
import { NoPermissionException } from './NoPermissionException'
import { UnauthorizedException } from './UnauthorizedException'

export type JwtType = {
    username: string;
    roles: string[];
    access_token: string;
    refresh_token: string;
    token_type: string;
    expires_in: number;
    clientId?: string;
}

export type JwtAccessTokenType = {
    sub: string
    nbf: number
    roles: string[]
    iss: string
    exp: number
    iat: number
    client_id: string
}

export const CURRENT_USER_KEY = 'currentUser'

export const authenticate = async(username: string, password: string, clientId?: String) => {
    const response = await unovisionClient.post('/login', {
        username,
        password,
        clientId,
    })

    const currentUser: JwtType = response.data

    unovisionClient.defaults.headers.common['Authorization'] = `Bearer ${currentUser.access_token}`
    sessionStorage.setItem(CURRENT_USER_KEY, JSON.stringify(currentUser))

    return currentUser
}

export const logout = () => {
    unovisionClient.defaults.headers.common['Authorization'] = null
    sessionStorage.removeItem(CURRENT_USER_KEY)
}

export const getStoredCurrentUser = (): JwtType | null => {
    const storedUserEncoded = sessionStorage.getItem(CURRENT_USER_KEY)

    if(storedUserEncoded) {
        const storedUser = JSON.parse(storedUserEncoded)

        unovisionClient.defaults.headers.common['Authorization'] = `Bearer ${storedUser.access_token}`

        return storedUser
    }

    return null
}

let timerId: number | undefined
export const debounceFunction = function(func: Function, delay: number) {
    clearTimeout(timerId)

    timerId = setTimeout(func, delay)
}

export const getDownload = async(url: string, fileName: string) => {
    const response = await unovisionClient.get<Blob>(url, {
        responseType: 'blob',
    })

    let anchor = document.createElement('a')
    document.body.appendChild(anchor)
    let objectUrl = window.URL.createObjectURL(response.data)
    anchor.href = objectUrl
    anchor.download = fileName
    anchor.click()
    window.URL.revokeObjectURL(objectUrl)

    return Promise.resolve()
}

export const handleServiceCall = async(callService: () => void,
                                       setLoading: Dispatch<SetStateAction<boolean>>,
                                       onUnauthorized: () => void, onError?: (errorCode: number) => void) => {
    try {
        setLoading(true)
        await callService()
    } catch(e) {
        if(e instanceof UnauthorizedException) {
            onUnauthorized()
        } else if(e instanceof NoPermissionException) {
            alert('Sie haben keine Rechte für diese Informationen.')
        } else if(e instanceof ErrorCodeException) {
            if(onError) {
                onError(parseInt(`${e.code}`))

                return
            }

            alert('Es ist ein Fehler aufgetreten: ' + e.code)
        } else if(e instanceof Error) {
            alert('Es ist ein Fehler aufgetreten: ' + e.message)
        } else {
            alert('Ein Fehler ist aufgetreten')
            console.log('Unknown error occured', e)
        }
    } finally {
        setLoading(false)
    }
}

export function buildExportFileName(type: string, extension = 'csv'): string {
    const date = new Date()

    return `${type}_${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}.${extension}`
}

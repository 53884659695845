import { unovisionClient } from './unovisionClient'
import { AddressModel } from '../models/AddressModel'
import {JwtAccessTokenType, JwtType} from "./common";
import {decodeJWT} from "../helpers/JWTParser";


const CLIENT_CONTEXT_URL = '/api/client'
const CLIENT_USERS_CONTEXT_URL = '/api/client-users'

export const getTenantInfo = async(): Promise<AddressModel> => {

    //Todo Store Tenant in BE
    const tenantInfo = {
        id: 1,
        companyName: 'VKAD',
        street: 'Biebricher Allee',
        streetNumber: '8',
        addressAddition: '',
        postcode: '65183',
        city: 'Wiesbaden',
    }

    return {
        id: tenantInfo.id,
        companyName: tenantInfo.companyName,
        street: tenantInfo.street,
        streetNumber: tenantInfo.streetNumber,
        addressAddition: tenantInfo.addressAddition,
        postcode: tenantInfo.postcode,
        city: tenantInfo.city
    }
}

export interface Client2UserId {
    clientId: string
    username: string
}

export interface Client2UserEntity {
    id: Client2UserId
}

export interface TenantData {
    name: string
}

export interface TenantType extends TenantData {
    id: string
    name: string
}

export interface CreateClient {
    clientName: string
}

export const getMyClients = async(): Promise<TenantType[]> => {
    const response = await unovisionClient.get(CLIENT_CONTEXT_URL)
    return response.data
}

export const getClientById = async(clientId: string): Promise<TenantType> => {
    const response = await unovisionClient.get(`${CLIENT_CONTEXT_URL}/${clientId}`)
    return response.data
}

export const deleteClient = async(id: string): Promise<void> => {
    const response = await unovisionClient.delete(`${CLIENT_CONTEXT_URL}/${id}`)
    return response.data
}

export const createTenant = async(createClient: CreateClient): Promise<void> => {
    const response = await unovisionClient.post(`${CLIENT_CONTEXT_URL}`, createClient)
    return response.data
}

export const getClientUserRelation = async(): Promise<Client2UserEntity[]> => {
    const response = await unovisionClient.get(`${CLIENT_USERS_CONTEXT_URL}/`)
    return response.data
}

export const attachUserToClient = async(clientId: string, userName: string): Promise<void> => {
    const response = await unovisionClient.post(`${CLIENT_CONTEXT_URL}/${clientId}/user/${userName}`)
    return response.data
}

export const removeUserToClient = async(tenantId: string, userName: string): Promise<void> => {
    const response = await unovisionClient.delete(`${CLIENT_CONTEXT_URL}/${tenantId}/user/${userName}`)
    return response.data
}

export const getCurrentClientId = (user: JwtType): string => {
    return decodeJWT<JwtAccessTokenType>(user.access_token).client_id
}

export const getAllClientsAsSuperuser = async(): Promise<TenantType[]> => {
    const response = await unovisionClient.get(`/api/client-superuser`)
    return response.data
}

import React from 'react'
import { Box, ResponsiveContext, Stack, Text } from 'grommet'

type RichTabTitleProps = {
    label: string
    isActive: boolean
    counter?: number
    badge?: boolean
}

export const RichTabTitle = (props: RichTabTitleProps) =>
    <ResponsiveContext.Consumer>
        {screenSize => {
            const isScreenSizeSmall = screenSize === 'small'
            return <Stack anchor='top-right'>
                <Box pad={{right: 'xxsmall'}}>
                    <Box pad={isScreenSizeSmall ? {right: 'xsmall'} : {right: 'small'}}>
                        <Text
                            size={isScreenSizeSmall ? 'small' : 'large'}
                            weight={props.isActive ? 'bold' : 'normal'}
                            color={props.isActive ? 'brand' : 'dark-6'}>
                            {props.label} {props.counter !== undefined && `(${props.counter})`}
                        </Text>
                    </Box>
                </Box>
                {props.badge && <Box
                    margin={{right: 'xxsmall'}}
                    background='status-ok'
                    pad='xxsmall'
                    round='xxsmall'
                />}
            </Stack>
        }
        }
    </ResponsiveContext.Consumer>

import React from 'react'
import { BasePage } from '../../components/layout/BasePage'
import { BaseButton } from '../../components/buttons/BaseButton'
import { Box } from 'grommet'
import { useHistory } from 'react-router-dom'

export const HomePage: React.FC = () => {
    const history = useHistory()
    return (
        <BasePage>
            <Box justify='center' align='center'>
                <Box
                    pad='large'
                    width='medium'
                    background='light-2'
                    border={{color: 'light-3', size: 'small'}}
                    round='small'
                    gap='small'
                >
                    <BaseButton
                        primary
                        label='Zum Login'
                        onClick={() => history.push('/login')}/>
                    <BaseButton
                        label='Subscribe für Neuigkeiten'
                        onClick={() => history.push('/subscribe')}/>
                </Box>
            </Box>
        </BasePage>
    )
}

import { Box, Text } from 'grommet'
import { StatusBadge } from '../badges/StatusBadge'
import React from 'react'
import { BoxExtendedProps } from 'grommet/components/Box'
import { DisableableBox } from './DisableableBox'

interface CounterBadgeContainerProps extends BoxExtendedProps {
    background: string
    counter: number | string
    label?: string
    disabled?: boolean
}

export const CounterBadgeContainer = (props: CounterBadgeContainerProps) => {
    const {background, counter, label, pad = 'small'} = props
    return <DisableableBox
        disabled={props.disabled}
        direction='row'
        align='center'
        gap='small'
        pad={pad}
        justify={label ? 'start' : 'end'}
    >
        <StatusBadge label={counter} background={background}/>
        {label && <Box width='xsmall'>
            <Text size='small'>{label}</Text>
        </Box>}
    </DisableableBox>
}

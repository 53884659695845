import { useCallback, useEffect, useState } from 'react'
import { getJobsByCampaignId, JobType, saveJob as saveJobApi, updateJob as updateJobApi } from '../api/jobs'
import { useCurrentCampaign } from './useCurrentCampaign'

export const useCampaignJobs = () => {
    const currentCampaign = useCurrentCampaign()
    const [allCampaignJobs, setAllCampaignJobs] = useState<JobType[] | undefined>(undefined)

    useEffect(() => {
        currentCampaign && getJobsByCampaignId(currentCampaign.id).then(
            (response) => {
                setAllCampaignJobs(response)
            })
    }, [currentCampaign])

    const addCampaignJob = useCallback((job: JobType) => {
        setAllCampaignJobs(allCampaignJobs?.filter(data => data.id !== job.id)?.concat(job))
    }, [setAllCampaignJobs, allCampaignJobs])

    const updateJob = useCallback((job, descriptionList) => {
        updateJobApi({
            job: job,
            description: descriptionList
        }).then(response => addCampaignJob(response))
    }, [addCampaignJob])

    const saveJob = useCallback((jobName, descriptionList) => {
        currentCampaign && saveJobApi({
            campaignId: currentCampaign.id,
            jobName: jobName,
            description: descriptionList
        }).then(response => addCampaignJob(response))
    }, [addCampaignJob, currentCampaign])

    return {
        allCampaignJobs: allCampaignJobs,
        addCampaignJob: addCampaignJob,
        saveJob: saveJob,
        updateJob: updateJob
    }
}

export const required = [
    (value: string) => value == null || value === undefined || value.length === 0 ? 'Muss ausgefüllt sein.' : undefined,
]

export const validateEmail = {
    state: 'error',
    regexp:  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    message: 'Bitte geben Sie eine valide E-Mail-Adresse ein.',
}

export const validateNumberLZ = {
    state: 'error',
    regexp: /^\d*$/,
    message: 'Nur Zahlen erlaubt.',
}

export const validatePhone = {
    state: 'error',
    regexp: /[0-9]{2,}/,
    message: 'Bitte geben Sie eine gültige Telefonnummer ein.',
}

export const validateNumber = {
    state: 'error',
    regexp: /^[1-9]\d*$/,
    message: 'Nur Zahlen erlaubt ohne führende Nullen erlaubt.',
}

export const validateDecimal = {
    state: 'error',
    regexp: /^([1-9]\d*|0)(\.\d*)?$/,
    message: 'Dezimalzahl mit Kommapunkt (0 und 0.0).',
}

export const validateCurrency = {
    state: 'error',
    regexp: /^([1-9]\d*|0)\.\d\d$/,
    message: 'Währung mit Kommapunkt (0.00).',
}

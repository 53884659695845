import React, { PropsWithChildren } from 'react'
import { Box, Heading, ResponsiveContext, Stack } from 'grommet'
import { StatusBadge, StatusBadgeType } from '../badges/StatusBadge'

//TODO Title shouldnt be part of the banner
export type BannerContainerProps = PropsWithChildren<{
    title?: string,
    statusBadge?: StatusBadgeType,
}>

export const BannerContainer: React.FC<BannerContainerProps> = (props: BannerContainerProps) =>
    <ResponsiveContext.Consumer>
        {screenSize => {
            const isScreenSizeSmall = screenSize === 'small'
            return (
                <Box height={{min: 'auto'}}
                     pad={!isScreenSizeSmall ? {horizontal: 'medium', bottom: 'medium'} : undefined}>
                    {!isScreenSizeSmall &&
                    <Heading level='3' color='brand' margin={{top: 'none', bottom: 'medium'}}>{props.title}</Heading>}
                    <Stack anchor='top-left'>
                        <Box
                            justify={isScreenSizeSmall ? 'center' : 'start'}
                            background={!isScreenSizeSmall ? 'light-1' : ''}
                            direction='row'
                            round={!isScreenSizeSmall ? 'small' : undefined}
                            elevation={isScreenSizeSmall ? undefined : 'small'}
                            overflow='hidden'
                        >
                            {isScreenSizeSmall ?
                                <Box>
                                    {isScreenSizeSmall && <StatusBadge
                                        margin={{bottom: 'small', top: 'medium', left: 'small'}}
                                        label={props.statusBadge?.label}
                                        background={props.statusBadge?.background}/>}
                                    {props.children}
                                </Box> :
                                <>
                                    {props.children}
                                </>
                            }
                        </Box>
                        {!isScreenSizeSmall && <StatusBadge
                            translateY='-50%'
                            margin={{left: 'small'}}
                            label={props.statusBadge?.label}
                            background={props.statusBadge?.background}/>}
                    </Stack>
                </Box>
            )
        }}
    </ResponsiveContext.Consumer>

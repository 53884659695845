import React from 'react'
import {BasePage, PageWithBreadcrumbsProps} from '../../components/layout/BasePage'
import {MessagesContent} from "./MessagesContent";

export const MessagesPage: React.FC<PageWithBreadcrumbsProps> = ({crumbs}: PageWithBreadcrumbsProps) => {
    return <BasePage
        noPad
        hideBackButton
        crumbs={crumbs}
        title={'Nachrichten'}
    >
        <MessagesContent/>
    </BasePage>
}

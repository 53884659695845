import React from 'react'
import { Box, ResponsiveContext, Stack, Text } from 'grommet'
import { DisableableBox } from '../common/DisableableBox'
import { Route } from 'react-router-dom'

type MainNavigationItemProps = {
    icon: JSX.Element
    label: string
    to: string
    badgeColor?: string
    showBadge?: boolean
    disabled?: boolean
}

export const MainNavigationItem = ({icon, label, to, badgeColor, disabled, showBadge}: MainNavigationItemProps) =>
    <Route
        path={to}
        children={({match, history}) => (
            <DisableableBox
                disabled={disabled}
                onClick={() => history.push(to)}
                round='small'
                background={match ? 'rgba(255,255,255,0.2)' : undefined}
                width='xsmall'
            >
                <ResponsiveContext.Consumer>
                    {screenSize =>
                        <Stack anchor='top-right'>
                            <Box justify='center' align='center' gap='xsmall' pad='small'>
                                {icon}
                                <Text size={screenSize === 'small' ? 'xsmall' : 'small'}>{label}</Text>
                            </Box>
                            {showBadge ?
                                <Box pad='xsmall' round='xsmall' margin='small'
                                     background={badgeColor ? badgeColor : 'status-ok'}/> : null}
                        </Stack>
                    }
                </ResponsiveContext.Consumer>
            </DisableableBox>
        )}
    />

import React from 'react'
import { Box, Button, ButtonProps } from 'grommet'

export type BaseButtonProps = ButtonProps & {
    icon?: JSX.Element,
    onClick?: (...args: any[]) => void,
};

export const BaseButton: React.FC<BaseButtonProps> = ({icon, label, onClick, ...rest}) => (
    <Button
        {...rest}
        onClick={onClick}
        label={
            <Box direction='row' align='center'>
                {icon || null}
                <Box flex align='center' margin={{horizontal: 'small'}}>{label}</Box>
            </Box>
        }
    />
)

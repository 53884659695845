import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { JobBenefitType, SaveJobBenefitType } from '../../../api/jobBenefits'
import {
    AdType,
    getAd,
    updateAd,
    updateAdImage,
    updateAdJobBenefits,
    updateAdJobs,
    updateAdStatus as updateAdStatusCall,
    UpdateAdType,
    updateAdVideo
} from '../../../api/ads'
import { useCampaignJobBenefits } from '../../../hooks/useCampaignJobBenefits'
import { useCampaignJobs } from '../../../hooks/useCampaignJobs'
import { AdModel } from '../../../models/AdModel'
import { JobDescriptionType, JobType } from '../../../api/jobs'
import { JobModel } from '../../../models/JobModel'

const AdDetailContext = React.createContext<Options | undefined>(undefined)

export type StorePropType = {
    children: ReactNode
}

type Options = {
    currentAd: AdModel | undefined,
    updateCurrentAdAndJobBenefits: (adUpdate: UpdateAdType, jobBenefits: SaveJobBenefitType[]) => void,
    updateCurrentAdImage: (mediaId: string) => void,
    updateCurrentAdVideo: (mediaId: string) => void,
    updateCurrentAdJobs: (jobs: JobType[]) => void,
    updateCurrentAdStatus: (active: boolean) => void,
    availableJobs: JobType[] | undefined,
    saveJobViaContext: (isNew: boolean, job: JobModel, jobDescriptionList: JobDescriptionType[]) => void
    availableJobBenefits: JobBenefitType[] | undefined,
}

function mapToModel(storedAd: AdType): AdModel {
    return {
        id: storedAd.id,
        title: storedAd.title,
        textHeader: storedAd.textHeader,
        textFooter: storedAd.textFooter,
        image: storedAd.image,
        video: storedAd.video,
        active: storedAd.status === 'ACTIVE',
        jobs: storedAd.jobs || [],
        selectedJobBenefits: storedAd.selectedJobBenefits || []
    }
}

const AdDetailContextProvider = ({children}: StorePropType) => {
    const {adId} = useParams<any>()
    const {allCampaignJobs, saveJob, updateJob} = useCampaignJobs()
    const {allCampaignJobBenefits, saveCampaignJobBenefits} = useCampaignJobBenefits()
    const [currentAd, setCurrentAd] = useState<AdModel | undefined>(undefined)

    function updateAdAndJobBenefits(adUpdate: UpdateAdType, existingJobBenefits: JobBenefitType[]) {
        Promise.all([
            updateAd(adId, adUpdate),
            updateAdJobBenefits(adId, {
                selectedJobBenefits: existingJobBenefits.map((value, index) => {
                    return {
                        jobBenefitId: value.id,
                        position: index
                    }
                })
            })
        ]).then((resultArray) => {
            setCurrentAd(mapToModel(Object.assign(resultArray[0], resultArray[1])))
        })
    }

    async function updateCurrentAdAndJobBenefits(adUpdate: UpdateAdType, jobBenefits: SaveJobBenefitType[]) {
        saveCampaignJobBenefits(jobBenefits).then(result => {
            updateAdAndJobBenefits(adUpdate, result || [])
        })
    }

    async function updateCurrentAdJobs(jobs: JobType[]) {
        const result = await updateAdJobs(adId, {jobIds: jobs.map(value => value.id)})
        setCurrentAd(mapToModel(result))
    }

    async function updateCurrentAdImage(mediaId: string) {
        const result = await updateAdImage(adId, mediaId)
        setCurrentAd(mapToModel(result))
    }

    async function updateCurrentAdVideo(mediaId: string) {
        const result = await updateAdVideo(adId, mediaId)
        setCurrentAd(mapToModel(result))
    }

    async function updateCurrentAdStatus(active: boolean) {
        const result = await updateAdStatusCall(adId, active)
        setCurrentAd(mapToModel(result))
    }

    useEffect(() => {
        getAd(adId).then(result => {
            setCurrentAd(mapToModel(result))
        })
    }, [adId])

    const saveJobViaContext = useCallback((isNew: boolean, job: JobModel, jobDescriptionList: JobDescriptionType[]) => {
        if(isNew) {
            saveJob(job.name, jobDescriptionList.map(j => j.text))
        } else {
            updateJob(job, jobDescriptionList)
        }
    }, [saveJob, updateJob])

    return (
        <AdDetailContext.Provider value={{
            currentAd,
            updateCurrentAdAndJobBenefits: updateCurrentAdAndJobBenefits,
            updateCurrentAdImage: updateCurrentAdImage,
            updateCurrentAdVideo: updateCurrentAdVideo,
            updateCurrentAdJobs: updateCurrentAdJobs,
            updateCurrentAdStatus: updateCurrentAdStatus,
            availableJobs: allCampaignJobs,
            saveJobViaContext: saveJobViaContext,
            availableJobBenefits: allCampaignJobBenefits,
        }}>
            {children}
        </AdDetailContext.Provider>
    )
}

function useAdDetailContext() {
    const context = React.useContext(AdDetailContext)
    if(context === undefined) {
        throw new Error('useAdDetails must be used within a AdDetailsContextProvider')
    }
    return context
}

export { AdDetailContextProvider, useAdDetailContext }

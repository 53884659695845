import {Button, CheckBox, CheckBoxGroup, Form, FormField, Layer, Select, TextInput} from 'grommet'
import React, { useCallback, useState } from 'react'
import { createUser, updateUser, UserType } from '../../../api/users'
import { roleOptions } from '../../../api/roles'
import { UnauthorizedException } from '../../../api/UnauthorizedException'
import { LabeledFormDialog } from '../../../components/common/LabeledFormDialog'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { required, validateEmail } from '../../../components/inputs/validation'
import { ControlButtons } from '../../../components/buttons/ControlButtons'
import {attachUserToClient, TenantType} from "../../../api/tenant";

type FormTypes = 'edit' | 'create'

interface FormProps {
    onHide: () => void;
    reload: () => void;
    type: FormTypes;
    allTenants: TenantType[];
    formValues?: UserType;
}

const buildInitialValueFromFormValues = (formValues: UserType) => ({
    ...formValues,
})

const buildFlatFormValues = (formValues: UserType): UserType => ({
    ...formValues,
})

const FormView =
    ({
         onHide,
         reload,
         type,
         allTenants,
         formValues,
     }: FormProps) => {

        const [currentUser, setCurrentUser] = useCurrentUser()

        const [value, setValue] = useState<UserType>((formValues && buildInitialValueFromFormValues(formValues)) as UserType)

        const createUserAndClient = async (user: UserType): Promise<void> => {
            await createUser(buildFlatFormValues(user))
            if(user.clientId) {
                await attachUserToClient(user.clientId, user.username)
            }
        }

        const handleSubmit = useCallback(async({value}: { value: UserType }) => {
            try {
                const { username } = value
                if(username) {
                    if(formValues && type === 'edit') {
                        await updateUser(buildFlatFormValues(value))
                    } else {
                        await createUserAndClient(value)
                    }
                    reload()
                    onHide()
                }
            } catch(e) {
                if(e instanceof UnauthorizedException) {
                    setCurrentUser(null)
                }
            }
        }, [reload, onHide, formValues,
            setCurrentUser, type])

        const handleCancel = useCallback(async() => {
            setValue((formValues && buildInitialValueFromFormValues(formValues)) as UserType)
            onHide()
        }, [onHide, formValues])

        return (
            <Layer>
                <LabeledFormDialog label={type === 'edit' ? 'Edit Backoffice User' : 'Create Backoffice User'}>
                    <Form<UserType>
                        value={value}
                        onChange={(nextValue: UserType) => setValue(nextValue)}
                        onSubmit={handleSubmit}
                        onReset={handleCancel}>
                        <FormField name={'username'} label={'Username'} validate={required}
                                   disabled={!!(formValues && formValues.username)}/>
                        <FormField name={'firstname'} label={'Vorname'} validate={required}/>
                        <FormField name={'name'} label={'Nachname'} validate={required}/>
                        <FormField name={'email'} label={'Email'} validate={required.concat(validateEmail as any)}/>
                        <FormField name={'clientId'} label={'Kunde'} validate={required}>
                            <Select
                                name='clientId'
                                placeholder='Kunde'
                                options={allTenants.map(client => {
                                    return {
                                        id: client.id,
                                        name: client.name
                                    }
                                })}
                                labelKey='name'
                                valueKey={{key: 'id', reduce: true}}
                            />
                        </FormField>
                        <FormField name={'password'} label={'Password'} validate={type === 'create' ? required : []}>
                            <TextInput type={'password'} name={'password'}/>
                            {formValues && (
                                'If password empty current password will be preserved.'
                            )}
                        </FormField>
                        <FormField name={'roles'} label={'User Role'}>
                            <CheckBoxGroup labelKey={'label'} valueKey={'name'} name={'roles'} options={roleOptions}/>
                        </FormField>
                        <FormField name={'enabled'} label={'Enabled'}>
                            <CheckBox name={'enabled'} disabled={currentUser?.username === value?.username && type === 'edit'}/>
                        </FormField>
                        <ControlButtons>
                            <Button type={'submit'} primary label={'Submit'}/>
                            <Button type={'reset'} label={'Cancel'}/>
                        </ControlButtons>
                    </Form>
                </LabeledFormDialog>
            </Layer>
        )
    }

interface DialogProps {
    reload: () => void;
    type: FormTypes;
    allTenants: TenantType[]
    formValues?: UserType;
    render: (handler: () => void) => JSX.Element;
}

export const UserDialog =
    ({
         reload,
         type,
         formValues,
         allTenants,
         render,
     }: DialogProps) => {
        const [isFormVisible, setFormVisible] = useState(false)

        const handleShow = useCallback(() => {
            setFormVisible(!isFormVisible)
        }, [setFormVisible, isFormVisible])

        return (
            <>
                {isFormVisible && (
                    <FormView formValues={formValues} type={type} allTenants={allTenants} onHide={handleShow} reload={reload}/>
                )}
                {render(handleShow)}
            </>
        )
    }

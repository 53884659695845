export const formatTimestampToDateAndTime = (dateString: string) => {
    const parsedDate = new Date(dateString)
    if(isNaN(parsedDate.getTime())) return 'Kein Datum vorhanden'

    const month = parsedDate.getMonth() + 1
    const day = parsedDate.getDate()
    const hour = parsedDate.getHours()
    const minute = parsedDate.getMinutes()
    return `${(day > 9 ? '' : '0') + day}.${(month > 9 ? '' : '0') + month}.${parsedDate.getFullYear()} - ${(hour > 9 ? '' : '0') + hour}:${(minute > 9 ? '' : '0') + minute}`
}

export const formatTimestampToDate = (dateString: string) => {
    const parsedDate = new Date(dateString)
    if(isNaN(parsedDate.getTime())) return 'Kein Datum vorhanden'

    const month = parsedDate.getMonth() + 1
    const day = parsedDate.getDate()
    return `${(day > 9 ? '' : '0') + day}.${(month > 9 ? '' : '0') + month}.${parsedDate.getFullYear()}`
}

import React from 'react'
import { BasePage, PageWithBreadcrumbsProps } from '../../components/layout/BasePage'
import { JobApplicationsContent } from './JobApplicationsContent'

export const JobApplicationsPage: React.FC<PageWithBreadcrumbsProps> = ({crumbs}: PageWithBreadcrumbsProps) => {
    return <BasePage
        noPad
        hideBackButton
        crumbs={crumbs}
        title={'Bewerber Management'}
    >
        <JobApplicationsContent/>
    </BasePage>
}

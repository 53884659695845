import {UserManager} from 'grommet-icons'
import {Buildings, Cardholder} from 'phosphor-react'
import React from 'react'
import {isUnovision} from '../../../api/roles'
import {useCurrentUser} from '../../../hooks/useCurrentUser'
import {NavigationItem} from './NavigationItem'

export const Navigation = () => {
    const [user, setUser, userToken] = useCurrentUser()
    return (
        <>
            {!isUnovision(user) ? (
                <>
                    <NavigationItem icon={<UserManager/>} to={'/profile'}>Profil</NavigationItem>
                </>
            ) : null}
            {isUnovision(user) ? (
                <>
                    <NavigationItem icon={<UserManager/>} to={'/admin/users'}>Benutzer</NavigationItem>
                    <NavigationItem icon={<Buildings/>} to={'/admin/tenants'}>Kunden</NavigationItem>
                    {userToken?.client_id &&<NavigationItem icon={<Cardholder/>} to={'/admin/campaigns'}>Anzeigenregionen</NavigationItem>}
                </>
            ) : null}
        </>
    )
}

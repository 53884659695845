import React, {ReactNode} from 'react'
import {Box, Heading, Paragraph, Stack, Text} from 'grommet'
import {useTenant} from '../../../../hooks/useTenant'
import {ContactModel} from '../../../../models/ContactModel'
import {AddressModel} from '../../../../models/AddressModel'
import {JobModel} from '../../../../models/JobModel'
import {FormEdit} from 'grommet-icons'

type TextContentMarkdownProps = {
    children?: string
}

export type ContainerMarkdownProps = {
    children?: ReactNode,
}

export const UpdatedDateMarkdownComponent = (updatedDate: Date | undefined) => {
    if(updatedDate) {
        const date = new Date(updatedDate)
        return `${date.getUTCDate()}.${date.getUTCMonth() + 1}.${date.getUTCFullYear()}`
    }
    return 'Error while loading Date'
}

export const ParagraphMarkdownComponent = ({children}: TextContentMarkdownProps) =>
    <Paragraph fill>
        {children}
    </Paragraph>

export const PreWrapParagraphMarkdownComponent = ({children}: TextContentMarkdownProps) =>
    <Paragraph fill style={{whiteSpace:"pre-wrap"}}>
        {children}
    </Paragraph>

export const HeadingMarkdownComponent = ({children}: TextContentMarkdownProps) =>
    <Heading fill level='4'>
        {children}
    </Heading>

export const ContainerMarkdownComponent = ({children}: ContainerMarkdownProps) =>
    <Box pad='medium'
         margin={{'vertical': 'medium'}}
         round='small'
         background='background-selected'>
        <Text>
            {children}
        </Text>
    </Box>

export const JobListMarkdownComponent = (jobs: JobModel[] = []) => <>
    {jobs && jobs.map((job: JobModel, idx: number) =>
        <li key={idx}
            style={{listStylePosition: 'inside'}}>
            <Text weight='bold'>{job.name}</Text>
        </li>)}
</>

export const JobEnumMarkdownComponent = (jobs: JobModel[] = []) => <Text>
    {jobs && jobs.map((job: JobModel, idx: number) => <React.Fragment
        key={idx}><strong>{!jobs[idx + 1] ? ' oder ' : ''}{job.name}{jobs[idx + 2] ? ', ' : ' '}</strong></React.Fragment>)}
</Text>

export const TenantNameMarkdownComponent = () => {
    const tenant = useTenant()
    return <>
        {tenant && <strong> {tenant.companyName} </strong>}
    </>
}

export const TenantAddressMarkdownComponent = () => {
    const tenant = useTenant()
    return <>
        {tenant && <Box pad='small'>
            <Text weight='bold'>{tenant.companyName}</Text>
            <Text>{tenant.street} {tenant.streetNumber}</Text>
            <Text>{tenant.addressAddition}</Text>
            <Text>{tenant.postcode} {tenant.city}</Text>
        </Box>}
    </>
}

export const CampaignMainAddressMarkdownComponent = (addresses: AddressModel[] | undefined) => {
    if(addresses) {
        return SubsidiaryListMarkdownComponent(addresses)
    }

    return <Text>Main campaign address is not set</Text>
}

export const DataPrivacyOfficerListMarkdownComponent = (dataPrivacyOfficers: ContactModel[] = []) => <>
    {dataPrivacyOfficers && dataPrivacyOfficers.map((dataPrivacyOfficer: ContactModel, idx: number) => <Box key={idx}
                                                                                                            pad='small'>
        <Text weight='bold'>{dataPrivacyOfficer.firstname} {dataPrivacyOfficer.lastname}</Text>
        <Text>{dataPrivacyOfficer.address?.companyName}</Text>
        <Text>{dataPrivacyOfficer.address?.street} {dataPrivacyOfficer.address?.streetNumber}</Text>
        <Text>{dataPrivacyOfficer.address?.addressAddition}</Text>
        <Text>{dataPrivacyOfficer.address?.postcode} {dataPrivacyOfficer.address?.city}</Text>
        <Text>E-Mail: {dataPrivacyOfficer.email}</Text>
        <Text>Tel.: {dataPrivacyOfficer.phone}</Text>
    </Box>)}
</>

export const DataPrivacyOfficerModalMarkdownComponent = (toggleOfficerModal: () => void, dataPrivacyOfficers: ContactModel[] = []) =>
    <Stack anchor='right'>
        <Box
            pad='medium'
            margin={{'vertical': 'medium'}}
            round='small'
            background='background-selected'
            onClick={() => toggleOfficerModal()}>
            {DataPrivacyOfficerListMarkdownComponent(dataPrivacyOfficers)}
        </Box>
        <Box pad='small'>
            <FormEdit/>
        </Box>
    </Stack>

export const SubsidiaryEnumMarkdownComponent = (subsidiaries: AddressModel[] = []) => <Text>
    {subsidiaries && subsidiaries.map((subsidiary: AddressModel, idx: number) => <React.Fragment
        key={idx}><strong>{subsidiary.companyName}{subsidiaries[idx + 1] && ' / '}</strong></React.Fragment>)}
</Text>

export const SubsidiaryListMarkdownComponent = (subsidiaries: AddressModel[] = []) => <>
    {subsidiaries && subsidiaries.map((subsidiary: AddressModel, idx: number) => <Box key={idx} pad='small'>
        <Text weight='bold'>{subsidiary.companyName}</Text>
        <Text>{subsidiary.street} {subsidiary.streetNumber}</Text>
        <Text>{subsidiary.addressAddition}</Text>
        <Text>{subsidiary.postcode} {subsidiary.city}</Text>
    </Box>)}
</>

export const SubsidiaryMarkdownComponent = (subsidiaries: AddressModel[] = []) =>
    <Stack anchor='right'>
        <Box
            pad='medium'
            margin={{'vertical': 'medium'}}
            round='small'>
            <Text>
                {SubsidiaryEnumMarkdownComponent(subsidiaries)}
            </Text>
        </Box>
        <Box pad='small'>
            <FormEdit/>
        </Box>
    </Stack>

export const DataProcessingDescriptionMarkdownComponent = (dataProcessingDescription: string | undefined) => {

    const Headline = "Für welche Prozessabschnitte besteht eine gemeinsame Verantwortlichkeit?"
    const onEmptyDescriptionText = "Text hinzufügen"

    const isStringEmpty = (string: string | undefined) => {
        return string == undefined || string.length == 0;
    }

    return <>
        {HeadingMarkdownComponent({children: Headline})}
        {!isStringEmpty(dataProcessingDescription) ?
            PreWrapParagraphMarkdownComponent( {children: dataProcessingDescription}) :
            <Paragraph
                fill
                textAlign={"center"}
                size={"small"}
                style={{fontStyle: "italic"}}
            >
                {onEmptyDescriptionText}
            </Paragraph>}
    </>
}

export const EditableContentContainer = (onClick: () => void, {children}: ContainerMarkdownProps) =>
    <Stack anchor='right'>
        <Box
            style={{display: "block"}}
            pad='medium'
            margin={{'vertical': 'medium'}}
            round='small'
            background='background-selected'
            onClick={onClick}>
            {children}
        </Box>
        <Box pad='small'>
            <FormEdit/>
        </Box>
    </Stack>

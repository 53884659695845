import {useContext} from "react";
import {JobApplicationsContext} from "../store/JobApplicationsContext";

export const useMessages = () => {
    return useContext(JobApplicationsContext).messages
}

export const useUpdateMessages = () => {
    return useContext(JobApplicationsContext).updateJobApplications
}

export const useShowMessagesBadge = () => {
    return useContext(JobApplicationsContext).showMessageNotificationBadge
}

export type JobApplicationStatusType = 'CONVERSATION_APPLICANT_SENT_A_MESSAGE' |
    'CONVERSATION_NON_APPLICANT_WROTE_A_MESSAGE' |
    'CONVERSATION_WAITING_FOR_APPLICANT_TO_RESPOND' |
    'CONVERSATION_CLOSED' |
    'APPLICATION_NEW_APPLICANT' |
    'APPLICATION_APPLICANT_IS_WAITING' |
    'APPLICATION_APPLICANT_IS_HIRED' |
    'APPLICATION_APPLICANT_IS_REJECTED' |
    'LONG_TIME_NO_CLIENT_ACTION' |
    'UNKNOWN_STATUS'

export type JobApplicationStatusTrayType = 'REACTION' | 'WAITING' | 'FINISHED'

type JobApplicationStatusDetailsType = {
    [key in JobApplicationStatusType]: JobApplicationStatusDetailObjectType
}

interface JobApplicationStatusDetailObjectType {
    label: string
    background: string
    tray: JobApplicationStatusTrayType
}

export const JOB_APPLICATION_STATUS_DETAILS: JobApplicationStatusDetailsType = {
    'APPLICATION_NEW_APPLICANT': {
        label: 'Neue Bewerbung',
        background: 'status-ok',
        tray: 'REACTION'
    },
    'APPLICATION_APPLICANT_IS_WAITING': {
        label: 'Ihre Reaktion erforderlich',
        background: 'status-warning',
        tray: 'REACTION'
    },
    'APPLICATION_APPLICANT_IS_HIRED': {
        label: 'Bewerber wurde eingestellt',
        background: 'light-6',
        tray: 'FINISHED'
    },
    'APPLICATION_APPLICANT_IS_REJECTED': {
        label: 'Bewerber abgesagt',
        background: 'light-6',
        tray: 'FINISHED'
    },
    'CONVERSATION_APPLICANT_SENT_A_MESSAGE': {
        label: 'Neue Nachricht',
        background: 'status-ok',
        tray: 'REACTION'
    },
    'CONVERSATION_NON_APPLICANT_WROTE_A_MESSAGE': {
        label: 'Neue Nachricht',
        background: 'status-ok',
        tray: 'REACTION'
    },
    'CONVERSATION_WAITING_FOR_APPLICANT_TO_RESPOND': {
        label: 'Warte auf Reaktion',
        background: 'light-6',
        tray: 'WAITING'
    },
    'CONVERSATION_CLOSED': {
        label: 'Konversation geschlossen',
        background: 'light-6',
        tray: 'FINISHED'
    },
    'LONG_TIME_NO_CLIENT_ACTION': {
        label: 'Lange nichts passiert!',
        background: 'status-warning',
        tray: 'REACTION'
    },
    'UNKNOWN_STATUS': {
        label: 'Unbekannter Status',
        background: 'status-error',
        tray: 'REACTION'
    },
}

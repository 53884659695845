import { Box, Form, FormField, Heading, Text, TextInput } from 'grommet'
import React, { useEffect, useState } from 'react'
import { required } from '../../../../components/inputs/validation'
import { BaseButton } from '../../../../components/buttons/BaseButton'
import { AddressModel } from '../../../../models/AddressModel'

const initialData: AddressModel = {
    companyName: '',
    street: '',
    streetNumber: '',
    addressAddition: '',
    postcode: '',
    city: '',
}
type Props = {
    subsidiary?: AddressModel,
    onSubmit: (address: AddressModel) => void
    onDelete?: () => void
}

export const AdDetailSubsidiaryEdit = (props: Props) => {

    const [input, setInput] = useState<AddressModel>(initialData)

    useEffect(() => {
        if (props.subsidiary !== undefined) {
            setInput(props.subsidiary)
        }
    }, [props.subsidiary])

    return <Box width={'large'}
                height={{min: 'auto}'}}>
        <Heading level={4}>Standort der Anzeige {!props.onDelete ? 'hinzufügen' : 'bearbeiten'}</Heading>

        <Text>Hier kannst du Name und Adresse von Pflegeeinrichtungen hinterlegen.
            Diese werden Teil der Anzeige und in der Datenschutzinfo automatisch hinterlegt.</Text>

        <Form<AddressModel>
            value={input}
            validate='change'
            onSubmit={() => props.onSubmit(input)}
            onChange={(nextValue: AddressModel) => {
                setInput(nextValue)
            }}>
            <Box>
                <Heading level={4} id='form-start'>Einrichtungsname & Anschrift</Heading>
                <FormField
                    name='companyName'
                    label='Unternehmensname*'
                >
                    <TextInput name='companyName' value={input.companyName}/>
                </FormField>
                <Box
                    direction='row'
                    gap='small'
                    justify='between'
                >
                    <FormField
                        name='street'
                        width='medium'
                        label='Straße*'
                        validate={required}
                    >
                        <TextInput name='street' value={input.street}/>
                    </FormField>
                    <FormField
                        name='streetNumber'
                        label='Hausnummer*'
                        validate={required}
                    >
                        <TextInput name='streetNumber' value={input.streetNumber}/>
                    </FormField>
                </Box>
                <FormField name='addressAddition' label='Adresszusatz'>
                    <TextInput name='addressAddition' value={input.addressAddition}/>
                </FormField>
                <Box direction={'row'} gap={'small'}>
                    <FormField
                        name='postcode'
                        label='Postleitzahl*'
                        validate={required}
                    >
                        <TextInput name='postcode' value={input.postcode}/>
                    </FormField>
                    <FormField
                        name='city'
                        label='Stadt*'
                        width={'medium'}
                        validate={required}
                    >
                        <TextInput name='city' value={input.city}/>
                    </FormField>
                </Box>
            </Box>

            <Box direction={'row'}
                 width={'100%'}
                 justify={'end'}
                 gap={'small'}>
                {props.onDelete && <BaseButton label={'Standort löschen'}
                                       onClick={props.onDelete}
                                       type={'button'}/>}
                <BaseButton primary
                            label={!props.onDelete ? 'Standort hinzufügen' : 'Änderung übernehmen'}
                            type={'submit'}/>
            </Box>
        </Form>
    </Box>
}

import { Button, Layer } from 'grommet'
import React, { useCallback, useState } from 'react'
import { ControlButtons } from '../buttons/ControlButtons'
import { LabeledFormDialog, LabeledFormDialogProps } from './LabeledFormDialog'

export type ConfirmDialogProps = LabeledFormDialogProps & {
    okLabel?: string,
    onOk: () => void,
    onCancel: () => void
}

export const useConfirm = function(handleDelete: () => Promise<void>) {
    const [visible, setVisible] = useState(false)
    const handleOpen = useCallback(() => setVisible(true), [setVisible])
    const handleCancel = useCallback(() => setVisible(false), [setVisible])
    const handleOk = useCallback(async() => {
        setVisible(false)
        await handleDelete()
    }, [setVisible, handleDelete])
    return {
        confirmVisible: visible,
        handleConfirmOpen: handleOpen,
        handleConfirmCancel: handleCancel,
        handleConfirmOk: handleOk
    }
}

export const ConfirmDialog = ({label, width, children, onCancel, onOk, okLabel}: ConfirmDialogProps) => (
    <Layer>
        <LabeledFormDialog label={label} width={width || '500px'}>
            {children}
            <ControlButtons>
                <Button primary onClick={onOk} label={okLabel || 'Ok'}/>
                <Button onClick={onCancel} label={'Abbrechen'}/>
            </ControlButtons>
        </LabeledFormDialog>
    </Layer>
)

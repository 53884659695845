import React from 'react'
import { ChatText, Gear, CheckCircle, XCircle, HourglassMedium, HandPalm } from 'phosphor-react'
import { ResponsiveSidebar } from '../../../components/sidebar/ResponsiveSidebar'
import { ResponsiveSidebarItem } from '../../../components/sidebar/ResponsiveSidebarItem'
import { ResponsiveSidebarCategory } from '../../../components/sidebar/ResponsiveSidebarCategory'
import { JobApplicationType } from '../../../api/jobApplications'
import { JobApplicationStepType } from '../JobApplicationSteps'
import { Star } from 'grommet-icons'
import { unovisionTheme } from '../../../theme/theme'

type ApplicantDetailSidebarProps = {
    currentApplication: JobApplicationType
    recordJobApplicationStepCallback: (stepToRecord: JobApplicationStepType) => void
    toggleFavoriteCallback: () => void
}

export const ApplicantDetailSidebar = ({
                                           currentApplication,
                                           recordJobApplicationStepCallback,
                                           toggleFavoriteCallback
                                       }: ApplicantDetailSidebarProps) =>
    <ResponsiveSidebar>
        <ResponsiveSidebarCategory
            title='Bewerber Optionen'
            icon={<Gear/>}
            sidebarItems={[
                <ResponsiveSidebarItem
                    label={currentApplication.isFavorite ? 'Als Favorit entfernen' : 'Als Favorit markieren'}
                    icon={<Star size='24' color={currentApplication.isFavorite ? 'brand' : 'light-4'}/>}
                    onClick={toggleFavoriteCallback}/>,
                <ResponsiveSidebarItem
                    label='Wurde eingestellt'
                    icon={<CheckCircle size={24} color={unovisionTheme.global.colors.brand.light}/>}
                    onClick={() => recordJobApplicationStepCallback('CLIENT_HIRED_APPLICATION')}
                />,
                <ResponsiveSidebarItem
                    label='Konversation schließen'
                    icon={<XCircle size={24} color={unovisionTheme.global.colors.brand.light}/>}
                    onClick={() => recordJobApplicationStepCallback('CLIENT_CLOSED_APPLICATION')}/>,
                <ResponsiveSidebarItem
                    label='Warten auf Reaktion'
                    icon={<HourglassMedium size={24} color={unovisionTheme.global.colors.brand.light}/>}
                    onClick={() => recordJobApplicationStepCallback('CLIENT_WAITING_FOR_APPLICANT_TO_RESPOND')}
                />
            ]}/>
        <ResponsiveSidebarCategory icon={<ChatText/>} title={'Nachrichtenvorlagen auslösen'} sidebarItems={
            [
                <ResponsiveSidebarItem
                    disabled
                    label={'Absage senden'}
                    icon={<HandPalm size={24} color={unovisionTheme.global.colors.brand.light}/>}
                    onClick={() =>
                        window.confirm('Sind sie sicher das Sie dem Bewerber absagen möchten? Dieser Vorgang ist nicht Rückgängig zu machen!')
                        && recordJobApplicationStepCallback('CLIENT_APPLICATION_REJECTED_SENT')}/>
            ]
        }/>
    </ResponsiveSidebar>

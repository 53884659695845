import React, {useEffect, useState} from 'react'
import {Modal, ModalContent, ModalHeading} from '../../../../components/layout/Modal'
import {Heading, Spinner} from 'grommet'
import {AttachDetachListView, OptionType} from '../../../ads/adDetail/content/AttachDetachListView'
import {AddressModel} from '../../../../models/AddressModel'
import {SubsidiaryEditModal} from './SubsidiaryEditModal'
import {createCampaignSubsidiary, getCampaignSubsidiaries} from '../../../../api/campaigns'
import {deleteSubsidiary, updateSubsidiary} from '../../../../api/subsidiaries'

type Props = {
    campaignId: number
    onClose: (selectedAddresses: AddressModel[]) => void
    preselectedAddressIds?: number[];
}
export const AddressSelectionModal: React.FC<Props> = (props: Props) => {

    const [subsidiaries, setSubsidiaries] = useState<AddressModel[] | undefined>(undefined)
    const [selectedAddressIds, setSelectedAddressIds] = useState<number[]>(props.preselectedAddressIds  || [])

    const [subsidiaryEditModalOpen, setSubsidiaryEditModalOpen] = useState<boolean>(false)
    const [subsidiaryToEdit, setSubsidiaryToEdit] = useState<AddressModel | undefined>(undefined)
    const [selectedSubsidiariesOptions, setSelectedSubsidiariesOptions] = useState<OptionType[]>([])
    const [allSubsidiariesOptions, setAllSubsidiariesOptions] = useState<OptionType[]>([])

    useEffect(() => {
        loadSubsidiaries()
    }, [props.campaignId])

    useEffect(() => {
        const selectedAddressOptions = resolveSelectedAddresses(selectedAddressIds)
            .map((data, index) => resolveOptionFromAddress(data, index))

        setSelectedSubsidiariesOptions(selectedAddressOptions)
    }, [selectedAddressIds, subsidiaries])

    useEffect(() => {
        setAllSubsidiariesOptions(
            subsidiaries
                ?.map((data, index) => resolveOptionFromAddress(data, index)) || []
        )
    }, [subsidiaries, selectedAddressIds])

    const loadSubsidiaries = async() => {
        const subsidiaries = await getCampaignSubsidiaries(props.campaignId)
        setSubsidiaries(subsidiaries)
    }

    const resolveSelectedAddresses = (addressIds: number[]): AddressModel[] => {
        // @ts-ignore
        return selectedAddressIds
            .map(id => subsidiaries?.find(address => address.id! === id))
            .filter(value => value !== undefined)
    }

    const resolveOptionFromAddress = (address: AddressModel, index: number): OptionType => {
        const addressAdditionString = address.addressAddition ? ' ' + address.addressAddition : ''
        return {
            id: address.id || index,
            name: address.companyName || '',
            subTitle: address.street + ' ' + address.streetNumber + ' ' + addressAdditionString + ', ' + address.postcode + ' ' + address.city,
            isEmpty: false,
            isHighlighted: address.id ? selectedAddressIds.includes(address.id) : false
        }
    }

    const handleEdit = (id: number) => {
        setSubsidiaryToEdit(subsidiaries?.find(s => s.id === id))
        setSubsidiaryEditModalOpen(true)
    }

    const handleSubmit = async (address: AddressModel) => {
        if (subsidiaries?.find(s => s.id === address.id) === undefined) {
            await createCampaignSubsidiary(props.campaignId, address)
        } else {
            await updateSubsidiary(address)
        }
        await loadSubsidiaries()
        setSubsidiaryEditModalOpen(false)
    }

    const handleDelete = async () => {
        await deleteSubsidiary(subsidiaryToEdit?.id!)
        await loadSubsidiaries()
        await unselectAddress(subsidiaryToEdit?.id!)
        setSubsidiaryEditModalOpen(false)
    }

    const showEditModalToAddSubsidiary = () => {
        setSubsidiaryToEdit(undefined)
        setSubsidiaryEditModalOpen(true)
    }

    const selectAddress = async (addressId: number) => {
        if (selectedAddressIds.includes(addressId)) return
        if (subsidiaries!.find(obj => obj.id === addressId) === undefined) return

        const updatedAddresses = (selectedAddressIds).concat([addressId])
        setSelectedAddressIds(updatedAddresses)
    }

    const unselectAddress = async (addressId: number) => {
        const updatedAddresses = selectedAddressIds?.filter(id => id !== addressId)
        setSelectedAddressIds(updatedAddresses)
    }

    const handleClose = () => {
        return props.onClose(resolveSelectedAddresses(selectedAddressIds))
    }

    return (
        <Modal
            full={"horizontal"}
            onEsc={handleClose}
            onClickClose={handleClose}
            onClickOutside={handleClose}
        >
            <ModalHeading>
                <Heading level={4}>Standorte verwalten</Heading>
            </ModalHeading>
            <ModalContent>
                {subsidiaries === undefined || selectedAddressIds === undefined ?
                    <div><Spinner/> Loading...</div> :
                    <AttachDetachListView
                        selectedOptionsTitle={selectedSubsidiariesOptions?.length + ' Hauptadressen ausgewählt'}
                        selectedOptionsSubtitle={'Wählen Sie ihre Hauptadressen aus'}
                        selectedOptions={selectedSubsidiariesOptions!}
                        allOptions={allSubsidiariesOptions}
                        optionsTitle={'Verfügbare Adressen,'}
                        optionsSubtitle={'die als Hauptadressen gesetzt werden können'}
                        optionAddButtonLabel={'Neuen Standort hinzufügen'}
                        handleAttach={selectAddress}
                        handleDetach={unselectAddress}
                        highlightColor={'background-selected'}
                        highlightedOptionDescription={'Als Haupt-Adresse ausgewählt'}
                        handleEdit={handleEdit}
                        handleAdd={showEditModalToAddSubsidiary}
                    />
                }
            </ModalContent>
            {subsidiaryEditModalOpen ?
                <SubsidiaryEditModal
                    subsidiary={subsidiaryToEdit}
                    onSubmit={handleSubmit}
                    onDelete={subsidiaryToEdit !== undefined ? handleDelete : undefined}
                    onClose={() => setSubsidiaryEditModalOpen(false)}
                /> : null
            }
        </Modal>
    )
}

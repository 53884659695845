import React, {useEffect, useState} from 'react'
import {
    addJobApplicationStep,
    getJobApplication,
    getJobApplicationRecordedSteps,
    getJobApplicationStatus,
    JobApplicationRecordedStepType,
    JobApplicationType,
    setJobApplicationIsFavorite,
} from '../../../api/jobApplications'
import {ApplicantDetailBanner} from './ApplicantDetailBanner'
import {ApplicantDetailSidebar} from './ApplicantDetailSidebar'
import {Box, Tab, Tabs} from 'grommet'
import {ContentLayout} from '../../../components/layout/ContentLayout'
import {JobApplicationStatusType} from '../JobApplicationStatus'
import {JobApplicationStepType} from '../JobApplicationSteps'
import {LoadingOverlay} from '../../../components/layout/LoadingOverlay'
import {RecordedStepsContainer} from './RecordedStepsContainer'
import {RichTabTitle} from '../../../components/tabs/RichTabTitle'
import {useParams} from 'react-router-dom'
import {ApplicantChatComponent} from './ApplicantChatComponent'

const initialJobApplication: JobApplicationType = {
    id: '',
    campaignId: 0,
    job: {id: 0, name: ''},
    isFavorite: false,
    applicant: {
        id: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        description: '',
        avatarUrl: ''
    },
    applicationTimestamp: '',
    applicationStatus: 'UNKNOWN_STATUS' as JobApplicationStatusType,
    lastApplicantActivityTimestamp: ''
}

export const ApplicantDetailContent = () => {
    const params = useParams<{ applicationId: string }>()
    const [currentApplication, setCurrentApplication] = useState({...initialJobApplication, id: params.applicationId})
    const [recordedJobApplicationSteps, setRecordedJobApplicationSteps] = useState<JobApplicationRecordedStepType[]>([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0)

    useEffect(() => {
        addJobApplicationStep(currentApplication.id, 'CLIENT_VISITED_APPLICATION').then(() => {
                getJobApplication(currentApplication.id).then((jobApplicationResponse) => {
                    setCurrentApplication(jobApplicationResponse)
                    getJobApplicationRecordedSteps(currentApplication.id).then((res) => {
                            setRecordedJobApplicationSteps(res.jobApplicationRecordedSteps)
                        }
                    ).then(() => {
                        setIsLoaded(true)
                    })
                })
            }
        ).catch((err) => {
                console.error('Error while loading Applicant: ', err)
            }
        )
    }, [currentApplication.id])

    const recordJobApplicationStep = (stepToRecord: JobApplicationStepType) => {
        addJobApplicationStep(currentApplication.id, stepToRecord).then(() => {
                getJobApplicationRecordedSteps(currentApplication.id).then((stepsResponse) => {
                        setRecordedJobApplicationSteps(stepsResponse.jobApplicationRecordedSteps)
                        getJobApplicationStatus(currentApplication.id).then((statusResponse) => {
                            setCurrentApplication({...currentApplication, applicationStatus: statusResponse})
                        })
                    }
                )
            }
        )
    }

    const toggleFavorite = () => {
        setJobApplicationIsFavorite(currentApplication.id, !currentApplication.isFavorite).then((favoriteResponse) => {
                setCurrentApplication({
                    ...currentApplication,
                    isFavorite: favoriteResponse.isFavorite
                })
                getJobApplicationRecordedSteps(currentApplication.id).then((recordedStepsResponse) => {
                    setRecordedJobApplicationSteps(recordedStepsResponse.jobApplicationRecordedSteps)
                })
            }
        ).catch((err) => {
            console.error('Error while updating favorite flag', err)
        })
    }

    const onActive = (nextIndex: number) => setActiveTabIndex(nextIndex)

    //TODO Selfdescription of the Bewerbers commented, since it is just mocked currently
    return <>
        {isLoaded && <ContentLayout
            sidebar={<ApplicantDetailSidebar
                currentApplication={currentApplication}
                recordJobApplicationStepCallback={recordJobApplicationStep}
                toggleFavoriteCallback={toggleFavorite}
            />}
            banner={<ApplicantDetailBanner
                currentJobApplication={currentApplication}
                toggleFavorite={toggleFavorite}
            />}>
            <Box gap='medium'>
                {/*<Box gap='small'>*/ }
                {/*    <Heading level='5' margin='none'>Selbstbeschreibung des Bewerbers:</Heading>*/}
                {/*    <Paragraph*/}
                {/*        size='small'*/}
                {/*        fill*/}
                {/*        margin='none'*/}
                {/*    >{currentApplication.applicant.description}</Paragraph>*/}
                {/*</Box>*/}
                <Tabs onActive={onActive} alignControls='start'>
                    <Tab title={
                        <RichTabTitle
                            isActive={activeTabIndex === 0}
                            label='Chatverlauf'
                        />}>

                        <Box pad={'medium'}>
                            <ApplicantChatComponent applicantId={currentApplication.id}/>
                        </Box>
                    </Tab>
                    <Tab title={
                        <RichTabTitle
                            isActive={activeTabIndex === 1}
                            label='Bewerbungsverlauf'
                        />}>
                        <Box
                            round='small'
                            pad='medium'
                            margin={{top: 'medium'}}
                            background='light-2'
                            height='medium'
                            overflow={{vertical: 'scroll'}}
                        >
                            <RecordedStepsContainer
                                applicantAvatarUrl={currentApplication.applicant.avatarUrl}
                                recordedSteps={recordedJobApplicationSteps}/>
                        </Box>
                    </Tab>
                </Tabs>
            </Box>
        </ContentLayout>}
        <LoadingOverlay
            visible={!isLoaded}
        />
    </>
}

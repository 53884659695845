import { unovisionClient } from './unovisionClient'
import { CAMPAIGN_CONTEXT_URL } from './campaigns'

export type JobBenefitType = {
    id: number,
    text: string
}

export type SaveJobBenefitType = {
    jobBenefitId?: number,
    text: string
}
const CONTEXT_PATH = '/job-benefit'

export const getCampaignJobBenefits = async(campaignId: number): Promise<JobBenefitType[]> => {
    const response = await unovisionClient.get<JobBenefitType[]>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}${CONTEXT_PATH}`)
    return response.data
}

export const saveCampaignJobBenefits = async(campaignId: number, jobBenefits: SaveJobBenefitType[]): Promise<JobBenefitType[]> => {
    const response = await unovisionClient.post<JobBenefitType[]>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}${CONTEXT_PATH}`, {
        jobBenefits: jobBenefits
    })

    return response.data
}

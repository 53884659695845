import { Box, FormField, FormFieldProps } from 'grommet'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface StaticFormFieldProps {
    display: React.ReactNode;
}

interface ModeFormFieldProps extends StaticFormFieldProps {
    isEdit: boolean;
    children: React.ReactNode;
    borderLess?: boolean;
}

const StyledFormField = styled(FormField)`
`

export const ModeFormField =
    (props: FormFieldProps & ModeFormFieldProps) => {
        const {
            label, name, isEdit, children, borderLess,
            display, ...rest
        } = props

        return (
            <StyledFormField contentProps={{border: isEdit && !borderLess}} label={<i>{label}</i>} {...rest}>
                {isEdit ? children : <Box pad={{left: 'small'}}>{display}</Box>}
            </StyledFormField>
        )
    }

export const StaticFormField =
    (props: FormFieldProps & StaticFormFieldProps) => {
        const {
            label, name,
            display, ...rest
        } = props

        return (
            <StyledFormField contentProps={{border: false}} label={<i>{label}</i>} {...rest}>
                <Box pad={{left: 'small'}}>{display}</Box>
            </StyledFormField>
        )
    }

export const BorderlessFormField =
    (props: FormFieldProps & { children?: ReactNode }) => {
        const {label, children, ...rest} = props

        return (
            <StyledFormField contentProps={{border: false}} label={<i>{label}</i>} {...rest}>
                {children}
            </StyledFormField>
        )
    }

import {TableCell, Text} from 'grommet'
import {FormEdit, FormTrash} from 'grommet-icons'
import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components'
import {UnauthorizedException} from '../../../api/UnauthorizedException'
import {useCurrentUser} from '../../../hooks/useCurrentUser'
import {ConfirmDialog, useConfirm} from '../../../components/common/ConfirmDialog'
import {HoverTableRow} from '../../../components/common/HoverTableRow'
import {CampaignDialog} from './CampaignDialog'
import {CampaignType, deleteCampaign} from '../../../api/campaigns'
import {XeniooPlatformModal} from "../../chatbot/XeniooPlatformModal";
import {Bot} from "../../../resources/icons/svg";

const FormTrashStyled = styled(FormTrash)`
  vertical-align: bottom;
  cursor: pointer;
`

const FormEditStyled = styled(FormEdit)`
  vertical-align: bottom;
  cursor: pointer;
  margin-right: 3px;
`

const ChatbotPlatformWrapper = styled.div`
  vertical-align: bottom;
  cursor: pointer;
  margin-right: 3px;
`

export const CampaignRow = ({
                                campaign,
                                reload,
                            }: { campaign: CampaignType, reload: () => void }) => {
    const setCurrentUser = useCurrentUser()[1]
    const [showChatbotScreen, setShowChatbotScreen] = useState<boolean>(false)

    const handleDelete = useCallback(async() => {
        if(campaign.id) {
            try {
                await deleteCampaign(campaign.id)
                reload()
            } catch(e) {
                if(e instanceof UnauthorizedException) {
                    setCurrentUser(null)
                }
            }
        }
    }, [reload, campaign.id, setCurrentUser])

    const {confirmVisible, handleConfirmOpen, handleConfirmOk, handleConfirmCancel} = useConfirm(handleDelete)

    const onChatbotPlatformButtonClick = () => {
        if (campaign.chatbotId) {
            setShowChatbotScreen(true)
        } else {
            alert("Es ist kein Chatbot mit dieser Anzeigenregion verbunden")
        }
    }

    if (showChatbotScreen) return <XeniooPlatformModal campaignId={campaign.id} onClickClose={() => setShowChatbotScreen(false)}/>

    return (
        <HoverTableRow>
            <TableCell direction={'row'}>
                {confirmVisible && (
                    <ConfirmDialog label={'Anzeigenregion löschen?'} onCancel={handleConfirmCancel}
                                   onOk={handleConfirmOk}>
                        <Text>
                            Wollen Sie wirklich die Anzeigenregion '{campaign.name}' löschen?
                        </Text>
                    </ConfirmDialog>
                )}
                <FormTrashStyled onClick={handleConfirmOpen}/>
                <CampaignDialog type={'edit'} formValues={campaign} reload={reload}
                                render={(handler: () => void) => <>
                                    <FormEditStyled onClick={handler}/>
                                </>}/>
                <ChatbotPlatformWrapper onClick={onChatbotPlatformButtonClick}>
                    <Bot/>
                </ChatbotPlatformWrapper>
            </TableCell>
            <TableCell>
                {campaign.name}
            </TableCell>
            <TableCell>
                {campaign.description}
            </TableCell>
            <TableCell>
                {campaign.avatarUrl}
            </TableCell>
            <TableCell>
                {campaign.chatbotId ?? "Kein Chatbot verbunden"}
            </TableCell>
        </HoverTableRow>
    )
}

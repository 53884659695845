import { unovisionClient } from './unovisionClient'

const CONTEXT_URL = '/api/recovery'

export interface ResetFormType {
    token: string;
    password: string;
}

export interface RecoveryFormType {
    username: string;
}

export interface LoginFormType {
    username: string;
    password: string;
}

export const sendRecoveryRequest = async(form: RecoveryFormType): Promise<void> => {
    await unovisionClient.post<RecoveryFormType>(`${CONTEXT_URL}`, form)
}

export const resetPassword = async(form: ResetFormType): Promise<void> => {
    await unovisionClient.post<ResetFormType>(`${CONTEXT_URL}/reset`, form)
}

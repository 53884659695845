import { Box, Button, Table, TableBody, TableCell, TableHeader, TableRow } from 'grommet'
import React, { useCallback, useEffect, useState } from 'react'
import { UnauthorizedException } from '../../../api/UnauthorizedException'
import { CampaignDialog } from './CampaignDialog'
import { CampaignRow } from './CampaignRow'
import {CampaignType, getAllCampaigns, getAllCampaignsAsSuperuser} from '../../../api/campaigns'

export const Campaign = () => {
    const [campaigns, setCampaigns] = useState<CampaignType[] | null>(null)

    const reloadConfigs = useCallback(async() => {
        try {
            getAllCampaigns().then((result) => {
                setCampaigns(result.campaigns)
            })
        } catch(e: any) {
            if(e instanceof UnauthorizedException) {
                setCampaigns(null)
            }
            console.log(e.message)
        }
    }, [])

    useEffect(() => {
        reloadConfigs()
    }, [reloadConfigs])

    return (
        <>
            <h1>Anzeigenregion</h1>
            <CampaignDialog type={'create'} reload={reloadConfigs} render={(handler: () => void) => <>
                <Box width={'small'} flex={false}><Button primary onClick={handler}
                                                          label={'Neue Anzeigenregion'}/></Box>
            </>}/>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableCell>
                            Aktionen
                        </TableCell>
                        <TableCell>
                            Name
                        </TableCell>
                        <TableCell>
                            Beschreibung
                        </TableCell>
                        <TableCell>
                            Avatar URL
                        </TableCell>
                        <TableCell>
                            Chatbot
                        </TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {campaigns == null
                        ? <TableRow><TableCell colSpan={7}>Loading...</TableCell></TableRow>
                        : campaigns.map(c => (
                            <CampaignRow
                                key={c.id}
                                campaign={c}
                                reload={reloadConfigs}/>
                        ))}
                </TableBody>
            </Table>
        </>
    )
}

import {
    dataPrivacyProperties,
    DataPrivacyTypeProperty,
    defaultDataPrivacyProperties,
    SelectedDataPrivacyLawType
} from "../ui/dashboard/dataPrivacy/DataPrivacyContent";

export const getDataPrivacyProperties = (dataPrivacyLaw: SelectedDataPrivacyLawType | undefined): DataPrivacyTypeProperty => {

    const specificDataPrivacyProperties = dataPrivacyLaw && dataPrivacyProperties.get(dataPrivacyLaw)

    return {
        requiredAmountOfMainAddresses: specificDataPrivacyProperties?.requiredAmountOfMainAddresses ?? defaultDataPrivacyProperties.requiredAmountOfMainAddresses,
        hasOptionalProcessingDescription: specificDataPrivacyProperties?.hasOptionalProcessingDescription ?? defaultDataPrivacyProperties.hasOptionalProcessingDescription,
    }
}

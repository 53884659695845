import React from 'react'
import { Box, Heading, Image, Text } from 'grommet'
import { Validate } from 'grommet-icons'
import styled from 'styled-components'
import SubscribeImageSrc from '../../../../resources/images/subscribe_image.png'

const successTitle = 'Vielen Dank für Ihre Anfrage!'
const successSubTitle = 'Wir melden uns sobald wie möglich'

export const SubscribeSuccessStep = () =>
    <Box
        fill
        wrap='reverse'
        animation='fadeIn'
        justify='around'
        align='center'
        pad='large'
    >
        <Box justify='center' align='center' gap='small'>
            <Validate size='64' color='brand'/>
            <>
                <Heading level='3' textAlign='center'>{successTitle}</Heading>
                <Text size='large' textAlign='center'>{successSubTitle}</Text>
            </>
        </Box>
        <StyledImage src={SubscribeImageSrc}/>
    </Box>

const StyledImage = styled(Image)`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 350px;
`
import { AddressModel } from './AddressModel'

export interface ContactModel {
    id?: number,
    address?: AddressModel,
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
}

// Had to be implemented as Grommet 2.17.3 doesn't support nested Form Values yet
export interface FormContactModel {
    contactId?: number,
    addressId?: number,
    firstname: string,
    lastname: string,
    email: string,
    phone: string,
    companyName?: string,
    street: string,
    streetNumber: string,
    addressAddition?: string,
    postcode: string,
    city: string
}

export const toFormContact = (contact: ContactModel): FormContactModel => (
    {
        contactId: contact.id,
        addressId: contact.address?.id,
        firstname: contact.firstname,
        lastname: contact.lastname,
        email: contact.email,
        phone: contact.phone,
        companyName: contact.address?.companyName,
        street: contact.address!!.street,
        streetNumber: contact.address!!.streetNumber,
        addressAddition: contact.address!!.addressAddition,
        postcode: contact.address!!.postcode,
        city: contact.address!!.city
    }
)

export const toFormContacts = (contacts: ContactModel[] = []): FormContactModel[] => contacts.map((contact) => toFormContact(contact))

export const toContact = (formContact: FormContactModel): ContactModel => ({
        id: formContact.contactId,
        firstname: formContact.firstname,
        lastname: formContact.lastname,
        email: formContact.email,
        phone: formContact.phone,
        address: {
            id: formContact.addressId,
            companyName: formContact.companyName,
            street: formContact.street,
            streetNumber: formContact.streetNumber,
            addressAddition: formContact.addressAddition,
            postcode: formContact.postcode,
            city: formContact.city
        }
    }
)

export const toContacts = (formContacts: FormContactModel[] = []): ContactModel[] => formContacts.map((formContact) => toContact(formContact))

import { Box, Text } from 'grommet'
import { CheckCircle, WarningCircle } from 'phosphor-react'
import { unovisionTheme } from '../../theme/theme'
import React from 'react'

type CheckListItemProps = {
    label: string
    isChecked: boolean
    size?: string
}

export const CheckListItem = (props: CheckListItemProps) =>
    <Box
        direction='row'
        gap='small'
        width={{min: 'small'}}
        align='center'
    >
        {props.isChecked ?
            <CheckCircle color={unovisionTheme.global.colors['status-ok']} size={props.size === 'small' ? 20 : 28}/> :
            <WarningCircle color={unovisionTheme.global.colors['status-error']}
                           size={props.size === 'small' ? 20 : 28}/>}
        <Text size='small' color='dark-4'>{props.label}</Text>
    </Box>

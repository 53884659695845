import React, { useCallback, useState } from 'react'
import { Form, Grid } from 'grommet'
import { DashedTextArea } from '../../../components/inputs/DashedTextArea'
import { CheckmarkClueListBox } from '../../../components/common/CheckmarkClueListBox'
import { EmployerDescriptionEditor } from './EmployerDescriptionEditor'
import { ChatBotPreviewBoxChild } from '../../../components/common/ChatBotPreviewBoxChild'
import { ChatBotPreviewBox } from '../../../components/common/ChatBotPreviewBox'
import { ControlButtons } from '../../../components/buttons/ControlButtons'
import { BaseButton } from '../../../components/buttons/BaseButton'
import { EmployerDescriptionItem, updateEmployerDescription } from '../../../api/campaignManagement'
import { useCurrentCampaign } from '../../../hooks/useCurrentCampaign'

export const EMPLOYER_DESCRIPTION_MAX_LENGTH = 300

export type EmployerDescriptionContentProps = {
    description?: EmployerDescriptionItem[]
}

export const EmployerDescriptionContent = ({description}: EmployerDescriptionContentProps) => {
    const clueList = [
        'Warum soll der Bewerber zu Ihrer Firma wechseln?',
        'Wie ist die Lage des Arbeitsplatzes?',
        'Wie ist die Verkehrsanbindung?',
        'Wie ist die Parkplatzsituation?',
        'Wie ist die Unternehmenskultur?',
        'Was bietet das Unternehmen seinen Mitarbeitern spannendes an?',
    ]

    const initialState = Object.assign(
        ['', '', '', ''],
        description?.map(val => val.text)
    )
    const currentCampaign = useCurrentCampaign()
    const [value, setValue] = useState<string[]>(initialState)
    const [saved, setSaved] = useState<boolean>(true)

    const handleTextChange = useCallback((event, index) => {
        value[index] = event.target.value
        setValue(Object.assign([], value))
        setSaved(false)
    }, [value, setValue, setSaved])

    const submit = useCallback(() => {
        if(currentCampaign) {
            updateEmployerDescription(currentCampaign.id, {
                description: value.map((val) => {
                    return {
                        text: val
                    }
                })
            }).then(result => {
                setSaved(true)
                alert('Updated: ' + result)
            })
        }
    }, [currentCampaign, value, setSaved])

    const hasChanged = useCallback(() => {
        return !saved && value.join() !== initialState.join()
    }, [initialState, value, saved])


    return (
        <Form>
            <Grid fill
                  areas={[
                      {name: 'tips', start: [0, 0], end: [0, 0]},
                      {name: 'main', start: [1, 0], end: [1, 0]},
                      {name: 'chatBot', start: [2, 0], end: [2, 0]},
                  ]}
                  columns={['25%', '40%', '25%']}
                  rows={['flex']}
                  justifyContent={'center'}
                  gap={'large'}>
                <CheckmarkClueListBox gridArea={'tips'}
                                      clueList={clueList}
                                      title={'Ein paar Tipps zum Inhalt'}
                                      subTitle={`Dies ist Ihre Selbstbeschreibung im Chatbot.
                                    Die Bewerber lesen solche Informationen sehr oft,
                                    daher bitte positiv, ansprechend und aus der Sicht der Bewerber schreiben.`}/>

                <EmployerDescriptionEditor gridArea={'main'}>
                    {value.map((item, index) => {
                        return <DashedTextArea key={index}
                                               value={item}
                                               maxLength={EMPLOYER_DESCRIPTION_MAX_LENGTH}
                                               resize={'vertical'}
                                               onChange={(event) => handleTextChange(event, index)}/>
                    })}
                    <ControlButtons>
                        <BaseButton primary
                                    label={'Speichern'}
                                    type={'submit'}
                                    disabled={!hasChanged()}
                                    onClick={submit}/>
                    </ControlButtons>
                </EmployerDescriptionEditor>

                <ChatBotPreviewBox gridArea={'chatBot'}>
                    {value.map((item, index) => {
                        return <ChatBotPreviewBoxChild key={index}>
                            {item}
                        </ChatBotPreviewBoxChild>
                    })}
                </ChatBotPreviewBox>
            </Grid>
        </Form>
    )
}




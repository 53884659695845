import React from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import { useCurrentUser } from '../../hooks/useCurrentUser'

import { SubscribePage } from '../../ui/public/subscribe/SubscribePage'
import { Login } from '../../ui/auth/login/Login'
import { Reset } from '../../ui/auth/reset/Reset'
import { Recovery } from '../../ui/auth/recovery/Recovery'
import { HomePage } from '../../ui/public/HomePage'
import { EmployerDescriptionPage } from '../../ui/dashboard/employerDescription/EmployerDescriptionPage'
import { JobApplicationsPage } from '../../ui/jobApplications/JobApplicationsPage'
import { CampaignContextProvider } from '../../store/CampaignContext'
import { DataPrivacyPage } from '../../ui/dashboard/dataPrivacy/DataPrivacyPage'
import { PublicDataPrivacyPage } from '../../ui/public/PublicDataPrivacyPage'
import { GeoTargetsPage } from '../../ui/dashboard/geoTargets/GeoTargetsPage'
import { ApplicantDetailPage } from '../../ui/jobApplications/applicantDetail/ApplicantDetailPage'
import { AdDetailPage } from '../../ui/ads/adDetail/AdDetailPage'
import { SelectCampaignPage } from '../../ui/dashboard/SelectCampaignPage'
import { DashboardPage } from '../../ui/dashboard/DashboardPage'
import { AdsPage } from '../../ui/ads/AdsPage'
import { Admin } from '../../ui/admin/Admin'
import {SelectClientPage} from "../../ui/dashboard/SelectClientPage";
import { MessagesPage } from '../../ui/messages/MessagesPage'
import {MessageDetailPage} from "../../ui/messages/MessageDetailPage";

export type RouteType = {
    name: string
    path: string
    exact?: boolean
    Component?: React.FC<any>
}

const PRIVATE_ROUTES: RouteType[] =
    [
        {name: 'Anzeigen Region Wahl', path: '/select-campaign', Component: SelectCampaignPage},
        {name: 'Home', path: '/campaign/:id/dashboard', Component: DashboardPage},
        {name: 'Ausspielorte', path: '/campaign/:id/dashboard/geo-targets', Component: GeoTargetsPage},
        {name: 'Datenschutz', path: '/campaign/:id/dashboard/data-privacy', Component: DataPrivacyPage},
        {
            name: 'Arbeitgeberbeschreibung',
            path: '/campaign/:id/dashboard/employer-description',
            Component: EmployerDescriptionPage
        },
        {name: 'Stellenanzeigen', path: '/campaign/:id/advertisements', Component: AdsPage},
        {name: 'Stellenanzeige', path: '/campaign/:id/advertisements/:adId', Component: AdDetailPage, exact: false},
        {
            name: 'Bewerbungen',
            path: '/campaign/:id/applicant-management',
            Component: JobApplicationsPage
        },
        {
            name: 'Nachrichten',
            path: '/campaign/:id/messages',
            Component: MessagesPage
        },
        {
            name: 'Bewerberdetails',
            path: '/campaign/:id/applicant-management/:applicationId',
            Component: ApplicantDetailPage
        },
        {
            name: 'Nachrichtendetails',
            path: '/campaign/:id/messages/:messageId',
            Component: MessageDetailPage
        },
    ]


export const Router = () => (
    <Switch>
        <AuthRoute exact path='/' component={HomePage}/>
        <Route path='/subscribe' component={SubscribePage}/>
        <Route path='/data-privacy/:dataPrivacyId' component={PublicDataPrivacyPage}/>
        <AuthRoute path='/login' component={Login}/>
        <AuthRoute path='/recovery/:token' component={Reset}/>
        <AuthRoute path='/recovery' component={Recovery}/>
        <PrivateRoute path='/admin' component={Admin}/>
        <PrivateRoute path='/select-client' component={SelectClientPage}/>
        <CampaignContextProvider>
            {PRIVATE_ROUTES.map(({path, name, exact = true, Component}, key) => (
                <PrivateRoute
                    exact={exact}
                    path={path}
                    key={key}
                    render={props => {
                        if(!Component) return null
                        const crumbs: RouteType[] = PRIVATE_ROUTES.filter(({path}) => props.match.path.includes(path))
                            .map(({path, ...rest}) => ({
                                path: Object.keys(props.match.params).length
                                    ? Object.keys(props.match.params).reduce(
                                        (path, param) => path.replace(
                                            // @ts-ignore
                                            `:${param}`, props.match.params[param]
                                        ), path
                                    )
                                    : path,
                                ...rest
                            }))
                        return (
                            <Component crumbs={crumbs} {...props} />
                        )
                    }}
                />
            ))}
        </CampaignContextProvider>
    </Switch>
)

const AuthRoute = (props: RouteProps) => {
    const [user] = useCurrentUser()
    return user ? <Redirect to={`/select-client`}/> :
        <Route {...props}/>
}

const PrivateRoute = (props: RouteProps) => {
    const [user] = useCurrentUser()
    return user ? <Route {...props}/> :
        <Redirect to={'/login'}/>
}

import React, { PropsWithChildren, ReactNode } from 'react'
import { Box } from 'grommet'

export type BaseContentProps = PropsWithChildren<{
    banner?: ReactNode,
    sidebar?: ReactNode
}>

export const ContentLayout: React.FC<BaseContentProps> = (props: BaseContentProps) =>
    <>
        {props.banner && props.banner}
        <Box pad={{bottom: '9em'}} height={{min: 'fit-content'}}>
            <Box direction='row' pad={{vertical: 'xsmall', horizontal: 'medium'}}>
                {props.sidebar && props.sidebar}
                {props.children}
            </Box>
        </Box>
    </>

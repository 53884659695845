import React from 'react'
import { AdType } from '../../api/ads'
import { Box, Heading, ResponsiveContext, Text } from 'grommet'
import { CheckListItem } from '../../components/text/CheckListItem'
import { ImageOrPlaceholder } from '../../components/media/ImageOrPlaceholder'

type AdListItemContainerProps = {
    ad: AdType
    onClick: () => void
}

export const AdListItemContainer = (props: AdListItemContainerProps) => <ResponsiveContext.Consumer>
    {screenSize => {
        const isScreenSizeSmall = screenSize === 'small'
        return <Box
            round='small'
            direction='row'
            elevation='small'
            border={{size: 'xsmall', color: 'light-5'}}
            onClick={props.onClick}
            overflow='hidden'
        >
            <Box align='center'
                 overflow='hidden'
                 width={isScreenSizeSmall ? 'xsmall' : 'small'}
                 justify='center'
                 alignContent='center'
            >
                <ImageOrPlaceholder src={props.ad.image?.url}/>
            </Box>
            <Box direction='row' width='100%' justify='between' wrap>
                <Box width={{max: 'medium', min: 'xsmall'}} pad={{horizontal: 'medium', vertical: 'small'}}>
                    <Box direction='row' align='center'>
                        <Box direction='row' align='center' gap='small'>
                            <Text color={props.ad.status === 'ACTIVE' ? 'status-ok' : 'status-error'}>
                                {props.ad.status === 'ACTIVE' ? 'Online' : 'Offline'}
                            </Text>
                            <Box background={props.ad.status === 'ACTIVE' ? 'status-ok' : 'status-error'}
                                 pad='xsmall'
                                 round='xsmall'
                            />
                        </Box>
                    </Box>
                    <Heading
                        margin={{vertical: 'xsmall'}}
                        level='4'
                        color='brand'>
                        {props.ad.title === '' ? '- Kein Titel vorhanden -' : props.ad.title}</Heading>
                    {!isScreenSizeSmall &&
                    <Box width='small'>
                        <Text truncate size='small'
                              color='dark-4'>{props.ad.textHeader === '' ? '- Kein Anzeigentext vorhanden -' : props.ad.textHeader}</Text>
                    </Box>}
                </Box>
                <Box direction='row' wrap align={isScreenSizeSmall ? 'center' : 'start'}>
                    <Box pad={isScreenSizeSmall ? {horizontal: 'medium', vertical: 'small'} : 'medium'}
                         width={{max: 'small', min: 'xsmall'}}
                         gap='xsmall'
                         height={isScreenSizeSmall ? 'xsmall' : undefined}
                    >
                        {!isScreenSizeSmall && <Text size='small'>Anzeigeninhalte für</Text>}
                        <Box>
                            {props.ad.jobs.length > 0 ? props.ad.jobs.map((job, idx) =>
                                    <CheckListItem
                                        key={idx}
                                        label={job.name}
                                        isChecked
                                        size='small'/>) :
                                <CheckListItem
                                    label='Kein Job zugeordnet'
                                    isChecked={false}
                                    size='small'/>}
                        </Box>
                    </Box>
                    {!isScreenSizeSmall &&
                    <Box pad='medium' width={{max: 'small', min: 'xsmall'}}
                         gap='xsmall'>
                        <Text size='small'>Inhalte</Text>
                        <Box>
                            <CheckListItem
                                label='Anzeigentext'
                                isChecked={props.ad.textHeader !== '' && props.ad.textFooter !== '' && props.ad.selectedJobBenefits.length > 0}
                                size='small'/>
                            <CheckListItem
                                label='Anzeigenbild'
                                isChecked={props.ad.image !== undefined}
                                size='small'/>
                            <CheckListItem
                                label='Anzeigenvideo'
                                isChecked={props.ad.video !== undefined}
                                size='small'/>
                        </Box>
                    </Box>}

                </Box>
            </Box>
        </Box>
    }
    }
</ResponsiveContext.Consumer>

import React, {useEffect, useState} from "react";
import {LoadingOverlay} from "../../components/layout/LoadingOverlay";
import {getChatbotPlatformUrl} from "../../api/campaigns";
import {Box, Text} from "grommet";

export type Input = {
    campaignId: number
    navigationSection?: string
}

export const XeniooPlatformComponent: React.FC<Input> = (input) => {
    const [platformUrl, setPlatformUrl] = useState<string | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<any>(undefined)

    useEffect(() => {
        loadSourceUrl()
            .catch(err => setError(err))
            .finally(() => setLoading(false))
    }, [])

    const loadSourceUrl = async () => {
        const chatbotPlatformUrlResponse = await getChatbotPlatformUrl(input.campaignId)
        setPlatformUrl(chatbotPlatformUrlResponse.platformUrl)
    }

    if (loading) return <LoadingOverlay/>
    if (!platformUrl || error) return (
        <Box fill justify='center' align='center'>
            <Text>Die Chatbot Platform konnte nicht geladen werden</Text>
        </Box>
    )

    return (
        <iframe src={platformUrl + (input.navigationSection ?? "/build")} height={'100%'} width={'100%'} referrerPolicy={"no-referrer"}/>
    )
}

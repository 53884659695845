import {JwtAccessTokenType, JwtType} from './common'
import {decodeJWT} from "../helpers/JWTParser";

export const userRoles = {
    superuser: 'ROLE_SUPERUSER',
    admin: 'ROLE_ADMIN',
    operator: 'ROLE_OPERATOR',
    client: 'ROLE_CLIENT',
}

export const roleOptions = [{
    name: userRoles.admin,
    label: 'Admin'
}, {
    name: userRoles.operator,
    label: 'Operator'
}, {
    name: userRoles.client,
    label: 'Client'
}]

export function hasRole(user: JwtType | null, userRole: string) {
    if(!user) {
        return false
    }
    const currentUserRoles = decodeJWT<JwtAccessTokenType>(user.access_token).roles
    return currentUserRoles.indexOf(userRole) > -1
}

export function isAdmin(user: JwtType | null) {
    return hasRole(user, userRoles.admin)
}

export function isOperator(user: JwtType | null) {
    return hasRole(user, userRoles.operator)
}

export function isUnovision(user: JwtType | null) {
    return hasRole(user, userRoles.superuser)
}

import React from 'react'
import { MagnifyingGlass } from 'phosphor-react'
import styled from 'styled-components'
import { TextInput } from 'grommet'

type SearchInputProps = {
    onChange: (event: any) => void
    value?: string | number
}

export const SearchInput: React.FC<SearchInputProps> = ({...rest}: SearchInputProps) => (
    <RoundedTextInput
        placeholder='Suche'
        icon={<MagnifyingGlass size={18}/>}
        {...rest}
    />
)

const RoundedTextInput = styled(TextInput)` 
  border-radius: 28px;
  font-size:  ${props => props.theme.text.medium.size};
  :focus {
    box-shadow: none;
    border: 1px solid  ${props => props.theme.global.colors['dark-6']};
  }
`

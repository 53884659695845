import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import {createGlobalStyle} from 'styled-components'
import {Grommet} from 'grommet'
import {Store} from './store/Store'
import {unovisionTheme} from './theme/theme'
import {Router} from './components/router/Router'
import {ExpireTimeBasedNotification} from './components/ExpireTimeBasedNotification'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    overflow: hidden;
  }
`

export const App: React.FC = () => (
    <BrowserRouter>
        <Store>
            <Grommet full theme={unovisionTheme}>
                <ExpireTimeBasedNotification
                    message={"Der Server wird am Montag, den 24. April 2023, von 12:00 Uhr bis 14:00 Uhr Wartungsarbeiten unterzogen. Es kann zu Unterbrechungen des Dienstes kommen."}
                    status={"info"}
                    time={10000}
                    expiryDate={new Date("2023-04-24T22:59:00.000Z")}
                />
                <GlobalStyle/>
                <Router/>
            </Grommet>
        </Store>
    </BrowserRouter>
)

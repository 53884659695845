import { Box, Text } from 'grommet'
import { Heading } from '../../../components/text/Heading'
import React, { PropsWithChildren } from 'react'
import { BoxProps } from 'grommet/es6'
import { Employer } from '../../../resources/icons/svg'

export const EmployerDescriptionEditor = (props: PropsWithChildren<BoxProps>) => {
    return <Box {...props}
                direction={'column'}
                pad={'small'}>
        <Box direction={'column'} gap={'none'} margin={{bottom: 'medium'}}>
            <Box direction={'row'} gap={'small'} align={'center'}>
                <Employer color={'accent-4'} size={'28px'}/>
                <Heading level={4}>Arbeitgeberbeschreibung</Heading>
            </Box>
            <Text color={'brand'} size={'medium'}>
                Eine kurze Information um Bewerbern das Unternehmen schmackhaft zu machen.
            </Text>
        </Box>
        {props.children}
    </Box>
}

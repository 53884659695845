import React, { useEffect } from 'react'
import { Avatar, Box, Text } from 'grommet'
import { JobApplicationRecordedStepType } from '../../../api/jobApplications'
import { JOB_APPLICATION_STEP_DETAILS } from '../JobApplicationSteps'
import { useCurrentCampaign } from '../../../hooks/useCurrentCampaign'
import { User } from 'phosphor-react'
import { formatTimestampToDateAndTime } from '../../../helpers/formatTimestamp'
import { unovisionTheme } from '../../../theme/theme'

type RecordedStepsContainerProps = {
    applicantAvatarUrl?: string,
    recordedSteps: JobApplicationRecordedStepType[]
}

export const RecordedStepsContainer = (props: RecordedStepsContainerProps) => {

    useEffect(() => {
        document.getElementById('end')?.scrollIntoView({behavior: 'smooth'})
    }, [props.recordedSteps])

    const currentCampaign = useCurrentCampaign()
    if(!currentCampaign) return null

    return (
        <Box gap='medium' pad='medium' height={{min: 'auto'}}>
            {props.recordedSteps.map((recordedStep: JobApplicationRecordedStepType, idx: number) => {
                    const jobApplicationStepDetails = JOB_APPLICATION_STEP_DETAILS[recordedStep.jobApplicationStep]
                    if(jobApplicationStepDetails && jobApplicationStepDetails.showInRecordedStepsContainer) {
                        return (
                            <RecordedStepContainer
                                key={idx}
                                fromApplicant={jobApplicationStepDetails.isFromApplicant}
                                avatarUrl={jobApplicationStepDetails.isFromApplicant ?
                                    props.applicantAvatarUrl :
                                    currentCampaign.avatarUrl}
                                recordedStep={recordedStep}
                            />
                        )
                    }
                    return null
                }
            )}
            <div id='end'/>
        </Box>
    )
}

type RecordedStepContainerProps = {
    avatarUrl?: string,
    fromApplicant: boolean,
    recordedStep: JobApplicationRecordedStepType,
}

const RecordedStepContainer = (props: RecordedStepContainerProps) =>
    <Box fill height={{min: 'fit-content'}} align={props.fromApplicant ? 'start' : 'end'}>
        <Box direction='row'>
            {props.fromApplicant && <Box flex='grow' pad='small'>
                <Avatar src={props.avatarUrl} background='brand' size='small'>
                    {props.avatarUrl === undefined &&
                    <User size={24} color={unovisionTheme.global.colors['accent-1']}/>}
                </Avatar>
            </Box>}
            <Box gap='xxsmall'>
                <Box
                    width='medium'
                    pad={{vertical: 'small', horizontal: 'medium'}}
                    round={props.fromApplicant ?
                        {corner: 'top-left', size: 'medium'} :
                        {corner: 'top-right', size: 'medium'}}
                    background={props.fromApplicant ? 'light-3' : 'white'}
                >
                    <Text>{JOB_APPLICATION_STEP_DETAILS[props.recordedStep.jobApplicationStep].label}</Text>
                </Box>
                <Box alignSelf={props.fromApplicant ? 'start' : 'end'}>
                    <Text size='small'>
                        am {formatTimestampToDateAndTime(props.recordedStep.timestamp)} Uhr
                    </Text>
                </Box>
            </Box>
            {!props.fromApplicant &&
            <Box flex='grow' pad='small'>
                <Avatar
                    src={props.avatarUrl}
                    background='brand'
                    size='small'
                    alignSelf='end'
                /></Box>}
        </Box>
    </Box>

import { TableCell, Text} from 'grommet'
import { FormEdit, FormTrash, UserAdd } from 'grommet-icons'
import React, {useCallback, useState} from 'react'
import styled from 'styled-components'
import { UnauthorizedException } from '../../../api/UnauthorizedException'
import { ConfirmDialog, useConfirm } from '../../../components/common/ConfirmDialog'
import { HoverTableRow } from '../../../components/common/HoverTableRow'
import { deleteClient, TenantType } from '../../../api/tenant'
import { useCurrentUser } from '../../../hooks/useCurrentUser'

const FormTrashStyled = styled(FormTrash)`
  vertical-align: bottom;
  cursor: pointer;
`

const FormEditStyled = styled(FormEdit)`
  vertical-align: bottom;
  cursor: pointer;
  margin-right: 3px;
`

const FormUserStyled = styled(UserAdd)`
  vertical-align: bottom;
  cursor: pointer;
`

export const TenantRow = ({
                              tenant,
                              reload,
                          }: { tenant: TenantType, reload: () => void }) => {
    const setCurrentUser = useCurrentUser()[1]

    const handleDelete = useCallback(async() => {
        if(tenant.id) {
            try {
                await deleteClient(tenant.id)
                reload()
            } catch(e) {
                if(e instanceof UnauthorizedException) {
                    setCurrentUser(null)
                }
            }
        }
    }, [reload, tenant.id, setCurrentUser])

    const {confirmVisible, handleConfirmOpen, handleConfirmOk, handleConfirmCancel} = useConfirm(handleDelete)

    const handleAddUser = function () {
        alert("Adding user")
    }

    return (
        <HoverTableRow>
            <TableCell direction={'row'}>
                {confirmVisible && (
                    <ConfirmDialog label={'Kunden löschen?'} onCancel={handleConfirmCancel}
                                   onOk={handleConfirmOk}>
                        <Text>
                            Wollen sie wirklich folgenden Kunden '{tenant.name}' löschen?
                        </Text>
                    </ConfirmDialog>
                )}
                <FormTrashStyled onClick={handleConfirmOpen}/>
                <FormUserStyled onClick={handleAddUser}/>
                {/*<TenantDialog type={'edit'} formValues={tenant} reload={reload}
                              render={(handler: () => void) => <>
                                  <FormEditStyled onClick={handler}/>
                              </>}/>*/}
            </TableCell>
            <TableCell>
                {tenant.name}
            </TableCell>
        </HoverTableRow>
    )
}

export class UnauthorizedException {

    message: string
    name: string

    constructor(message: string) {
        this.message = message
        this.name = 'UnauthorizedException'
    }
}

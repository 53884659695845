import React, { PropsWithChildren, useEffect, useState } from 'react'
import { getJobApplicationsByCampaignId, JobApplicationType } from '../api/jobApplications'
import { useCurrentCampaign } from '../hooks/useCurrentCampaign'
import {JOB_APPLICATION_STATUS_DETAILS, JobApplicationStatusType} from '../ui/jobApplications/JobApplicationStatus'

export const JobApplicationsContext = React.createContext<JobApplicationsContextOptions>({
        jobApplications: [],
        showNotificationBadge: false,
        updateJobApplications: () => {},
        messages: [],
        showMessageNotificationBadge: false,
    }
)

type JobApplicationsContextOptions = {
    jobApplications: JobApplicationType[]
    showNotificationBadge: boolean
    updateJobApplications: () => void
    messages: JobApplicationType[]
    showMessageNotificationBadge: boolean
}

export const JobApplicationsContextProvider = (props: PropsWithChildren<{}>) => {

    const [jobApplications, setJobApplications] = useState<JobApplicationType[]>([])
    const currentCampaign = useCurrentCampaign()

    useEffect(() => {
        updateJobApplications()
        // eslint-disable-next-line
    }, [currentCampaign])

    const updateJobApplications = () => {
        currentCampaign && getJobApplicationsByCampaignId(currentCampaign.id).then(
            (result) => {
                setJobApplications(result.jobApplications)
            }
        ).catch((err) =>
            console.error('Error while loading JobApplications', err)
        )
    }
    const getJobApplications = () => {
        return jobApplications
            .filter((jobApplication) => isJobApplication(jobApplication))
            .sort((x, y) =>
                y.lastApplicantActivityTimestamp?.localeCompare(x.lastApplicantActivityTimestamp))
    }

    const isShowShowJobApplicationNotificationBadge = () => {
        const jobApplications = getJobApplications()
        return !jobApplications.every((message) =>
            JOB_APPLICATION_STATUS_DETAILS[message.applicationStatus].tray !== 'REACTION')
    }

    const getMessages = () => {
        return jobApplications
            .filter((jobApplication) => isMessage(jobApplication))
            .sort((x, y) =>
                y.lastApplicantActivityTimestamp?.localeCompare(x.lastApplicantActivityTimestamp))
    }

    const isShowMessageNotificationBadge = () => {
        const messages = getMessages()
        return !messages.every((message) =>
            JOB_APPLICATION_STATUS_DETAILS[message.applicationStatus].tray !== 'REACTION')
    }

    const isJobApplication = (jobApplication: JobApplicationType): boolean => {
        const statusOfJobApplication: JobApplicationStatusType[] = [
            'CONVERSATION_APPLICANT_SENT_A_MESSAGE',
            'CONVERSATION_WAITING_FOR_APPLICANT_TO_RESPOND',
            'CONVERSATION_CLOSED',
            'APPLICATION_NEW_APPLICANT',
            'APPLICATION_APPLICANT_IS_WAITING',
            'APPLICATION_APPLICANT_IS_HIRED',
            'APPLICATION_APPLICANT_IS_REJECTED',
            'LONG_TIME_NO_CLIENT_ACTION',
            'UNKNOWN_STATUS'
        ]
        return statusOfJobApplication.includes(jobApplication.applicationStatus)
    }

    const isMessage = (jobApplication: JobApplicationType): boolean => {
        const statusOfMessage: JobApplicationStatusType[] = [
            'CONVERSATION_NON_APPLICANT_WROTE_A_MESSAGE',
            'UNKNOWN_STATUS'
        ]
        return statusOfMessage.includes(jobApplication.applicationStatus)
    }

    return (
        <JobApplicationsContext.Provider value={{
            jobApplications: getJobApplications(),
            showNotificationBadge: isShowShowJobApplicationNotificationBadge(),
            updateJobApplications,
            messages: getMessages(),
            showMessageNotificationBadge: isShowMessageNotificationBadge(),
        }}>
            {props.children}
        </JobApplicationsContext.Provider>
    )
}

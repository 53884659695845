import React from 'react'
import { Desktop, Gear, HouseLine, ImageSquare, TextT, UserPlus, YoutubeLogo } from 'phosphor-react'
import { ResponsiveSidebar } from '../../../components/sidebar/ResponsiveSidebar'
import { ResponsiveSidebarCategory } from '../../../components/sidebar/ResponsiveSidebarCategory'
import { ResponsiveSidebarItem } from '../../../components/sidebar/ResponsiveSidebarItem'
import { useHistory, useRouteMatch } from 'react-router-dom'

export const AdDetailSidebar = () => {
    const history = useHistory()
    const {url} = useRouteMatch()

    return <ResponsiveSidebar closeOnSelect={true}>
        <ResponsiveSidebarCategory
            title='Anzeigen Optionen'
            icon={<Gear/>}
            sidebarItems={[
                <ResponsiveSidebarItem
                    label='Vorschau'
                    hideCheckMark
                    icon={<Desktop/>}
                    active={useRouteMatch(`${url}`)?.isExact}
                    onClick={() => {
                        history.push(`${url}`)
                    }}
                />,
                <ResponsiveSidebarItem
                    label='Text bearbeiten'
                    hideCheckMark
                    icon={<TextT/>}
                    active={useRouteMatch(`${url}/edit-text`)?.isExact}
                    onClick={() => {
                        history.push(`${url}/edit-text`)
                    }}
                />,
                <ResponsiveSidebarItem
                    label='Bild bearbeiten'
                    hideCheckMark
                    icon={<ImageSquare/>}
                    active={useRouteMatch(`${url}/edit-image`)?.isExact}
                    onClick={() => {
                        history.push(`${url}/edit-image`)
                    }}
                />,
                <ResponsiveSidebarItem
                    label='Video bearbeiten'
                    hideCheckMark
                    icon={<YoutubeLogo/>}
                    active={useRouteMatch(`${url}/edit-video`)?.isExact}
                    onClick={() => {
                        history.push(`${url}/edit-video`)
                    }}
                />
            ]}/>
        <ResponsiveSidebarCategory
            title={'Job Optionen'}
            icon={<Gear/>}
            sidebarItems={[<ResponsiveSidebarItem
                hideCheckMark
                label={'Jobs der Anzeige zuordnen'}
                icon={<UserPlus/>}
                active={useRouteMatch(`${url}/jobs`)?.isExact}
                onClick={() => {
                    history.push(`${url}/jobs`)
                }}/>]}/>
        <ResponsiveSidebarCategory
            title={'Beworbener Standort'}
            icon={<Gear/>}
            sidebarItems={[<ResponsiveSidebarItem
                hideCheckMark
                label={'Standort der Anzeige zuordnen'}
                icon={<HouseLine/>}
                active={useRouteMatch(`${url}/subsidiaries`)?.isExact}
                onClick={() => {
                    history.push(`${url}/subsidiaries`)
                }}/>]}/>
    </ResponsiveSidebar>
}

import React, { useEffect, useState } from 'react'
import { Box } from 'grommet'
import { GeoTargetsMap } from '../../components/googleMap/GeoTargetsMap'
import { GeoTargetType } from '../../api/campaignManagement'
import { useGeoTargets } from '../../hooks/useGeoTargets'

type GeoTargetsPreviewProps = {
    campaignId: number
}

export const GeoTargetsPreview = (props: GeoTargetsPreviewProps) => {

    const [existingGeoTargets, setExistingGeoTargets] = useState<GeoTargetType[]>([])
    const {getGeoTargets} = useGeoTargets()

    useEffect(() => {
        getGeoTargets(props.campaignId)
            .then(value => setExistingGeoTargets(value))
        // eslint-disable-next-line
    }, [])

    return <Box
        round='small'
        height='small'
        width='100%'
        overflow='hidden'
        border={{color: 'light-6', size: 'xsmall'}}
    >
        <GeoTargetsMap
            readOnly
            existingGeoTargets={existingGeoTargets}
        />
    </Box>
}
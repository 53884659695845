import React, { useMemo, useState } from 'react'
import { EmployerDescriptionContent } from './EmployerDescriptionContent'
import { EmployerDescriptionItem, getEmployerDescriptionByCampaignId } from '../../../api/campaignManagement'
import { useCurrentCampaign } from '../../../hooks/useCurrentCampaign'
import { LoadingOverlay } from '../../../components/layout/LoadingOverlay'
import { BasePage, PageWithBreadcrumbsProps } from '../../../components/layout/BasePage'

export const EmployerDescriptionPage: React.FC<PageWithBreadcrumbsProps> = ({crumbs}: PageWithBreadcrumbsProps) => {
    const currentCampaign = useCurrentCampaign()
    const [isLoaded, setIsLoaded] = useState(false)
    const [employerDescription, setEmployerDescription] = useState<EmployerDescriptionItem[]>()

    useMemo(() => {
        currentCampaign && getEmployerDescriptionByCampaignId(currentCampaign.id).then(
            (result) => {
                setEmployerDescription(result.description)
                setIsLoaded(true)
            }
        )
    }, [currentCampaign, setIsLoaded, setEmployerDescription])

    return (<>
            {isLoaded &&
            <BasePage crumbs={crumbs} title={'Arbeitgeberbeschreibung'}>
                <EmployerDescriptionContent description={employerDescription}/>
            </BasePage>
            }
            <LoadingOverlay
                visible={!isLoaded}
            />
        </>
    )
}

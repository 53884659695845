import React from 'react'
import { Box, Text } from 'grommet'
import styled from 'styled-components'
import {MarginType} from "grommet/es6/utils";

export interface StatusBadgeType {
    label?: string | number
    background?: string
    translateY?: string
    margin?: MarginType;
}

export const StatusBadge: React.FC<StatusBadgeType> = (props: StatusBadgeType) => <>
    {props.label !== undefined &&
    <StyledBox
        {...props}
        direction='row'
        pad={{horizontal: 'xsmall', vertical: 'xxsmall'}}
        width='fit-content'
        height='fit-content'
        round='medium'
        background={props.background}
    >
        <Text margin={{horizontal: 'small'}}>
            {props.label}
        </Text>
    </StyledBox>
    }</>

const StyledBox = styled(Box)<StatusBadgeType>`
    transform: translateY(${props => props.translateY});
`

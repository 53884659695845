import React from 'react'
import { BannerContainer } from '../../components/layout/BannerContainer'
import { BannerCol } from '../../components/layout/BannerCol'
import { Box, ResponsiveContext, Text } from 'grommet'
import { CounterBadgeContainer } from '../../components/common/CounterBadgeContainer'
import { House } from 'phosphor-react'
import { unovisionTheme } from '../../theme/theme'

type DashboardBannerProps = {
    credit: number
    activeAdsCounter: number
    applicantCounter: number
}

export const DashboardBanner: React.FC<DashboardBannerProps> = (props: DashboardBannerProps) =>
    <ResponsiveContext.Consumer>
        {screenSize =>
            <BannerContainer title='Home'>
                <BannerCol displaySeparator justify='center'>
                    <Box direction='row' align='center' gap='small'>
                        {screenSize !== 'small' && <House size={32} color={unovisionTheme.global.colors.brand.light}/>}
                        <Text size='large'>Willkommen in Ihrer Anzeigen Region</Text>
                    </Box>
                </BannerCol>
                <BannerCol direction='row' wrap>
                    <CounterBadgeContainer
                        disabled
                        label='Konto Guthaben'
                        background='accent-2'
                        counter={`${props.credit} €`}
                    />
                    <CounterBadgeContainer
                        label='Aktive Stellenanzeigen'
                        background='status-warning'
                        counter={props.activeAdsCounter}
                    />
                    <CounterBadgeContainer
                        label='Gesamtzahl Bewerber'
                        background='status-ok'
                        counter={props.applicantCounter}
                    />
                </BannerCol>
            </BannerContainer>
        }
    </ResponsiveContext.Consumer>

import { unovisionClient } from './unovisionClient'
import { AddressModel } from '../models/AddressModel'

const CONTEXT_URL = '/api/subsidiary'

export const updateSubsidiary = async(subsidiary: AddressModel): Promise<AddressModel> => {
    const response = await unovisionClient.put<AddressModel>(`${CONTEXT_URL}`, {update: subsidiary})
    return response.data
}

export const deleteSubsidiary = async(id: number): Promise<void> => {
    const response = await unovisionClient.delete(`${CONTEXT_URL}/${id}`)
    return response.data
}

import React, {ChangeEvent, useEffect, useState} from "react";
import {Modal, ModalContent, ModalHeading} from "../../../../components/layout/Modal";
import {Box, Form, FormField, Heading, Paragraph, TextArea} from "grommet";
import {BaseButton} from "../../../../components/buttons/BaseButton";

type Props = {
    campaignName: string,
    dataProcessingDescription?: string,
    setDataProcessingDescription: (dataProcessingDescription: string) => void,
    onCancel: () => void
}

const DataProcessingDescriptionModal: React.FC<Props> = (props) => {

    const [value, setValue] = useState<string | undefined>(undefined)

    useEffect(() => {
        setValue(props.dataProcessingDescription)
    }, [])

    const onInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault()
        setValue(event.target.value)
    }

    const onSubmit = () => {
        props.setDataProcessingDescription(value ?? '')
        props.onCancel()
    }

    return (
        <Modal
            onEsc={props.onCancel}
            onClickClose={props.onCancel}
            onClickOutside={props.onCancel}
        >
            <ModalHeading>
                <Heading level={4}>Prozessabschnitte bearbeiten in Anzeigen-Region {props.campaignName}</Heading>
            </ModalHeading>
            <ModalContent pad={{horizontal:"medium", bottom:"medium"}}>
                <Paragraph color={'accent-1'}>Hier sollte eine kurze leicht verständliche inhaltliche Beschreibung der Prozessabschnitte
                    gegeben werden.</Paragraph>
                <Form>
                    <FormField label={"Wie werden personenbezogene Daten in den Systemen verarbeitet?"}>
                        <Box height={"small"}>
                            <TextArea
                                value={value}
                                onChange={onInputChange}
                                fill={true}
                                resize={false}
                            />
                        </Box>
                    </FormField>
                    <Box direction={'row'} width={'100%'} justify={'end'}>
                        <BaseButton primary
                                    label={'Änderung übernehmen'}
                                    onClick={onSubmit}/>
                    </Box>
                </Form>
            </ModalContent>
        </Modal>
    )
}
export default DataProcessingDescriptionModal

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AddGeoTargetModal } from './AddGeoTargetModal'
import { BaseButton } from '../../../components/buttons/BaseButton'
import { BasePage, PageWithBreadcrumbsProps } from '../../../components/layout/BasePage'
import {
    DEFAULT_GOOGLE_MAPS_CENTER,
    GEO_TARGET_COLORS,
    GeoTargetsMap
} from '../../../components/googleMap/GeoTargetsMap'
import { Box, Text } from 'grommet'
import { CampaignContext } from '../../../store/CampaignContext'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { GeoTargetListContainer } from './GeoTargetListContainer'
import { GeoTargetSearchInput } from './GeoTargetSearchInput'
import { GeoTargetType } from '../../../api/campaignManagement'
import styled from 'styled-components'
import { useGeoTargets } from '../../../hooks/useGeoTargets'
import { MapsInfoWindow } from './MapsInfoWindow'

type GeoTarget = {
    addressString: string
    gpsLocation: GPSCoordinates
}

export type GPSCoordinates = {
    lat: number,
    lng: number
}

const infoWindowDomNode = document.getElementById('maps-info-root')

export const GeoTargetsPage: React.FC<PageWithBreadcrumbsProps> = ({crumbs}: PageWithBreadcrumbsProps) => {

    const maxNumberOfExistingLocations = 3
    // At the moment we start from Germany
    const dummyGeoTarget: GeoTarget = {
        addressString: 'Germany',
        gpsLocation: DEFAULT_GOOGLE_MAPS_CENTER
    }

    const campaignContext = useContext(CampaignContext)
    const {getGeoTargets, addGeoTargetToCampaign, removeGeoTargetFromCampaign} = useGeoTargets()
    const [currentGeoTarget, setCurrentGeoTarget] = useState<GeoTarget | undefined>(dummyGeoTarget)
    const [existingGeoTargets, setExistingGeoTargets] = useState<GeoTargetType[]>([])
    const [showAddGeoTargetModal, setShowAddGeoTargetModal] = useState(false)

    const hideModal = useCallback(() => setShowAddGeoTargetModal(false), [setShowAddGeoTargetModal])

    useEffect(() => {
        refreshExistingGeoTargets()
        // eslint-disable-next-line
    }, [])

    const refreshExistingGeoTargets = () => {
        getGeoTargets(campaignContext.currentCampaign!.id)
            .then((value) => setExistingGeoTargets(value),
                () => {
                    setExistingGeoTargets([])
                })
            .catch(reason => console.log(reason))

    }

    const removeGeoTargetHandler = (index: number) => {
        return removeGeoTargetFromCampaign(
            campaignContext.currentCampaign!.id, index).then(() => refreshExistingGeoTargets())
    }

    const addCurrentGeoTargetToCampaign = (givenName?: string) => {
        if(!currentGeoTarget) return

        const l = currentGeoTarget!

        return addGeoTargetToCampaign(
            campaignContext.currentCampaign!.id,
            l.addressString,
            {latitude: l.gpsLocation.lat, longitude: l.gpsLocation.lng},
            givenName
        )
            .then(() => refreshExistingGeoTargets())
            .catch(reason => console.log(reason))
            .finally(() => setShowAddGeoTargetModal(false))
    }

    function handleShowAddGeoTargetModal(e: any) {
        stopPropagation(e)
        setShowAddGeoTargetModal(true)
    }

    function stopPropagation(e: any) {
        let evt = e ? e : window.event
        if(evt.stopPropagation) evt.stopPropagation()
        if(evt.cancelBubble != null) evt.cancelBubble = true
    }

    const setCurrentSearchGeoTargetFromTextInput = (address: string) => {
        return geocodeByAddress(address)
            .then(results => {
                console.log('GPS retrieved', getLatLng(results[0]))
                return getLatLng(results[0])
            })
            .then(latLng => setCurrentGeoTarget({
                addressString: address,
                gpsLocation: latLng
            }))
            .catch(error => console.error('Error', error))
    }

    const canBeAddedToCampaign = (): boolean => {
        const cl = currentGeoTarget!.gpsLocation

        const gpsTolerance = 0.1       // approx 11km

        let result = existingGeoTargets?.filter(l =>
            Math.abs(l.gpsLocation.latitude - cl.lat) < gpsTolerance && Math.abs(l.gpsLocation.longitude - cl.lng) < gpsTolerance
        )

        return result == null || result.length === 0
    }

    return (
        <BasePage crumbs={crumbs} title={'Ausspielorte'} noPad>
            <StyledContainer fill>
                <GeoTargetsMap
                    existingGeoTargets={existingGeoTargets}
                    currentGeoTarget={currentGeoTarget}
                    setCurrentGeoTarget={setCurrentGeoTarget}
                    domNode={infoWindowDomNode}
                />
                <SearchBarContainer margin='medium'>
                    <GeoTargetSearchInput onResultSelected={setCurrentSearchGeoTargetFromTextInput}/>
                </SearchBarContainer>
                <GeoTargetsContainer margin='medium'>
                    <GeoTargetListContainer
                        geoTargets={existingGeoTargets}
                        onGeoTargetSelectHandler={(l) => setCurrentGeoTarget({
                            addressString: l.addressString,
                            gpsLocation: {lat: l.gpsLocation.latitude, lng: l.gpsLocation.longitude}
                        })}
                        onGeoTargetDeleteHandler={removeGeoTargetHandler}
                        geoTargetColors={GEO_TARGET_COLORS}
                    />
                </GeoTargetsContainer>
            </StyledContainer>
            {
                currentGeoTarget && showAddGeoTargetModal && <AddGeoTargetModal
                    addressString={currentGeoTarget.addressString}
                    onAddGeoTargetClick={addCurrentGeoTargetToCampaign}
                    onEsc={hideModal}
                    onClickClose={hideModal}
                    onClickOutside={hideModal}/>
            }
            <MapsInfoWindow domNode={infoWindowDomNode!!}>
                <Box direction={'column'} gap={'small'} align={'start'} pad={'small'}>
                    <Text weight={'bold'}>Adresse:</Text>
                    <Text>{currentGeoTarget?.addressString}</Text>
                    {
                        existingGeoTargets && existingGeoTargets.length >= maxNumberOfExistingLocations && canBeAddedToCampaign() &&
                        <Text color={'highlight'}>Maximale Anzahl({maxNumberOfExistingLocations}) an Ausspielorten
                            erreicht!</Text>
                    }
                    {
                        existingGeoTargets && existingGeoTargets.length < maxNumberOfExistingLocations && canBeAddedToCampaign() &&
                        <BaseButton primary
                                    onClick={handleShowAddGeoTargetModal}
                                    label={'Hinzufügen'}
                                    alignSelf={'end'}/>
                    }
                </Box>
            </MapsInfoWindow>

        </BasePage>
    )
}

const StyledContainer = styled(Box)`
    position: relative;
    width: 100%;
    height: 100%;
`

const SearchBarContainer = styled(Box)`
    position: absolute;
    top: 0;
`

const GeoTargetsContainer = styled(Box)`
    position: absolute;
    bottom: 0;
`

import React, { useCallback, } from 'react'
import { Avatar, Box, Button, Header, Image, Menu, ResponsiveContext, Text } from 'grommet'
import { CaretDown, CaretLeft } from 'phosphor-react'
import Logo from '../../resources/images/logo-auf-whitebg.svg'
import { logout } from '../../api/common'
import { useCurrentCampaign } from '../../hooks/useCurrentCampaign'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {isUnovision} from "../../api/roles";

export type MetaBarProps = {
    title?: string,
    mobileTitle?: string,
    hideBackButton?: boolean
}

interface DropDownMenuItem {
    label: string
    onClick: () => void
}

export const MetaBar = ({title, mobileTitle, hideBackButton}: MetaBarProps) => {

    const [currentUser, setCurrentUser] = useCurrentUser()
    const history = useHistory()
    const currentCampaign = useCurrentCampaign()

    const onClickLogout = useCallback(() => {
        logout()
        setCurrentUser(null)
        history.push('/login')
    }, [setCurrentUser, history])

    const onClickAdmin = useCallback(()=>{
        history.push('/admin')
    },[history])

    const onClickChangeClient = useCallback(()=>{
        history.push('/select-client')
    },[history])

    const getTitle = (isScreenSizeSmall: boolean) => {
        if(isScreenSizeSmall) {
            return mobileTitle
        } else if (title){
            return title
        } else if (currentCampaign) {
            return `Anzeigen Region: ${currentCampaign.name}`
        } else {
            return 'Willkommen'
        }

    }

    const getDropDownMenuItems = () => {
        const dropDownMenuItems: DropDownMenuItem[] = []

        dropDownMenuItems.push({label: 'Ausloggen', onClick: onClickLogout})
        if (isUnovision(currentUser)) dropDownMenuItems.push({label: 'Admin', onClick: onClickAdmin})
        dropDownMenuItems.push({label:'Klient wechseln', onClick: onClickChangeClient})

        return dropDownMenuItems
    }

    return <Header background='light-2' elevation='small' height='xxsmall'>
        <ResponsiveContext.Consumer>
            {screenSize => {
                const isScreenSizeSmall = screenSize === 'small'
                return (
                    <>
                        <Box width='small'>
                            {isScreenSizeSmall && !hideBackButton ?
                                <Button icon={<CaretLeft size={24}/>} onClick={() => history.goBack()}/>
                                : !isScreenSizeSmall ?
                                    <Box pad={{left: 'large'}}>
                                        <StyledImage src={Logo}
                                                     onClick={() => history.push('/')}/>
                                    </Box> : <Box/>}
                        </Box>
                        <Box width='medium'>
                            <Text
                                textAlign='center'
                                weight='bold'
                                size={isScreenSizeSmall ? 'medium' : 'large'}
                                color='brand'
                            >
                                {getTitle(isScreenSizeSmall)}
                            </Text>
                        </Box>
                        <Box width='small' align='end'>
                            {currentUser && currentCampaign && <Menu
                                icon={
                                    <Box direction='row' align='center' gap='xsmall'>
                                        <Avatar size='xsmall' src={currentCampaign.avatarUrl}/>
                                        <CaretDown size={20}/>
                                    </Box>
                                }
                                dropBackground='light-2'
                                dropAlign={{right: 'right', top: 'bottom'}}
                                items={getDropDownMenuItems()}/>}
                        </Box>
                    </>
                )
            }
            }
        </ResponsiveContext.Consumer>
    </Header>
}

const StyledImage = styled(Image)`
  width: 120px;
  cursor: pointer;
`

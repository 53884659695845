import React, { PropsWithChildren } from 'react'
import { Box, BoxProps } from 'grommet'
import styled from 'styled-components'

type DisableableBoxProps = BoxProps & PropsWithChildren<{
    disabled?: boolean
}>

export const DisableableBox = (props: DisableableBoxProps) => {
    const {disabled, onClick, children, ...rest} = props

    return <StyledBox
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
        {...rest}
    >{children}</StyledBox>
}

const StyledBox = styled(Box)<DisableableBoxProps>`
    ${props => props.disabled ? 'opacity: 0.5;' : ''}
    :focus {
        box-shadow: none;
    }
`

import React, { PropsWithChildren } from 'react'
import { Box } from 'grommet'
import { BoxProps } from 'grommet/es6'

export const RoundDashedBorderBox = ({children, ...rest}: PropsWithChildren<BoxProps>) => {
    return <Box round={'small'}
                pad={'medium'}
                align={'center'}
                gap={'small'}
                border={{side: 'all', style: 'dashed', size: 'small'}}
                {...rest}>
        {children}
    </Box>
}

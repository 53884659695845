import { Image, Stack } from 'grommet'
import { Image as ImageIcon, Placeholder } from 'phosphor-react'
import { unovisionTheme } from '../../theme/theme'
import React from 'react'

type ImageOrPlaceholderProps = {
    src?: string
}
export const ImageOrPlaceholder = ({src}: ImageOrPlaceholderProps) => {
    return src ?
        <Image fit='cover'
               src={src}
               fill/>
        :
        <Stack anchor='center'>
            <Placeholder size={50} color={unovisionTheme.global.colors['light-5']}/>
            <ImageIcon size={24}/>
        </Stack>
}

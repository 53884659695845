import React, { PropsWithChildren } from 'react'
import { BreadCrumbs } from '../navigation/Breadcrumbs'
import { Box, ResponsiveContext } from 'grommet'
import { MainNavigation } from '../navigation/MainNavigation'
import { MetaBar } from '../navigation/MetaBar'
import { RouteType } from '../router/Router'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useCurrentCampaign } from '../../hooks/useCurrentCampaign'

export type PageWithBreadcrumbsProps = {
    crumbs?: RouteType[]
}

export type PageProps = PageWithBreadcrumbsProps & PropsWithChildren<{
    title?: string,
    titleSuffix?: string,
    noPad?: boolean,
    hideBackButton?: boolean,
}>

export const BasePage: React.FC<PageProps> = (props: PageProps) => {
    const [currentUser] = useCurrentUser()
    const currentCampaign = useCurrentCampaign()

    return <ResponsiveContext.Consumer>
        {size =>
            <Box fill>
                <MetaBar hideBackButton={props.hideBackButton} mobileTitle={props.title}/>
                <Box flex overflow={{horizontal: 'hidden'}}>
                    {size !== 'small' && currentUser && <><MainNavigation/> <BreadCrumbs
                        crumbs={props.crumbs}
                        hideBackButton={props.hideBackButton}/></>}
                    <Box fill pad={props.noPad ? undefined : 'medium'}>
                        {props.children}
                    </Box>
                </Box>
                {currentUser && currentCampaign && size === 'small' && <MainNavigation/>}
            </Box>

        }
    </ResponsiveContext.Consumer>
}

import React from 'react'
import styled from 'styled-components'

export type HeadingProps = {
    level?: number,
}

export const Heading: React.FC<HeadingProps> = (props) => {
    const {level, ...cProps} = props
    const Component = level === 2 ? H2
        : level === 3 ? H3
            : level === 4 ? H4
                : level === 5 ? H5
                    : H1

    return (
        <Component
            {...cProps}
        />
    )
}

const H1 = styled.h1`
  font-size: 32px;
  line-height: 39px;
  color: ${props => props.theme.global.colors.h1};
  margin: 10px 0px;
`

const H2 = styled.h2`
  font-size: 36px;
  line-height: 65px;
  color: ${props => props.theme.global.colors.h2};
  margin: 10px 0px;
  letter-spacing: 0.1px;
`

const H3 = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.global.colors.h3};
  margin: 10px 0px;
`

const H4 = styled.h4`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: ${props => props.theme.global.colors.h4};
  margin: 0px;
`

const H5 = styled.h5`
  font-size: 16px;
  font-weight: inherit;
  color: ${props => props.theme.global.colors.h5};
  margin: 12px 0px;
  text-transform: uppercase;
`

import React from 'react'
import { Avatar, Box, Button, ResponsiveContext, Text } from 'grommet'
import { BannerContainer } from '../../../components/layout/BannerContainer'
import { BannerCol } from '../../../components/layout/BannerCol'
import { JOB_APPLICATION_STATUS_DETAILS } from '../JobApplicationStatus'
import { JobApplicationType } from '../../../api/jobApplications'
import { formatTimestampToDate } from '../../../helpers/formatTimestamp'
import { Phone, EnvelopeSimple, User } from 'phosphor-react'
import { Star } from 'grommet-icons'
import { unovisionTheme } from '../../../theme/theme'

type ApplicantDetailBannerProps = {
    currentJobApplication: JobApplicationType,
    toggleFavorite: () => void,
}

export const ApplicantDetailBanner: React.FC<ApplicantDetailBannerProps> = ({
                                                                                currentJobApplication,
                                                                                toggleFavorite
                                                                            }: ApplicantDetailBannerProps) => {
    const jobTitle = currentJobApplication.job?.name

    const {applicant, applicationStatus, isFavorite, applicationTimestamp} = currentJobApplication


    return <BannerContainer title='Bewerberdetails'
                            statusBadge={JOB_APPLICATION_STATUS_DETAILS[applicationStatus]}>
        <ResponsiveContext.Consumer>
            {screenSize =>
                <Box direction='row'>
                    <BannerCol justify='center'>
                        <Box
                            direction='row'
                            align='start'
                            width={{min: 'xsmall'}}
                        >
                            <Avatar
                                border={{color: 'brand', size: 'xsmall'}}
                                src={applicant.avatarUrl}
                                background='brand'
                                size='large'
                            >
                                {applicant.avatarUrl === undefined &&
                                <User size={48} color={unovisionTheme.global.colors['accent-1']}/>}
                            </Avatar>
                            <Button plain
                                    icon={<Star color={isFavorite ? 'brand' : 'light-4'}/>}
                                    onClick={toggleFavorite}/>
                        </Box>
                    </BannerCol>
                    <Box wrap direction='row' width='fit-content'
                         pad={screenSize === 'small' ? {horizontal: 'medium'} : ''}>
                        <BannerCol displaySeparator>
                            <Text
                                weight='bold'
                                size='xlarge'
                                color='brand'
                            >
                                {applicant.firstName} {applicant.lastName}
                            </Text>
                            <Text
                                size='large'
                                weight='bold'
                            >
                                {jobTitle}
                            </Text>
                            <Text size='small' color='grey'>Beworben
                                am {formatTimestampToDate(applicationTimestamp)} </Text>
                        </BannerCol>
                        <BannerCol>
                            <Box direction='row' gap='small' wrap align='center'>
                                <Phone size={24}/>
                                <Text margin='none' size='small' color='grey'>{applicant.phone}</Text>
                            </Box>
                            <Box direction='row' gap='small' wrap align='center'>
                                <EnvelopeSimple size={24}/>
                                <Text size='small' color='grey'>{applicant.email}</Text>
                            </Box>
                        </BannerCol>
                    </Box>
                </Box>
            }
        </ResponsiveContext.Consumer>
    </BannerContainer>
}

import React from 'react'
import { BasePage, PageWithBreadcrumbsProps } from '../../components/layout/BasePage'
import { useCurrentCampaign } from '../../hooks/useCurrentCampaign'
import { DashboardContent } from './DashboardContent'

export const DashboardPage: React.FC<PageWithBreadcrumbsProps> = ({crumbs}: PageWithBreadcrumbsProps) => {
    const currentCampaign = useCurrentCampaign()
    if(!currentCampaign) return null
    return (
        <BasePage crumbs={crumbs} noPad hideBackButton title={currentCampaign.name}>
            <DashboardContent currentCampaign={currentCampaign}/>
        </BasePage>
    )
}

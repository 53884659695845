import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Form, FormField } from 'grommet'
import { RecoveryFormType, sendRecoveryRequest } from '../../../api/recovery'
import { handleServiceCall } from '../../../api/common'
import { required } from '../../../components/inputs/validation'
import { LoginBox } from '../login/LoginBox'
import { ControlButtons } from '../../../components/buttons/ControlButtons'
import { BasePage } from '../../../components/layout/BasePage'


export const Recovery: React.FC = () => {
    const [value, setValue] = useState<RecoveryFormType>({username: ''})
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const handleChange = useCallback(setValue, [setValue])

    const handleSubmit = useCallback(async({value}) => {
        await handleServiceCall(
            async() => {
                await sendRecoveryRequest(value)
                setSubmitted(true)
            },
            setLoading,
            () => {
            },
            errorCode => alert(`Es ist ein Fehler aufgetreten: ${errorCode}`),
        )
    }, [setSubmitted])

    return (
        <BasePage>
            <LoginBox pad='medium' direction='column'>
                <Form<RecoveryFormType>
                    value={value}
                    onChange={handleChange}
                    onSubmit={handleSubmit}>

                    <h1>Passwortwiederherstellung</h1>

                    {!submitted ? (
                        <>
                            <FormField
                                label='Benutzername'
                                name='username'
                                validate={required}
                            />

                            <ControlButtons>
                                <Box pad='xsmall'>
                                    <Link to='/'>Zurück</Link>
                                </Box>

                                <Button
                                    primary
                                    disabled={loading}
                                    type='submit'
                                    label='Anfordern'
                                />
                            </ControlButtons>
                        </>
                    ) : (
                        <>
                            <Box>
                                In den nächsten Minuten sollte eine Email mit einem Link zum Zurücksetzen Ihres
                                Passwortes in Ihrem Postfach sein. Falls Sie keine Email bekommen, sind sie entweder
                                nicht der Besitzer des angeforderten Kontos oder wir haben Ihren Benutzernamen nicht
                                in unserer Datenbank gefunden. Kontaktieren Sie in diesem Fall bitte den Support unter
                                <a href={'mailto:support@alphacure.de'}>support@alphacure.de</a>
                            </Box>

                            <Box pad={{top: 'xsmall'}}>
                                <Link to='/signin'>Zurück</Link>
                            </Box>
                        </>
                    )}
                </Form>
            </LoginBox>
        </BasePage>
    )
}

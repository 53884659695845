import React, { PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'

type InfoWindowContentProps = PropsWithChildren<{
    domNode: HTMLElement
}>

export class MapsInfoWindow extends React.Component<InfoWindowContentProps> {
    private readonly container: HTMLDivElement

    constructor(props: InfoWindowContentProps) {
        super(props)
        this.container = document.createElement('div')
    }

    componentDidMount() {
        this.props.domNode.appendChild(this.container)
    }

    componentWillUnmount() {
        this.props.domNode.removeChild(this.container)
    }

    render() {
        return ReactDOM.createPortal(this.props.children, this.container)
    }
}

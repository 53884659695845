import { FormField, Text, TextArea, TextAreaProps, ThemeContext } from 'grommet'
import React from 'react'
import { unovisionTheme } from '../../theme/theme'
import styled from 'styled-components'

interface DashedTextAreaProps extends TextAreaProps {
    maxLength?: number,
    onChange: (event: any) => void
}

const formFieldTheme = {
    formField: {
        border: false,
    }
}

export const DashedTextArea: React.FC<DashedTextAreaProps> = (props: DashedTextAreaProps) => {
    return <ThemeContext.Extend value={formFieldTheme}>
        <FormField
            info={
                <Text size={'medium'} textAlign={'end'}>
                    {props.maxLength && (props.value?.length || 0) + '/' + props.maxLength}
                </Text>
            }>
            <EditableBox maxLength={props.maxLength}
                         value={props.value}
                         resize={props.resize}
                         onChange={props.onChange}/>
        </FormField>
    </ThemeContext.Extend>
}


const EditableBox = styled(TextArea)`
    font-weight: normal;
    border: 2px dashed;
    border-color: ${() => unovisionTheme.global.colors['border-text-area']};
    background-color: ${() => unovisionTheme.global.colors['background-highlight-box']};
    font-size: ${() => unovisionTheme.text.large.size};
    color: ${() => unovisionTheme.global.colors.text};
`

import React, {useEffect, useState} from 'react'
import {JobApplicationType, setJobApplicationIsFavorite,} from '../../api/jobApplications'
import {Box} from 'grommet'
import {ContentLayout} from '../../components/layout/ContentLayout'
import {JobApplicationStatusType} from '../jobApplications/JobApplicationStatus'
import {useParams} from 'react-router-dom'
import {ApplicantChatComponent} from "../jobApplications/applicantDetail/ApplicantChatComponent";
import {ApplicantDetailBanner} from "../jobApplications/applicantDetail/ApplicantDetailBanner";
import {useMessages} from "../../hooks/useMessages";

const initialMessage: JobApplicationType = {
    id: '',
    campaignId: 0,
    isFavorite: false,
    applicant: {
        id: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        description: '',
        avatarUrl: ''
    },
    applicationTimestamp: '',
    applicationStatus: 'UNKNOWN_STATUS' as JobApplicationStatusType,
    lastApplicantActivityTimestamp: ''
}

export const MessageDetailContent = () => {
    const params = useParams<{ messageId: string }>()
    const [currentMessage, setCurrentMessage] = useState({...initialMessage, id: params.messageId})

    const messages = useMessages()
    useEffect(() => {
        setCurrentMessage(messages.find((message) => message.id === params.messageId)!)
    }, [])

    const toggleFavorite = () => {
        setJobApplicationIsFavorite(currentMessage.id, !currentMessage.isFavorite).then((favoriteResponse) => {
                setCurrentMessage({
                    ...currentMessage,
                    isFavorite: favoriteResponse.isFavorite
                })
            }
        ).catch((err) => {
            console.error('Error while updating favorite flag', err)
        })
    }

    return <>
        <ContentLayout
            banner={<ApplicantDetailBanner
                currentJobApplication={currentMessage}
                toggleFavorite={toggleFavorite}
            />}>
            <Box gap='medium'>
                <ApplicantChatComponent applicantId={currentMessage.id}/>
            </Box>
        </ContentLayout>
    </>
}

import React from 'react'
import {
    FacebookLogo,
    Gear,
    Info,
    MapPin,
    NotePencil,
    Scales,
    TreeStructure,
    UsersThree
} from 'phosphor-react'
import { ResponsiveSidebar } from '../../components/sidebar/ResponsiveSidebar'
import { ResponsiveSidebarCategory } from '../../components/sidebar/ResponsiveSidebarCategory'
import { ResponsiveSidebarItem } from '../../components/sidebar/ResponsiveSidebarItem'
import { useHistory, useRouteMatch } from 'react-router-dom'

export const DashboardSidebar = () => {
    const history = useHistory()
    const {url} = useRouteMatch()

    return (
        <ResponsiveSidebar icon={<Gear size={20} weight='bold' color='white'/>}>
            <ResponsiveSidebarCategory
                icon={<Gear/>}
                title={'Bestandteile der Anzeigen Region'}
                sidebarItems={[
                    <ResponsiveSidebarItem
                        disabled
                        label={'Facebook Seite'}
                        icon={<FacebookLogo/>}
                        onClick={() => {
                        }}
                    />,
                    <ResponsiveSidebarItem
                        label={'Ausspielorte'}
                        icon={<MapPin/>}
                        onClick={() => history.push(`${url}/geo-targets`)}/>,
                    <ResponsiveSidebarItem
                        disabled
                        label={'Mitarbeiter'}
                        icon={<UsersThree/>}
                        onClick={() => {
                        }}/>,
                    <ResponsiveSidebarItem
                        label={'Arbeitgeberbeschreibung'}
                        icon={<Info/>}
                        onClick={() => history.push(`${url}/employer-description`)}/>,
                    <ResponsiveSidebarItem
                        disabled
                        label={'Chatbot Vorschau'}
                        icon={<TreeStructure/>}
                        onClick={() => {
                        }}/>,
                    <ResponsiveSidebarItem
                        label={'Datenschutz'}
                        icon={<Scales/>}
                        onClick={() => history.push(`${url}/data-privacy`)}/>,
                    <ResponsiveSidebarItem
                        disabled
                        label={'Nachrichten Vorlagen'}
                        icon={<NotePencil/>}
                        onClick={() => {
                        }}/>
                ]
                }/>
        </ResponsiveSidebar>
    )
}

import React from 'react'
import { BasePage, PageWithBreadcrumbsProps } from '../../components/layout/BasePage'
import { AdsContent } from './AdsContent'

export const AdsPage: React.FC<PageWithBreadcrumbsProps> = ({crumbs}: PageWithBreadcrumbsProps) => {
    return (
        <BasePage hideBackButton crumbs={crumbs} noPad title={'Stellenanzeigen'}>
            <AdsContent/>
        </BasePage>
    )
}

import React from 'react'
import styled from 'styled-components'
import { Box, BoxProps, Button, Layer, LayerProps } from 'grommet'
import { Close } from 'grommet-icons'

export type ModalProps = LayerProps & {
    headless?: boolean,
    onClickClose?: () => void
}

export const Modal: React.FC<ModalProps> = ({onClickClose, headless, children, ...rest}) => (
    <Layer animation={'fadeIn'} {...rest}>
        {!headless && (
            <ModalClose
                onClickClose={onClickClose}
            />
        )}
        {children}
    </Layer>
)

export const ModalClose: React.FC<{ onClickClose?: () => void }> = ({onClickClose}) => (
    <Box flex={false} pad={'small'} align={'end'}>
        <Button
            icon={<Close/>}
            onClick={onClickClose}
        />
    </Box>
)

export const ModalHeading: React.FC<BoxProps> = (props) => {
    const {pad = {horizontal: 'medium'}} = props

    return (
        <Box
            {...props}
            flex={false}
            pad={pad}
        />
    )
}

export const ModalContent: React.FC<BoxProps> = (props) => {
    const {pad = {horizontal: 'medium'}, children, ...rest} = props

    return (
        <Scrollable>
            <Box flex={false} pad={pad} {...rest}>
                {children}
            </Box>
        </Scrollable>
    )
}

const Scrollable = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
`

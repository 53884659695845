import { Box, Form, Text, TextInput } from 'grommet'
import { Add } from 'grommet-icons'
import * as React from 'react'
import { useState } from 'react'
import { Modal, ModalContent, ModalHeading, ModalProps } from '../../../components/layout/Modal'
import { Heading } from '../../../components/text/Heading'
import { BaseButton } from '../../../components/buttons/BaseButton'

type AddGeoTargetModalProps = ModalProps & {
    addressString: string
    onAddGeoTargetClick: (givenName?: string) => void
}

export const AddGeoTargetModal = (props: AddGeoTargetModalProps) => {
    const {addressString, onAddGeoTargetClick, ...rest} = props
    const [givenName, setGivenName] = useState<string>('')

    return (
        <Modal {...rest}>
            <ModalHeading>
                <Heading level={4}>Anzeigenregion hinzufügen</Heading>
            </ModalHeading>
            <ModalContent>
                <Form onSubmit={() => onAddGeoTargetClick(givenName)}>
                    <Box gap={'small'} margin={'medium'}>
                        <Text>Bitte geben Sie der Anzeigenregion einen Namen</Text>
                        <TextInput size='medium' value={givenName} onChange={event => setGivenName(event.target.value)}
                                   autoFocus/>
                        <Text weight={'bold'} color={'h2'}>{addressString}</Text>
                        <BaseButton primary
                                    alignSelf={'end'}
                                    label={'Hinzufügen'}
                                    icon={<Add color='white' size='small'/>}
                                    size={'medium'}
                                    type={'submit'}/>
                    </Box>
                </Form>
            </ModalContent>
        </Modal>
    )
}

import React, { useEffect, useState } from 'react'
import { Box, Image, ResponsiveContext, Text } from 'grommet'
import { CampaignModel } from '../../models/CampaignModel'
import { ContentLayout } from '../../components/layout/ContentLayout'
import { CounterBadgeContainer } from '../../components/common/CounterBadgeContainer'
import { DashboardSidebar } from './DashboardSidebar'
import { DashboardBanner } from './DashboardBanner'
import { DisableableBox } from '../../components/common/DisableableBox'
import { GeoTargetsPreview } from './DashboardGeoTargetsMap'
import { IconContext, ListBullets, Money, UserList } from 'phosphor-react'
import { unovisionTheme } from '../../theme/theme'
import { useHistory } from 'react-router-dom'
import { useJobApplications } from '../../hooks/useJobApplications'
import AdsImageSrc from '../../resources/images/dashboard-button-ads.png'
import JobApplicationsImageSrc from '../../resources/images/dashboard-button-applications.png'
import CreditImageSrc from '../../resources/images/dashboard-button-credit.png'
import { getAdsByCampaignId } from '../../api/campaigns'

type DashboardContentProps = {
    currentCampaign: CampaignModel
}

export const DashboardContent: React.FC<DashboardContentProps> = (props: DashboardContentProps) => {
    const history = useHistory()
    const jobApplications = useJobApplications()
    const creditDummy = 0
    const [activeAdsCounter, setActiveAdsCounter] = useState<number>(0)

    useEffect(() => {
        props.currentCampaign && getAdsByCampaignId(props.currentCampaign.id).then((adsResponse) => {
            setActiveAdsCounter(adsResponse.advertisements.filter(ad => ad.status === 'ACTIVE').length)
        })
    }, [props.currentCampaign])

    return (
        <ContentLayout sidebar={<DashboardSidebar/>}
                       banner={<DashboardBanner
                           credit={creditDummy}
                           activeAdsCounter={activeAdsCounter}
                           applicantCounter={jobApplications.length}
                       />}>
            <Box>
                <Box pad={{horizontal: 'small', bottom: 'small'}} gap='small'>
                    <GeoTargetsPreview campaignId={props.currentCampaign.id}/>
                    <Text>Kampagnenbeschreibung: zBsp. - Welche Häuser sind in dieser Kampagne
                        beteiligt?</Text>
                </Box>
                <Box direction='row' wrap>
                    <IconContext.Provider value={{
                        size: 20,
                        color: unovisionTheme.global.colors.brand.light
                    }}>
                        <DashboardButton
                            label={'Stellenanzeigen'}
                            counterBadge={<CounterBadgeContainer background={'status-warning'}
                                                                 counter={activeAdsCounter}/>}
                            icon={<ListBullets/>}
                            imageUrl={AdsImageSrc}
                            onClick={() => history.push(`/campaign/${props.currentCampaign.id}/advertisements`)}
                        />
                        <DashboardButton
                            label={'Bewerbungen'}
                            counterBadge={<CounterBadgeContainer background={'status-ok'}
                                                                 counter={jobApplications.length}/>}
                            icon={<UserList/>}
                            imageUrl={JobApplicationsImageSrc}
                            onClick={() => history.push(`/campaign/${props.currentCampaign.id}/applicant-management`)}
                        />
                        <DashboardButton
                            disabled
                            label={'Guthaben'}
                            counterBadge={<CounterBadgeContainer background={'accent-2'}
                                                                 counter={`${creditDummy} €`}/>}
                            icon={<Money/>}
                            imageUrl={CreditImageSrc}/>
                    </IconContext.Provider>
                </Box>
            </Box>
        </ContentLayout>
    )
}

type DashboardButtonProps = {
    label: string
    counterBadge: JSX.Element
    icon: JSX.Element
    imageUrl: string
    onClick?: () => void
    disabled?: boolean
}

const DashboardButton = (props: DashboardButtonProps) =>
    <Box pad='small' flex width={{min: '20em'}}>
        <DisableableBox
            disabled={props.disabled}
            onClick={props.onClick}
            background='light-2'
            round='small'
            height='small'
            elevation='small'
        >
            <Box
                height='medium'
                overflow='hidden'
                round={{corner: 'top', size: 'small'}}
            >
                <Image fit='cover' src={props.imageUrl}/>
            </Box>
            <ResponsiveContext.Consumer>
                {screenSize =>
                    <Box
                        pad={screenSize === 'small' ? 'medium' : 'small'}
                        direction='row'
                        justify='between'
                    >
                        <Box
                            direction='row'
                            gap='small'
                            align='center'
                        >
                            <Box>
                                {props.icon}
                            </Box>
                            <Text color='brand'>{props.label}</Text>
                        </Box>
                        {props.counterBadge}
                    </Box>}
            </ResponsiveContext.Consumer>
        </DisableableBox>
    </Box>

import { Box, Text } from 'grommet'
import { Heading } from '../text/Heading'
import React from 'react'
import { BoxProps } from 'grommet/es6'
import { Bot } from '../../resources/icons/svg'
import styled from 'styled-components'

export type ChatBotPreviewBoxProps = BoxProps & {
    headless?: boolean
}

export const ChatBotPreviewBox: React.FC<ChatBotPreviewBoxProps> = ({headless, children}) => {
    return <Box
        direction={'column'}
        pad={headless ? 'none' : 'small'}>
        {!headless && (
            <Box direction={'column'} gap={'none'} margin={{bottom: 'medium'}}>
                <Box direction={'row'} gap={'small'} align={'center'}>
                    <Bot/>
                    <ChatBotHeading level={4}>Bot-Vorschau</ChatBotHeading>
                </Box>
                <Text color={'brand'} size={'medium'}>
                    So sehen Ihre Bewerber die Beschreibung im Chatbot
                </Text>
            </Box>
        )}
        <Box gap={'medium'} margin={{bottom: 'medium'}}>
            {children}
        </Box>
    </Box>
}

const ChatBotHeading = styled(Heading)`
    color: ${(props) => props.theme.global.colors['text-dark-gray']};
`

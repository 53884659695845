import GoogleMapReact, { Coords } from 'google-map-react'
import { useEffect, useMemo, useState } from 'react'
import { GeoTargetType } from '../../api/campaignManagement'
import { unovisionTheme } from '../../theme/theme'
import { GPSCoordinates } from '../../ui/dashboard/geoTargets/GeoTargetsPage'

type GeoTargetsMapProps = {
    existingGeoTargets: GeoTargetType[]
    currentGeoTarget?: any
    setCurrentGeoTarget?: (target: any) => void
    domNode?: any
    readOnly?: boolean
}

export const DEFAULT_GOOGLE_MAPS_CENTER = {
    lat: 51.165691,
    lng: 10.451526
}

export const GEO_TARGET_COLORS = [unovisionTheme.global.colors['accent-1'], unovisionTheme.global.colors['accent-2'], unovisionTheme.global.colors['accent-3'], unovisionTheme.global.colors['text-dark-gray']]

export const GeoTargetsMap = (props: GeoTargetsMapProps) => {

    const DEFAULT_GOOGLE_MAPS_ZOOM = 6
    const radiusInMeters = 30000

    const {existingGeoTargets} = props
    const [existingGeoTargetCirclesOnMap, setExistingGeoTargetCirclesOnMap] = useState<google.maps.Circle[]>([])
    const [existingGeoTargetMarkersOnMap, setExistingGeoTargetMarkersOnMap] = useState<google.maps.Marker[]>([])
    const [center, setCenter] = useState<Coords>()
    const [zoom, setZoom] = useState<number>()

    const [googleMapInstance, setGoogleMapInstance] = useState<any>({
        mapInstance: null,
        mapApi: null,
    })

    const {geocoder, infoWindow, mapMarker} = useMemo(() => {
        return {
            geocoder: new google.maps.Geocoder(),
            infoWindow: new google.maps.InfoWindow(),
            mapMarker: {
                marker: new google.maps.Marker(),
                circle: new google.maps.Circle()
            }
        }
    }, [])

    const getMapBounds = (mapApi: any, geoTargets: GeoTargetType[]) => {
        const bounds = new mapApi.LatLngBounds()
        geoTargets.forEach((geoTarget) => {
            bounds.extend(
                new mapApi.LatLng(geoTarget.gpsLocation.latitude, geoTarget.gpsLocation.longitude),
            )
        })
        return bounds
    }

    const bindResizeListener = (bounds: any) => {
        googleMapInstance.mapApi.event.addDomListenerOnce(googleMapInstance.mapInstance, 'idle', () => {
            googleMapInstance.mapApi.event.addDomListener(window, 'resize', () => {
                googleMapInstance.mapInstance.fitBounds(bounds)
            })
        })
    }

    const centerMapAccordingGeoTargets = (
        setCenter: (coords: Coords) => void,
        setZoom: (zoom: number) => void
    ) => {
        if(googleMapInstance.mapInstance && existingGeoTargets.length > 0) {
            if(existingGeoTargets.length === 1) {
                setCenter({
                    lat: existingGeoTargets[0].gpsLocation.latitude,
                    lng: existingGeoTargets[0].gpsLocation.longitude
                })
                setZoom(8)
            } else {
                const bounds = getMapBounds(googleMapInstance.mapApi, existingGeoTargets)
                googleMapInstance.mapInstance.fitBounds(bounds)
                bindResizeListener(bounds)
            }
        }
    }

    const getGeoTargetMarkerOptions = (color: string) => {
        return {
            path: 'M29.4732 77.6023L30.2715 78.6602L31.0698 77.6023L55.3861 45.3758C56.8292 43.4633 57.8609 40.8786 58.5339 38.2173C59.2099 35.5443 59.5429 32.7192 59.5429 30.2715C59.543 14.1053 46.4377 1 30.2715 1C14.1053 1 1 14.1053 1 30.2715C1 35.5881 2.03454 41.2378 5.15681 45.3758L5.95506 44.7734L5.15681 45.3758L29.4732 77.6023ZM30.2715 19.3742C36.289 19.3742 41.1688 24.254 41.1688 30.2714C41.1688 36.289 36.289 41.1688 30.2715 41.1688C24.254 41.1688 19.3743 36.289 19.3743 30.2715C19.3743 24.254 24.254 19.3742 30.2715 19.3742Z',
            fillColor: color,
            fillOpacity: 0.7,
            strokeWeight: 0,
            rotation: 0,
            scale: 0.7,
            anchor: new google.maps.Point(29.27, 77.66),
        }
    }

    const getGeoTargetCircleOptions = (
        latitude: number,
        longitude: number,
        color: string,
    ) => {
        return {
            center: {lat: latitude, lng: longitude},
            radius: radiusInMeters,
            map: googleMapInstance.mapInstance,
            strokeColor: color,
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: color,
            fillOpacity: 0.3
        }
    }

    const clearCirclesAndMarkers = () => {
        existingGeoTargetCirclesOnMap.forEach(value => value.setMap(null))       // remove all circles from map
        setExistingGeoTargetCirclesOnMap([])     // empty the store

        existingGeoTargetMarkersOnMap.forEach(value => value.setMap(null))       // remove all circles from map
        setExistingGeoTargetMarkersOnMap([])     // empty the store
    }

    const showSelectedGeoTargetsOnMap = () => {
        if(!props.currentGeoTarget?.gpsLocation) return

        const l = props.currentGeoTarget.gpsLocation

        mapMarker.marker.setOptions({
            position: l,
            map: googleMapInstance.mapInstance,
            icon: getGeoTargetMarkerOptions(GEO_TARGET_COLORS[existingGeoTargets!.length])
        })

        mapMarker.circle.setOptions(getGeoTargetCircleOptions(l.lat, l.lng, GEO_TARGET_COLORS[existingGeoTargets!.length]))

        infoWindow.setContent(props.domNode)
        infoWindow.open(googleMapInstance.mapInstance, mapMarker.marker)
    }

    const handleOnMapClick = (gpsLocation: GPSCoordinates) => {
        geocoder.geocode(
            {location: gpsLocation},
            (
                results: google.maps.GeocoderResult[] | null,
                status: google.maps.GeocoderStatus
            ) => {
                if(status === 'OK') {
                    if(results && results[0]) {
                        props.setCurrentGeoTarget && props.setCurrentGeoTarget({
                            addressString: results[0].formatted_address,
                            gpsLocation: {lat: gpsLocation.lat, lng: gpsLocation.lng}
                        })
                    } else {
                        window.alert('No results found')
                    }
                } else {
                    window.alert('Geocoder failed due to: ' + status)
                }
            }
        )
    }

    useEffect(() => {
        clearCirclesAndMarkers()

        const addedGeoTargetCircles: google.maps.Circle[] = []
        const addedGeoTargetMarkers: google.maps.Marker[] = []

        googleMapInstance.mapInstance && existingGeoTargets && existingGeoTargets.forEach((value, index) => {
            addedGeoTargetCircles.push(new google.maps.Circle(getGeoTargetCircleOptions
            (value.gpsLocation.latitude, value.gpsLocation.longitude, GEO_TARGET_COLORS[index])))
            addedGeoTargetMarkers.push(new google.maps.Marker({
                position: {lat: value.gpsLocation.latitude, lng: value.gpsLocation.longitude},
                icon: getGeoTargetMarkerOptions(GEO_TARGET_COLORS[index]),
                map: googleMapInstance.mapInstance,
            }))
        })

        setExistingGeoTargetCirclesOnMap(addedGeoTargetCircles)
        setExistingGeoTargetMarkersOnMap(addedGeoTargetMarkers)

        centerMapAccordingGeoTargets(setCenter, setZoom)
        // eslint-disable-next-line
    }, [googleMapInstance, existingGeoTargets])

    useEffect(() => {
        googleMapInstance.mapInstance && props.currentGeoTarget && showSelectedGeoTargetsOnMap()
        // eslint-disable-next-line
    }, [props.currentGeoTarget])

    return <GoogleMapReact
        defaultCenter={DEFAULT_GOOGLE_MAPS_CENTER}
        center={props.currentGeoTarget?.gpsLocation || center}
        defaultZoom={DEFAULT_GOOGLE_MAPS_ZOOM}
        zoom={zoom}
        options={props.readOnly ? {
            draggableCursor: 'auto',
            fullscreenControl: false,
            zoomControl: false,
            gestureHandling: 'none',
            disableDefaultUI: true,
            keyboardShortcuts: false
        } : {fullscreenControl: false}}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({map, maps}) => setGoogleMapInstance({mapInstance: map, mapApi: maps})}
        onClick={props.readOnly ? undefined : handleOnMapClick}
    />
}

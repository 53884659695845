import { CAMPAIGN_CONTEXT_URL } from './campaigns'
import { unovisionClient } from './unovisionClient'

const CONTEXT_URL = '/api/job'
const MIN_JOB_DESCRIPTION_LENGTH = 4

interface NewJobType {
    campaignId: number,
    jobName: string,
    description: string[]
}

interface UpdateJobType {
    job: JobType
    description: JobDescriptionType[]
}

export type JobType = {
    id: number
    name: string
}

export type JobDescriptionType = {
    id?: number,
    jobId?: number,
    text: string,
}

export const getJobsByCampaignId = async(campaignId: number): Promise<JobType[]> => {
    const response = await unovisionClient.get<JobType[]>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}/job`)

    return response.data
}

export const updateJob = async(update: UpdateJobType): Promise<JobType> => {
    const response = await unovisionClient
        .put<JobType>(`${CONTEXT_URL}/${update.job.id}`, update)

    return response.data
}

export const saveJob = async(newJob: NewJobType): Promise<JobType> => {
    const response = await unovisionClient
        .post<JobType>(`${CONTEXT_URL}`, newJob)

    return response.data
}


export const getJobDescription = async(jobId: number): Promise<JobDescriptionType[]> => {
    const response = await unovisionClient.get<JobDescriptionType[]>(`${CONTEXT_URL}/${jobId}/description`)

    while(response.data.length < MIN_JOB_DESCRIPTION_LENGTH) {
        response.data.push({jobId, text: ''})
    }

    return response.data
}

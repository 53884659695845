import React from 'react'
import { BasePage, PageWithBreadcrumbsProps } from '../../../components/layout/BasePage'
import { DataPrivacyContent } from './DataPrivacyContent'
import { useCurrentCampaign } from '../../../hooks/useCurrentCampaign'

export const DataPrivacyPage: React.FC<PageWithBreadcrumbsProps> = ({crumbs}: PageWithBreadcrumbsProps) => {
    const currentCampaign = useCurrentCampaign()
    if(!currentCampaign) return null

    return (
        <BasePage crumbs={crumbs} title={'Datenschutzinfo für '} titleSuffix={currentCampaign.name}>
            <DataPrivacyContent currentCampaign={currentCampaign}/>
        </BasePage>
    )
}

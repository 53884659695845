import { useEffect, useState } from 'react'
import {
    getCampaignJobBenefits,
    JobBenefitType,
    saveCampaignJobBenefits as saveJobBenefits,
    SaveJobBenefitType,
} from '../api/jobBenefits'
import { useCurrentCampaign } from './useCurrentCampaign'

export const useCampaignJobBenefits = () => {
    const currentCampaign = useCurrentCampaign()
    const [allCampaignJobBenefits, setAllCampaignJobBenefits] = useState<JobBenefitType[] | undefined>()

    useEffect(() => {
        currentCampaign && getCampaignJobBenefits(currentCampaign.id).then(
            (response) => {
                setAllCampaignJobBenefits(response)
            })
    }, [currentCampaign])


    async function saveCampaignJobBenefits(jobBenefits: SaveJobBenefitType[]): Promise<JobBenefitType[] | undefined> {
        if(currentCampaign) {
            const newJobBenefits = await saveJobBenefits(currentCampaign.id, jobBenefits)
            setAllCampaignJobBenefits(allCampaignJobBenefits?.concat(newJobBenefits))
            return newJobBenefits
        }
        return undefined
    }

    return {
        saveCampaignJobBenefits: saveCampaignJobBenefits,
        allCampaignJobBenefits: allCampaignJobBenefits,
    }
}


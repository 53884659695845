import {CAMPAIGN_CONTEXT_URL} from './campaigns'
import {unovisionClient} from './unovisionClient'
import {AddressModel} from '../models/AddressModel'
import {ContactModel} from '../models/ContactModel'
import {JobModel} from '../models/JobModel'
import {SelectedDataPrivacyLawType} from '../ui/dashboard/dataPrivacy/DataPrivacyContent'

export const EMPLOYER_DESCRIPTION_CONTEXT_URL = '/employer-description'
export const DATA_PRIVACY_CONTEXT_URL = '/data-privacy'
export const PUBLIC_DATA_PRIVACY_CONTEXT_URL = '/api/data-privacy'
export const GEO_TARGET_CONTEXT_URL = '/geo-target'
export const CAMPAIGN_MAIN_ADDRESS = '/main-address'

export type EmployerDescriptionType = {
    campaignId: string,
    description: EmployerDescriptionItem[]
}
export type UpdateEmployerDescriptionType = {
    description: EmployerDescriptionItem[]
}
export type EmployerDescriptionItem = {
    text: string
}

export type DataPrivacyDTOType = {
    id: number,
    campaignId: number,
    selectedDataPrivacyLaw: SelectedDataPrivacyLawType,
    subsidiaries?: AddressModel[],
    campaignMainAddresses?: AddressModel[],
    dataPrivacyOfficers?: ContactModel[],
    dataProcessingDescription?: string,
    updatedDate?: Date
}

export type DataPrivacyResponseType = {
    dataPrivacyDTO?: DataPrivacyDTOType,
    jobList: JobModel[]
}

export type CreateDataPrivacyType = {
    campaignId: number,
    selectedDataPrivacyLaw: SelectedDataPrivacyLawType,
    subsidiaries?: AddressModel[],
    dataPrivacyOfficers?: ContactModel[],
    dataProcessingDescription?: string,
    campaignMainAddresses?: AddressModel[],
}

export const getEmployerDescriptionByCampaignId = async(campaignId: number): Promise<EmployerDescriptionType> => {
    const response = await unovisionClient
        .get<EmployerDescriptionType>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}${EMPLOYER_DESCRIPTION_CONTEXT_URL}`)
    return response.data
}

export const updateEmployerDescription = async(campaignId: number,
                                               updateEmployerDescriptionType: UpdateEmployerDescriptionType) => {
    const response = await unovisionClient
        .put<UpdateEmployerDescriptionType>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}${EMPLOYER_DESCRIPTION_CONTEXT_URL}`,
            updateEmployerDescriptionType)
    return response.data
}

export const getDataPrivacyByCampaignId = async(campaignId: number): Promise<DataPrivacyResponseType> => {
    const response = await unovisionClient
        .get<DataPrivacyResponseType>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}${DATA_PRIVACY_CONTEXT_URL}`)
    return response.data
}

export const getPublicDataPrivacy = async(dataPrivacyId: string): Promise<DataPrivacyResponseType> => {
    const response = await unovisionClient
        .get<DataPrivacyResponseType>(`${PUBLIC_DATA_PRIVACY_CONTEXT_URL}/${dataPrivacyId}`)
    return response.data
}

export const updateDataPrivacy = async(campaignId: number,
                                       updatedDataPrivacy: DataPrivacyDTOType): Promise<DataPrivacyResponseType> => {
    const response = await unovisionClient
        .put<DataPrivacyResponseType>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}${DATA_PRIVACY_CONTEXT_URL}`,
            updatedDataPrivacy)
    return response.data
}

export const createDataPrivacy = async(campaignId: number,
                                       dataPrivacy: CreateDataPrivacyType): Promise<DataPrivacyResponseType> => {
    const response = await unovisionClient
        .post<DataPrivacyResponseType>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}${DATA_PRIVACY_CONTEXT_URL}`,
            dataPrivacy)
    return response.data
}

export type GeoTargetResponseType = {
    geoTargets: GeoTargetType[]
}

export type GeoTargetType = {
    index: number
    givenName: string
    addressString: string
    gpsLocation: GpsLocationType
}

export type GpsLocationType = {
    latitude: number
    longitude: number
}

export const getGeoTargets = async(campaignId: number): Promise<GeoTargetResponseType> => {
    const response = await unovisionClient
        .get<GeoTargetResponseType>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}${GEO_TARGET_CONTEXT_URL}`)
    return response.data
}

export type AddGeoTargetType = {
    givenName?: string
    addressString: string
    gpsLocation: GpsLocationType
}

export const addGeoTarget = async(campaignId: number, addGeoTarget: AddGeoTargetType): Promise<void> => {
    const response = await unovisionClient.post<void>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}${GEO_TARGET_CONTEXT_URL}`, addGeoTarget)
    return response.data
}

export const removeGeoTarget = async(campaignId: number, index: number): Promise<void> => {
    const response = await unovisionClient.delete<void>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}${GEO_TARGET_CONTEXT_URL}/${index}`)
    return response.data
}

export type AddMainAddressType = {
    addressId: number
}

export const addMainAddressToCampaign = async(campaignId: number, addMainAddress: AddMainAddressType): Promise<void> => {
    const response = await unovisionClient.post<void>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}${CAMPAIGN_MAIN_ADDRESS}`, addMainAddress)
    return response.data
}

export const removeMainAddressFromCampaign = async(campaignId: number, addressId: number): Promise<void> => {
    const response = await unovisionClient.delete<void>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}${CAMPAIGN_MAIN_ADDRESS}/${addressId}`)
    return response.data
}

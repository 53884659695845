import { Box, Button } from 'grommet'
import { FormNext, FormPrevious } from 'grommet-icons'
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { PageResult } from '../../api/PageResult'

export interface Paging {
    page: number;
    totalPages: number;
}

export interface PaginatorProps {
    paging: Paging;
    onNextPage: () => void;
    onPrevPage: () => void;
}

const PrevNextButton = styled(Button)`
    line-height: 11px;
`

export const SimplePaginator = ({paging, onNextPage, onPrevPage}: PaginatorProps) => (
    <Box direction={'row'} flex={false} pad={{bottom: '40px', top: '40px'}}>
        <PrevNextButton label={<FormPrevious/>} disabled={paging.page === 0} secondary onClick={onPrevPage}/>
        <Box pad={{top: '8px', left: '20px', right: '20px'}}>{`${paging.page + 1} / ${paging.totalPages || 1}`}</Box>
        <PrevNextButton label={<FormNext/>} disabled={paging.page + 1 >= paging.totalPages} onClick={onNextPage}/>
    </Box>
)

export interface Checked {
    [key: string]: boolean;
}

export type CheckedActionDispatcher = Dispatch<SetStateAction<Checked>>

export function useChecked():
    [Checked, CheckedActionDispatcher, (id: string) => void] {
    const [checked, setChecked] = useState<Checked>({})
    const handleCheck = useCallback((id: string) => {
        if(checked[id]) {
            delete checked[id]
        } else {
            checked[id] = true
        }
        setChecked({
            ...checked,
        })
    }, [checked, setChecked])

    return [checked, setChecked, handleCheck]
}

export function useAllChecked<T>(
    pageResult: PageResult<T> | null,
    checked: Checked,
    setChecked: CheckedActionDispatcher,
    keyMapper: (item: T) => string):
    [boolean, boolean, () => void] {
    const allChecked = useMemo(() =>
        (pageResult && pageResult.items && pageResult.items.map(item => checked[keyMapper(item)])
            .reduce((previousValue, currentValue) => previousValue && currentValue))
        || false,
        [checked, pageResult, keyMapper])

    const handleCheckAll = useCallback(() => {
        if(allChecked) {
            setChecked({})
        } else if(pageResult != null) {
            const newChecked: Checked = {}
            pageResult.items && pageResult.items.map(item => keyMapper(item)).forEach(id => {
                newChecked[id] = true
            })
            setChecked(newChecked)
        }
    }, [allChecked, setChecked, pageResult, keyMapper])

    const hasChecked = useMemo(() => Object.keys(checked).length > 0, [checked])

    return [hasChecked, allChecked, handleCheckAll]
}

export function usePageParam(): number {
    let {page} = useParams<{ page: string }>()
    if(!page) {
        return 0
    } else {
        return parseInt(page)
    }
}

import { Box, Text } from 'grommet'
import { AdModel } from '../../../../models/AdModel'
import { Avatar } from '../../../../components/media/Avatar'
import React from 'react'
import { useCurrentCampaign } from '../../../../hooks/useCurrentCampaign'
import { ThumbsUp } from 'phosphor-react'
import { VideoPlayer } from '../../../../components/media/VideoPlayer'

export const AdDetailFacebookPreview = ({currentAd}: { currentAd: AdModel }) => {
    const currentCampaign = useCurrentCampaign()
    return currentCampaign ?
        <Box direction={'column'}
             border={{color: 'border', size: 'xsmall'}}
             round='small'
             width={'500px'}
             height={{min: 'auto'}}>

            <Box pad={'small'} gap={'small'}>
                <Box direction={'row'} align={'center'} gap={'small'}>
                    <Avatar size='small'
                            src={currentCampaign.avatarUrl}
                    />
                    <Box direction={'column'}>
                        <Text size={'large'} color={'brand'} weight={'bold'}>{currentAd.title}</Text>
                        <Text>{new Date().toLocaleString('de')}</Text>
                    </Box>
                </Box>

                <Text>{currentAd.textHeader}</Text>
                <ul>
                    {currentAd.selectedJobBenefits?.map(benefit => {
                        return <li key={benefit.id}><Text>{benefit.text}</Text></li>
                    })}
                </ul>
                <Text>{currentAd.textFooter}</Text>
            </Box>
            <VideoPlayer src={currentAd.video?.url} poster={currentAd.image?.url}/>
            <Box height={'xsmall'} pad={'small'} border={'bottom'} background={'light-1'}>
                <Text size={'large'}
                      weight={'bold'}>{currentAd.title}</Text>
            </Box>
            <Box direction={'row'} justify={'between'} pad={{horizontal: 'medium', vertical: 'xsmall'}}>
                <Box direction={'row'} gap={'small'} align={'center'}>
                    <ThumbsUp/><Text>0 likes</Text>
                </Box>
                <Box direction={'row'} gap={'medium'} align={'center'}>
                    <Text>0 comments</Text><Text>0 share</Text><Text>0 views</Text>
                </Box>
            </Box>
        </Box> : null
}

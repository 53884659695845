export type JobApplicationStepType = 'APPLICANT_NEW_APPLICATION' |
    'APPLICANT_SENT_A_MESSAGE' |
    'CLIENT_SENT_A_MESSAGE' |
    'CLIENT_VISITED_APPLICATION' |
    'CLIENT_CHANGED_FAVORITE' |
    'CLIENT_HIRED_APPLICATION' |
    'CLIENT_CLOSED_APPLICATION' |
    'CLIENT_APPLICATION_REJECTED_SENT' |
    'CLIENT_APPLICATION_REJECTED_RECEIVED' |
    'CLIENT_WAITING_FOR_APPLICANT_TO_RESPOND'

type JobApplicationStepDetailsType = {
    [key in JobApplicationStepType]: JobApplicationStepDetailObjectType
}

type JobApplicationStepDetailObjectType = {
    label: string
    isFromApplicant: boolean
    showInRecordedStepsContainer: boolean
}

// TODO Just show necessary steps (showInRecordedStepsContainer) - Everything is turned on for testing purpose
// Step for keep applicant open for another 3 days
export const JOB_APPLICATION_STEP_DETAILS: JobApplicationStepDetailsType = {
    'APPLICANT_NEW_APPLICATION': {
        label: 'Bewerbung eingetroffen ',
        isFromApplicant: true,
        showInRecordedStepsContainer: true
    },
    'APPLICANT_SENT_A_MESSAGE': {
        label: 'Neue Nachricht',
        isFromApplicant: true,
        showInRecordedStepsContainer: true
    },
    'CLIENT_SENT_A_MESSAGE': {
        label: 'Nachricht gesendet',
        isFromApplicant: false,
        showInRecordedStepsContainer: true
    },
    'CLIENT_VISITED_APPLICATION': {
        label: 'Profil gesichtet',
        isFromApplicant: false,
        showInRecordedStepsContainer: true
    },
    'CLIENT_CHANGED_FAVORITE': {
        label: 'Favoritenstatus bearbeitet',
        isFromApplicant: false,
        showInRecordedStepsContainer: true
    },
    'CLIENT_HIRED_APPLICATION': {
        label: 'Bewerber als eingestellt markiert',
        isFromApplicant: false,
        showInRecordedStepsContainer: true
    },
    'CLIENT_CLOSED_APPLICATION': {
        label: 'Konversation wurde geschlossen',
        isFromApplicant: false,
        showInRecordedStepsContainer: true
    },
    'CLIENT_APPLICATION_REJECTED_SENT': {
        label: 'Bewerber wurde abgelehnt (Gesendet)',
        isFromApplicant: false,
        showInRecordedStepsContainer: true
    },
    'CLIENT_APPLICATION_REJECTED_RECEIVED': {
        label: 'Bewerber wurde abgelehnt (Empfangen)',
        isFromApplicant: false,
        showInRecordedStepsContainer: true
    },
    'CLIENT_WAITING_FOR_APPLICANT_TO_RESPOND': {
        label: 'Warte auf Reaktion...',
        isFromApplicant: false,
        showInRecordedStepsContainer: true
    },
}
import { CheckBoxProps } from 'grommet'

const isObject = (item: any) => (
    item && typeof item === 'object' && !Array.isArray(item))

const deepFreeze = (obj: any) => {
    Object.keys(obj).forEach(
        key => key && isObject(obj[key]) && Object.freeze(obj[key]),
    )
    return Object.freeze(obj)
}

const accentColors = ['#23A6f0', '#614767', '#810E21', '#FF8D35']
const neutralColors = ['#EBF2EA', '#F0E8E7', '#80746E', '#767676']
const darkColors = ['#333333', '#555555', '#777777', '#999999', '#999999', '#999999']
const lightColors = ['#F8F8F8', '#F2F2F2', '#EDEDED', '#DADADA', '#DADADA', '#DADADA']
const statusColors: any = {
    critical: '#b70014',
    error: '#FA0606',
    'error-background': '#FFE7E7',
    warning: '#FFD144',
    ok: '#27AE60',
    'ok-background': 'rgba(111, 207, 151, 0.2)',
    unknown: '#CCCCCC',
    disabled: '#CCCCCC',
}

const colors: any = {
    brand: {
        light: '#196394',
        dark: '#196394'
    },
    'background': {
        'dark': '#111111',
        'light': '#FFFFFF'
    },
    'background-back': {
        'dark': '#111111',
        'light': '#EEEEEE'
    },
    'background-selected': {
        'dark': '#ECF6F9',
        'light': '#ECF6F9',
    },
    'border': {
        'dark': '#444444',
        'light': '#CCCCCC'
    },
    'text': {
        'dark': '#FFFFFF',
        'light': '#333333'
    },
    'h1': '#23a6f0',
    'h2': '#737373',
    'h3': '#333333',
    'h4': '#4F4F4F',
    'h5': '#868686',
    'unovision-light-blue': '#D3EDFC',
    'orange': '#EB801E',
    'highlight': '#D64646',
    'background-light-blue': '#F0F6F8',
    'background-chatbot-preview-box': '#EEF2F4',
    'background-ad-detail-title': '#F5F5F5',
    'background-highlight-box': '#F1F6F8',
    'background-message-box': '#E0E0E0',
    'background-premium-ad': 'rgba(255, 141, 53, 0.2)',
    'background-selected-job-box': '#D9E9ED',
    'background-editable': '#F9EDBB',
    'border-gold': '#F2C94C',
    'border-premium-ad': 'rgba(255, 205, 136, 0.2)',
    'border-table': '#E7EBF0',
    'border-text-area': '#DBEAED',
    'text-light-gray': '#BDBDBD',
    'text-dark-gray': '#616161',
    'text-info': '#737373',
    'text-white': '#FFFFFF',
    'video-background': '#BFC6CA',
    'video-alternative-controls': '#F9F9F9',
    'gradient-1': 'linear-gradient(to bottom, #F6F6F6, #F8F8F8, #F9F9F9, #DDDDDD)',
    'gradient-2': 'linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%), #23A6F0',
    'gradient-3': 'linear-gradient(180deg, #FFAF65 0%, #FEAD03 41.15%, #B55700 100%)',
    focus: accentColors[0],
}

const colorArray = (array: Array<any>, prefix: string) => (
    array.forEach((color: string, index: number) => {
        colors[`${prefix}-${index + 1}`] = color
    }))

colorArray(lightColors, 'light')
colorArray(darkColors, 'dark')
colorArray(accentColors, 'accent')
colorArray(neutralColors, 'neutral')
Object.keys(statusColors).forEach((color) => {
    colors[`status-${color}`] = statusColors[color]
})

export const unovisionTheme = deepFreeze({
    global: {
        colors,
        font: {
            family: 'Roboto, sans-serif;',
            size: '12px',
        },
        control:{
            border: {
                radius: '8px',
            },
        },
        input: {
            weight: 'normal'
        }
    },
    formField: {
        label: {
            size: 'small',
            weight: 'normal',
            margin: {
                horizontal: 'none'
            },
            requiredIndicator: true
        },
        border: {
            position: 'inner',
            side: 'all',
            radius: 8
        },
        round: '8px',
        error: {
            size: 'xsmall',
            margin: {
                horizontal: 'none'
            },
        },
        help: {
            size: 'xsmall',
            margin: {
                horizontal: 'none'
            },
        },
        info: {
            size: 'xsmall',
            margin: {
                horizontal: 'none'
            },
        },
    },
    textInput: {
        extend: `background-color: white;`
    },
    anchor: {
        textDecoration: 'none',
        hover: {
            textDecoration: 'none',
        },
        color: {
            dark: '#FFFFFF',
            light: '#4F4F4F',
        },
    },
    text: {
        xsmall: {
            size: '10px'
        },
        small: {
            size: '12px',
            height: '14px'
        },
        medium: {
            size: '14px'
        },
        large: {
            size: '16px'
        },
        xlarge: {
            size: '20px'
        },
        xxlarge: {
            size: '24px'
        }
    },
    button: {
        extend: `
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -html-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-tap-highlight-color: transparent;
        `,
    },
    avatar: {
        size: {
            small: '36px',
            medium: '58px'
        }
    },
    tabs: {
        header: {
            border: {
                side: 'bottom',
                size: 'small',
                color: 'light-6'
            },
            extend: `
                display: flex;
                justify-content: start;
                align-items: flex-end;
                flex-wrap: nowrap;
                width: 100%;
            `
        }
    },
    tab: {
        margin: {
            'top': 'small',
            'bottom': 'none',
            'left': 'none'
        },
        border: {
            color: 'transparent',
            size: 'medium',
            active: {
                color: 'brand'
            }
        },
    }
})

import React from 'react'
import { AddressModel } from '../../../../models/AddressModel'
import { Modal, ModalContent } from '../../../../components/layout/Modal'
import { Box } from 'grommet'
import { AdDetailSubsidiaryEdit } from '../../../ads/adDetail/content/AdDetailSubsidiaryEdit'

type Props = {
    subsidiary?: AddressModel
    onSubmit: (address: AddressModel) => void
    onDelete?: () => void
    onClose: () => void
}
export const SubsidiaryEditModal: React.FC<Props> = (props: Props) => {
    return (
        <Modal
            onEsc={props.onClose}
            onClickClose={props.onClose}
            onClickOutside={props.onClose}
        >
            <ModalContent>
                <Box pad={"medium"}>
                    <AdDetailSubsidiaryEdit
                        subsidiary={props.subsidiary}
                        onSubmit={props.onSubmit}
                        onDelete={props.onDelete}
                    />
                </Box>
            </ModalContent>
        </Modal>
    )
}
import {unovisionClient} from './unovisionClient'
import {AdType} from './ads'
import {AddressModel} from "../models/AddressModel";

export const CAMPAIGN_CONTEXT_URL = '/api/campaign'

export type CampaignType = {
    id: number;
    name: string;
    description?: string;
    avatarUrl?: string;
    chatbotId?: string;
}
type AdsResponseType = {
    advertisements: AdType[]
}

export type CampaignResponseType = {
    campaigns: CampaignType[]
}

export type CreateCampaign = {
    campaignName: string;
    campaignDescription?: string;
    campaignAvatarUrl?: string;
}

export type ChatbotPlatformUrlResponse = {
    platformUrl: string
}

type ChatbotType = {
    id: string;
}

export const getAllCampaigns = async(): Promise<CampaignResponseType> => {
    const response = await unovisionClient.get<CampaignResponseType>(CAMPAIGN_CONTEXT_URL)
    return response.data
}

export const getCampaignMainAddress = async(campaignId: number): Promise<AddressModel[]> => {
    const response = await unovisionClient.get<AddressModel[]>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}/main-address`)
    return response.data
}

export const getAdsByCampaignId = async(campaignId: number): Promise<AdsResponseType> => {
    const response = await unovisionClient.get<AdsResponseType>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}/advertisement`)
    return response.data
}

export const createCampaign = async(createCampaign: CreateCampaign): Promise<CampaignType> => {
    const response = await unovisionClient.post<CampaignType>(CAMPAIGN_CONTEXT_URL, createCampaign)
    return response.data
}

export const deleteCampaign = async(campaignId: number): Promise<void> => {
    const response = await unovisionClient.delete(`${CAMPAIGN_CONTEXT_URL}/${campaignId}`)
    return response.data
}

export const createChatbot = async(campaignId: number): Promise<ChatbotType> => {
    const response = await unovisionClient.post(`${CAMPAIGN_CONTEXT_URL}/${campaignId}/chatbot`)
    return response.data
}

export const getAllCampaignsAsSuperuser = async(): Promise<CampaignType[]> => {
    const response = await unovisionClient.get(`${CAMPAIGN_CONTEXT_URL}/campaign-superuser`)
    return response.data
}

export const getChatbotPlatformUrl = async(campaignId: number): Promise<ChatbotPlatformUrlResponse> => {
    const response = await unovisionClient.get(`${CAMPAIGN_CONTEXT_URL}/${campaignId}/chatbot-platform-url`)
    return response.data
}

export const getCampaignSubsidiaries = async(campaignId: number, activeOnly?: boolean): Promise<AddressModel[]> => {
    let url = `${CAMPAIGN_CONTEXT_URL}/${campaignId}/subsidiary`;
    if(activeOnly != undefined) {
        url += "?activeOnly="+activeOnly
    }
    const response = await unovisionClient.get<AddressModel[]>(url)
    return response.data
}

export const createCampaignSubsidiary = async(campaignId: number, subsidiary: AddressModel): Promise<AddressModel> => {
    const response = await unovisionClient.post<AddressModel>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}/subsidiary`, {create: subsidiary})
    return response.data
}


import React, { useCallback, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Box, Button, Form, FormField } from 'grommet'
import { authenticate } from '../../../api/common'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { ControlButtons } from '../../../components/buttons/ControlButtons'
import { LoadingOverlay } from '../../../components/layout/LoadingOverlay'
import { BasePage } from '../../../components/layout/BasePage'

export interface LoginFormType {
    username: string;
    password: string;
}

export const Login: React.FC = () => {
    const history = useHistory()
    const [, setCurrentUser] = useCurrentUser()
    const [value, setValue] = useState<LoginFormType>({username: '', password: ''})
    const [loading, setLoading] = useState(false)

    const handleSubmit = useCallback(async({value}: { value: LoginFormType }) => {
        try {
            await authenticateUser(value)
        } catch(e) {
            setLoading(false)
            setTimeout(() => alert('Ein Login Fehler ist aufgetreten!'), 0)
        }
    }, [setLoading, setCurrentUser, history])

    const authenticateUser = async(value: LoginFormType): Promise<void> => {
        setLoading(true)
        const user = await authenticate(value.username, value.password)

        setCurrentUser(user)
        setLoading(false)
        history.push(`/select-client`)
    }

    return (
        <BasePage hideBackButton title='Login'>
            <Box justify='center' align='center'>
                <Box
                    pad='medium'
                    direction='column'
                    width='medium'
                    background='light-2'
                    round='small'
                    border={{color: 'light-3', size: 'small'}}
                >
                    <Form<LoginFormType>
                        value={value}
                        onChange={setValue}
                        onSubmit={handleSubmit}>

                        <h1>Login</h1>

                        <FormField
                            name='username'
                            label='Benutzername'
                            required
                        />

                        <FormField
                            name='password'
                            label='Passwort'
                            type='password'
                            required
                        />

                        <ControlButtons>
                            <Box pad='xsmall'>
                                <Link to='recovery'>
                                    Passwort vergessen?
                                </Link>
                            </Box>

                            <Button
                                primary
                                type='submit'
                                label='Login'
                            />
                        </ControlButtons>
                    </Form>

                    <LoadingOverlay
                        visible={loading}
                    />
                </Box>
            </Box>
        </BasePage>
    )
}

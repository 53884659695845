import React from 'react'
import { AdModel } from '../../../models/AdModel'
import { BannerCol } from '../../../components/layout/BannerCol'
import { BannerContainer } from '../../../components/layout/BannerContainer'
import { BaseButton } from '../../../components/buttons/BaseButton'
import { Box, ResponsiveContext, Text } from 'grommet'
import { CheckListItem } from '../../../components/text/CheckListItem'
import { ImageOrPlaceholder } from '../../../components/media/ImageOrPlaceholder'
import { useAdDetailContext } from './AdDetailContext'

type AdDetailBannerProps = {
    currentAd: AdModel
}

export const AdDetailBanner: React.FC<AdDetailBannerProps> = ({currentAd}: AdDetailBannerProps) => {
    const {updateCurrentAdStatus} = useAdDetailContext()

    return <ResponsiveContext.Consumer>
        {screenSize => {
            const isSmall = screenSize === 'small'
            const isLarge = screenSize === 'large'
            return <BannerContainer title={currentAd.title}>
                <Box direction='row'>
                    <Box align='center'
                         overflow='hidden'
                         width='small'
                         justify='center'
                         alignContent='center'
                    >
                        <ImageOrPlaceholder src={currentAd.image?.url}/>
                    </Box>
                    <BannerCol displaySeparator
                               direction={'row'}
                               align={'center'}
                               gap={'medium'}
                               pad={isSmall ? 'none' : {horizontal: 'medium', vertical: 'small'}}
                               margin={isSmall ? 'none' : {vertical: 'medium'}}
                               flex={isLarge ? 'grow' : undefined}>
                        <Box direction={'row'} align={'center'} wrap pad={'medium'}>
                            <Text color={currentAd.active ? 'status-ok' : 'status-error'}>
                                {currentAd.active ? 'Online' : 'Offline'}
                            </Text>
                            <Box background={currentAd.active ? 'status-ok' : 'status-error'}
                                 pad='xsmall'
                                 round='xsmall'
                                 margin='medium'
                            />
                            <BaseButton
                                label={<Text
                                    color={'brand'}>{currentAd.active ? 'Offline nehmen' : 'Online schalten'}</Text>}
                                onClick={() => {
                                    updateCurrentAdStatus(!currentAd.active)
                                }}/>
                        </Box>
                    </BannerCol>
                </Box>
                <Box direction={'row'}>
                    <BannerCol displaySeparator
                               pad={isSmall ? {horizontal: 'medium', bottom: 'medium'} : undefined}>
                        <Text size='large' weight='bold' margin={{bottom: 'small'}}>Anzeigeninhalt für</Text>
                        {currentAd.jobs.length > 0 ? currentAd.jobs.map(job => <CheckListItem key={job.id}
                                                                                              isChecked={true}
                                                                                              label={job.name}/>
                        ) : <CheckListItem
                            label='Kein Job zugeordnet'
                            isChecked={false}/>}
                    </BannerCol>
                    <BannerCol displaySeparator={isSmall}
                               pad={isSmall ? {horizontal: 'medium', bottom: 'medium'} : undefined}>
                        <Text size='large' weight='bold' margin={{bottom: 'small'}}>Inhalte</Text>
                        <CheckListItem label={'Anzeigentext'}
                                       isChecked={currentAd.textFooter !== undefined && currentAd.textHeader !== undefined && currentAd.selectedJobBenefits.length > 0}/>
                        <CheckListItem label={'Anzeigenbild'} isChecked={currentAd.image !== undefined}/>
                        <CheckListItem label={'Anzeigenvideo'} isChecked={currentAd.video !== undefined}/>
                    </BannerCol>
                    {isLarge && <BannerCol width={'small'}/>}
                </Box>
            </BannerContainer>
        }
        }
    </ResponsiveContext.Consumer>
}

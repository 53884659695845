import React, { MutableRefObject } from 'react'
import { Layer, Spinner, SpinnerExtendedProps } from 'grommet'
import { unovisionTheme } from '../../theme/theme'

export type LoadingOverlayProps = SpinnerExtendedProps & {
    targetRef?: MutableRefObject<any>,
    visible?: boolean,
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = (props: LoadingOverlayProps) => {
    const {targetRef, visible, color = unovisionTheme.global.colors.brand, size = 'large'} = props
    if(!visible) return null

    return (
        <Layer plain target={targetRef?.current}>
            <Spinner color={color}
                     size={size}/>
        </Layer>
    )
}

LoadingOverlay.defaultProps = {
    visible: true
}

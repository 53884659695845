import React, {useEffect} from 'react'
import {Box, Heading, ResponsiveContext,} from 'grommet'
import {ContentLayout} from '../../components/layout/ContentLayout'
import {useCurrentCampaign} from '../../hooks/useCurrentCampaign'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {JobApplicationListContainer} from '../jobApplications/JobApplicationListContainer'
import {useMessages, useUpdateMessages} from "../../hooks/useMessages";

export const MessagesContent = () => {

    const history = useHistory()
    const {url} = useRouteMatch()
    const messages = useMessages()

    const handleVisitMessageDetails = (messageId: string) => {
        history.push(`${url}/${messageId}`)
    }

    return (
        <ResponsiveContext.Consumer>
            {screenSize => {
                const isScreenSizeSmall = screenSize === 'small'
                return <>
                    <ContentLayout>
                        <Box direction={"column"} width={"100%"}>
                            {!isScreenSizeSmall &&
                                <Heading level='3' color='brand'
                                         margin={{top: 'none', bottom: 'medium'}}>Nachrichten</Heading>}
                            <Box fill gap='medium'>
                                <JobApplicationListContainer
                                    organizedJobApplications={messages}
                                    handleVisitApplicantDetail={handleVisitMessageDetails}
                                />
                            </Box>
                        </Box>
                    </ContentLayout>
                </>
            }}
        </ResponsiveContext.Consumer>
    )
}

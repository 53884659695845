import React from 'react'
import { BasePage } from '../../../components/layout/BasePage'
import { Subscribe } from './Subscribe'
import { Image } from 'grommet'
import styled from 'styled-components'
import SplashBgSrc from '../../../resources/images/splashBackround.svg'
import VkadLogoSrc from '../../../resources/images/vkad_logo.jpg'

export const SubscribePage: React.FC = () => {
    return (
        <BasePage>
            <StyledBackground src={SplashBgSrc}/>
            <StyledVkadLogo src={VkadLogoSrc}/>
            <Subscribe/>
        </BasePage>
    )
}

const StyledBackground = styled(Image)`
    position: absolute;
    top: 20vh;
    right: 0;
    min-width: 100vw;
    min-height: 100vh;
    opacity: 15%;
`

const StyledVkadLogo = styled(Image)`
    width: 100px;
    padding: 1em 3em;
    align-self: flex-end;
`
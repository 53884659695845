import { useHistory } from 'react-router-dom'
import { Box, Button } from 'grommet'
import { ArrowRight, CaretLeft } from 'phosphor-react'
import React from 'react'
import { Link } from 'react-router-dom'
import { RouteType } from '../router/Router'

type BreadCrumbsProps = {
    hideBackButton?: boolean
    crumbs?: RouteType[]
}

export const BreadCrumbs = ({hideBackButton, crumbs}: BreadCrumbsProps) => {
    const history = useHistory()
    return <Box direction='row' pad='medium' height='xxsmall' gap='small' align='center'>
        {!hideBackButton &&
        <Button plain label='Zurück' icon={<CaretLeft size={18}/>} onClick={() => history.goBack()}/>}
        <Box direction='row' gap='small'>
            {crumbs?.map(({name, path}, key) =>
                key + 1 === crumbs.length ?
                    <span key={key}>{name}</span>
                    :
                    <Link key={key} to={path}>
                        {name} <ArrowRight size={10}/>
                    </Link>
            )}
        </Box>
    </Box>
}
import React from 'react'
import { Avatar, Box, Heading, ResponsiveContext, Stack, Text } from 'grommet'
import { BoxProps } from 'grommet/es6'
import { formatTimestampToDateAndTime } from '../../helpers/formatTimestamp'
import { JobApplicationType } from '../../api/jobApplications'
import { JOB_APPLICATION_STATUS_DETAILS } from './JobApplicationStatus'
import { StatusBadge } from '../../components/badges/StatusBadge'
import { Star } from 'grommet-icons'
import { unovisionTheme } from '../../theme/theme'
import { User } from 'phosphor-react'

type JobApplicationListItemContainerProps = BoxProps & {
    jobApplication: JobApplicationType,
}

export const JobApplicationListItemContainer = ({jobApplication, ...rest}: JobApplicationListItemContainerProps) => {

    const {
        applicant,
        lastApplicantActivityTimestamp,
        applicationTimestamp,
        applicationStatus,
        isFavorite,
        job
    } = jobApplication

    return (
        <ResponsiveContext.Consumer>
            {screenSize =>
                <Stack anchor='top-left'>
                    <Stack anchor='top-right'>
                        <Box
                            {...rest}
                            direction='row'
                            elevation='small'
                            round='small'
                            pad={screenSize === 'small' ?
                                'large' :
                                {
                                    bottom: 'small',
                                    top: 'medium',
                                    horizontal: 'medium'
                                }}
                            border={{size: 'xsmall', color: 'light-5'}}
                        >
                            <Box width={{max: 'xxsmall'}} height={{max: 'xxsmall'}}>
                                <Avatar
                                    border={{color: 'brand', size: 'xsmall'}}
                                    src={applicant.avatarUrl}
                                    background='brand'
                                    size={'large'}
                                >
                                    {applicant.avatarUrl ??
                                    <User size={'24'} color={unovisionTheme.global.colors['accent-1']}/>}
                                </Avatar>
                            </Box>
                            <Box
                                wrap
                                direction='row'
                                justify='between'
                                width='100%'
                            >
                                <Box
                                    direction='row'
                                    wrap
                                    justify='between'
                                    align='center'
                                    pad={{vertical: 'xsmall', horizontal: 'small'}}
                                >
                                    <Box width='small'>
                                        <Heading
                                            level='4'
                                            margin={{vertical: 'none'}}
                                            color='brand'
                                        >
                                            {`${applicant.firstName} ${applicant.lastName}`}</Heading>
                                    </Box>
                                    <Box>
                                        <Text margin={{vertical: 'none'}}>{job?.name}</Text>
                                    </Box>
                                </Box>
                                <Box
                                    direction='row'
                                    wrap
                                    justify='between'
                                    align='center'
                                >
                                    <JobApplicationListItemDate
                                        label='Letzte Aktivität am'
                                        date={formatTimestampToDateAndTime(lastApplicantActivityTimestamp)}/>
                                    <JobApplicationListItemDate
                                        label='Beworben am'
                                        date={formatTimestampToDateAndTime(applicationTimestamp)}/>
                                </Box>
                            </Box>
                        </Box>
                        <Box pad='small'>
                            <Star color={isFavorite ? 'brand' : 'light-4'}/>
                        </Box>
                    </Stack>
                    <StatusBadge
                        translateY='-50%'
                        margin={{left: 'small'}}
                        label={JOB_APPLICATION_STATUS_DETAILS[applicationStatus].label}
                        background={JOB_APPLICATION_STATUS_DETAILS[applicationStatus].background}/>
                </Stack>
            }
        </ResponsiveContext.Consumer>
    )
}

type JobApplicationListItemDateProps = {
    label: string
    date: string
}

const JobApplicationListItemDate = ({label, date}: JobApplicationListItemDateProps) =>
    <Box width='small' pad={{vertical: 'xsmall', horizontal: 'small'}}>
        <Text size='small' color='dark-6'>{label}</Text>
        <Text size='small'
              color='dark-6'
        >{date}</Text>
    </Box>

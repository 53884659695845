import { Box } from 'grommet'
import React, { ReactElement, ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const ItemOuter = styled(Box)`
  user-select: none;
  cursor: pointer;
  color: #373737;

  svg {
    fill: #373737;
    stroke: #373737;
  }

  .active & {
    background-color: ${props => props.theme.global.colors.brand.light};
    color: white;

    svg {
      fill: white;
      stroke: white;
    }
  }
`

export type ItemProps = {
    to: string;
    children: ReactNode;
    exact?: boolean;
    icon?: ReactElement;
}

const StyledLink = styled(NavLink)`
  text-decoration: none;
`

const Item = ({icon, to, children, exact}: ItemProps) => (
    <StyledLink to={to} activeClassName={'active'} exact={exact}>
        <ItemOuter pad={'small'} direction={'row'}>
            {icon ? (
                    <>
                        <Box margin={{top: '3px'}}>{React.cloneElement(icon, {
                            color: 'black',
                            size: '18px',
                        })}</Box>
                        <Box margin={{left: '10px'}}>{children}</Box>
                    </>
                ) :
                <Box margin={{left: '30px'}}>{children}</Box>}
        </ItemOuter>
    </StyledLink>
)

export type NavigationItemClickableProps = {
    onClick: () => void;
    children: ReactNode;
    icon?: ReactElement;
}

export const NavigationItemClickable = ({icon, onClick, children}: NavigationItemClickableProps) => (
    <div onClick={onClick}>
        <ItemOuter pad={'small'} direction={'row'}>
            {icon ? (
                    <>
                        {React.cloneElement(icon, {
                            color: 'white',
                            size: '20px',
                        })}<Box margin={{left: '10px'}}>{children}</Box>
                    </>
                ) :
                <Box margin={{left: '30px'}}>{children}</Box>}
        </ItemOuter>
    </div>
)

export const NavigationItem: (props: ItemProps & { exact?: boolean }) => JSX.Element = Item

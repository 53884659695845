import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Button, TextInput, TextInputProps } from 'grommet'
import { FormLock, View } from 'grommet-icons'

interface PasswordInputProps extends TextInputProps, Omit<JSX.IntrinsicElements['input'], 'onSelect' | 'size' | 'placeholder' | 'width'> {
    value?: string | number
}

const StyledButton = styled(Button)`
  padding: 8px;
`

export const PasswordInput = ({...rest}: PasswordInputProps) => {
    const [reveal, setReveal] = useState(false)

    const icon = reveal
        ? <FormLock size='medium'/>
        : <View size='medium'/>

    return (
        <Box
            width='100%'
            direction='row'
            align='center'
            round='small'>

            <TextInput
                plain
                type={reveal ? 'text' : 'password'}
                {...rest}
            />

            <StyledButton
                tip='Show/Hide password'
                icon={icon}
                onClick={() => setReveal(!reveal)}
            />
        </Box>
    )
}

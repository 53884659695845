import React, {useEffect, useState} from 'react'
import {ContactModel} from '../../../../models/ContactModel'
import {AddressModel} from '../../../../models/AddressModel'
import {JobModel} from '../../../../models/JobModel'
import {SelectedDataPrivacyLawType} from '../DataPrivacyContent'
import axios from 'axios'
import {
    CampaignMainAddressMarkdownComponent,
    ContainerMarkdownComponent,
    DataPrivacyOfficerListMarkdownComponent,
    DataPrivacyOfficerModalMarkdownComponent,
    DataProcessingDescriptionMarkdownComponent,
    EditableContentContainer,
    HeadingMarkdownComponent,
    JobEnumMarkdownComponent,
    JobListMarkdownComponent,
    ParagraphMarkdownComponent,
    SubsidiaryEnumMarkdownComponent,
    SubsidiaryListMarkdownComponent,
    TenantAddressMarkdownComponent,
    TenantNameMarkdownComponent,
    UpdatedDateMarkdownComponent,
} from './MarkdownComponents'
import {Markdown} from 'grommet'

const DATAPRIVACY_MARKDOWN_PATHS = {
    DSGVO_AV: '/static/docu/data-privacy/DSGVO_AV.md',
    KDG_AV: '/static/docu/data-privacy/KDG_AV.md',
    DSG_EKD_AV: '/static/docu/data-privacy/DSG_EKD_AV.md',
    KDG_GV: '/static/docu/data-privacy/KDG_GV.md',
}

type DataPrivacyMarkdownProps = {
    selectedDataPrivacyLaw: SelectedDataPrivacyLawType,
    dataPrivacyOfficers?: ContactModel[],
    campaignMainAddresses?: AddressModel[],
    subsidiaries?: AddressModel[],
    jobList?: JobModel[],
    dataProcessingDescription?: string,
    updatedDate?: Date,
    toggleOfficerModal?: () => void,
    toggleDataProcessingDescriptionModal?: () => void,
    toggleMainAddressModal?: () => void,
}

export const DataPrivacyMarkdown: React.FC<DataPrivacyMarkdownProps> = (props: DataPrivacyMarkdownProps) => {
    const {selectedDataPrivacyLaw, subsidiaries, dataPrivacyOfficers, jobList, updatedDate, campaignMainAddresses} = props
    const [dataPrivacyMarkdown, setDataPrivacyMarkdown] = useState('')

    useEffect(() => {
        switch(selectedDataPrivacyLaw) {
            case 'DSGVO_AV':
                axios.get(DATAPRIVACY_MARKDOWN_PATHS.DSGVO_AV).then(res => setDataPrivacyMarkdown(res.data))
                break
            case 'KDG_AV':
                axios.get(DATAPRIVACY_MARKDOWN_PATHS.KDG_AV).then(res => setDataPrivacyMarkdown(res.data))
                break
            case 'DSG_EKD_AV':
                axios.get(DATAPRIVACY_MARKDOWN_PATHS.DSG_EKD_AV).then(res => setDataPrivacyMarkdown(res.data))
                break
            case 'KDG_GV':
                axios.get(DATAPRIVACY_MARKDOWN_PATHS.KDG_GV).then(res => setDataPrivacyMarkdown(res.data))
                break
            default:
                setDataPrivacyMarkdown('Error while loading Data Privacy')
        }
    }, [selectedDataPrivacyLaw])

    // Initializing dynamic Markdown Components
    const CampaignMainAddress = () => props.toggleMainAddressModal ?
        EditableContentContainer(
            props.toggleMainAddressModal,
            {children: CampaignMainAddressMarkdownComponent(campaignMainAddresses)},
        ) :
       CampaignMainAddressMarkdownComponent(campaignMainAddresses)
    const SubsidiaryList = () => SubsidiaryListMarkdownComponent(subsidiaries)
    const SubsidiaryEnum = () => SubsidiaryEnumMarkdownComponent(subsidiaries)
    const DataPrivacyOfficerList = () => DataPrivacyOfficerListMarkdownComponent(dataPrivacyOfficers)
    const JobList = () => JobListMarkdownComponent(jobList)
    const JobEnum = () => JobEnumMarkdownComponent(jobList)
    const UpdatedTimestamp = () => UpdatedDateMarkdownComponent(updatedDate)
    // If Modal functions are passed then initialize with Modal Containers
    const OfficerContainer = props.toggleOfficerModal ? () =>
            DataPrivacyOfficerModalMarkdownComponent(props.toggleOfficerModal!, dataPrivacyOfficers) :
        React.Fragment
    const SubsidiaryContainer = ContainerMarkdownComponent
    const DataProcessingDescription = () => props.toggleDataProcessingDescriptionModal ?
        EditableContentContainer(
            props.toggleDataProcessingDescriptionModal,
            {children: DataProcessingDescriptionMarkdownComponent(props.dataProcessingDescription)}
        ) :
        props.dataProcessingDescription ?
            DataProcessingDescriptionMarkdownComponent(props.dataProcessingDescription) :
            null

    return <Markdown components={{
        'p': ParagraphMarkdownComponent,
        'h4': HeadingMarkdownComponent,
        campaignmainaddress: CampaignMainAddress,
        tenantname: TenantNameMarkdownComponent,
        tenantaddress: TenantAddressMarkdownComponent,
        joblist: JobList,
        jobenum: JobEnum,
        container: ContainerMarkdownComponent,
        dataprivacyofficerlist: DataPrivacyOfficerList,
        dataprivacyofficercontainer: OfficerContainer,
        subsidiarylist: SubsidiaryList,
        subsidiaryenum: SubsidiaryEnum,
        subsidiarycontainer: SubsidiaryContainer,
        lastupdateddate: UpdatedTimestamp,
        dataprocessingdescription: DataProcessingDescription,
    }}>
        {dataPrivacyMarkdown}
    </Markdown>
}

import React, { PropsWithChildren, useState } from 'react'
import { Box, Button, Layer, ResponsiveContext, Text } from 'grommet'
import { ColorType } from 'grommet/utils'
import { IconContext, Pencil, X } from 'phosphor-react'
import styled from 'styled-components'
import { unovisionTheme } from '../../theme/theme'

export type ResponsiveSidebarProps = PropsWithChildren<{
    icon?: JSX.Element
    counter?: number
    label?: string
    background?: ColorType,
    closeOnSelect?: boolean
}>

export const ResponsiveSidebar: React.FC<ResponsiveSidebarProps> = ({
                                                                        closeOnSelect = false,
                                                                        ...props
                                                                    }: ResponsiveSidebarProps) => {

    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false)

    const toggleMobileSidebar = () => {
        setIsMobileSidebarOpen(!isMobileSidebarOpen)
    }

    return (
        <IconContext.Provider value={{
            size: 24,
            color: unovisionTheme.global.colors.brand.light
        }}>
            <ResponsiveContext.Consumer>
                {screenSize =>
                    screenSize === 'small' ?
                        <>
                            {isMobileSidebarOpen &&
                            <Layer
                                animation='fadeIn'
                                responsive={false}
                                onEsc={toggleMobileSidebar}
                            >
                                <CloseButton
                                    margin='small'
                                    plain
                                    icon={<X size={32}/>}
                                    onClick={toggleMobileSidebar}
                                />
                                <Box pad='large' gap='medium' onClick={closeOnSelect ? toggleMobileSidebar : undefined}>
                                    {props.children}
                                </Box>
                            </Layer>
                            }
                            <MobileSidebarToggle
                                background={props.background}
                                margin={{horizontal: 'medium', vertical: 'xlarge'}}
                                onClick={toggleMobileSidebar}
                            >
                                {props.icon ? props.icon : <Pencil color='white'/>}
                                <Text size='small' margin={{top: 'xxsmall'}}>
                                    {props.counter !== undefined && props.counter > 0 && props.counter} {props.label}</Text>
                            </MobileSidebarToggle>
                        </> :
                        <Box pad={{right: 'medium'}} width={{min: 'medium'}}>
                            <Box height={{min: 'auto'}} round='small' border={{color: 'border', size: 'xsmall'}}>
                                {props.children}
                            </Box>
                        </Box>
                }
            </ResponsiveContext.Consumer>
        </IconContext.Provider>
    )
}

const CloseButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
`

const MobileSidebarToggle = styled(Button)<ResponsiveSidebarProps>`
    position: fixed;
    bottom: 0;
    right: 0;
    min-width: 5em;
    max-width: 5em;
    min-height: 5em;
    max-height: 5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${(props) => props.background ? props.background : unovisionTheme.global.colors.brand.light};
    color: white;
    z-index: 99;
`

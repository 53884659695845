import React from 'react'
import {Box, Button, Heading, Paragraph, RadioButtonGroup, Stack, Text} from 'grommet'
import {normalizeColor} from 'grommet/utils'
import {unovisionTheme} from '../../../theme/theme'
import {Book, Compliance, FormEdit, StatusGood, Support} from 'grommet-icons'
import {ErrorBadge} from '../../../components/layout/ErrorBadge'
import {BaseButton} from '../../../components/buttons/BaseButton'
import {SelectedDataPrivacyLawType} from './DataPrivacyContent'

const lawTitle = 'Datenschutzgesetz wählen'
const lawDescription = 'Sie können sich aussuchen ob Sie die DSGVO oder die KDG Variante nutzen möchten.'

const finishTitle = 'Datenschutzinfo fertig stellen'
const finishDescription = 'Um eine gültige Datenschutzinfo zu erhalten, müssen Sie folgende Punkte alle befüllen. Wenn die Inhalte ausreichen, werden Sie automatisch abgehakt.'

const helpTitle = 'Sie benötigen Unterstützung?'
const helpDescription = 'Buchen Sie jetzt einen unserer Datenschutzprofis. Wir helfen Ihnen gerne Ihre Prozesse Datenschutzkonform zu machen.'

export type DataPrivacySideBarProps = {
    hasCampaignMainAddress: boolean,
    hasDataProcessingDescription?: boolean,
    hasDataPrivacyOfficer: boolean,
    hasDataChanged: boolean,
    selectedDataPrivacyLaw: SelectedDataPrivacyLawType,
    setDataPrivacyLaw: (selectedDataPrivacyLaw: SelectedDataPrivacyLawType) => void,
    openOfficerModal: () => void,
    openMainAddressModal: () => void,
    openDataProcessingDescriptionModal: () => void,
    onSave: () => void,
    saveButtonText?: string
}

function DataPrivacySideBar(props: DataPrivacySideBarProps) {

    const dataPrivacyOptions = [
        {
            disabled: true,
            id: "DSGVO_GV",
            label:  "DSGVO - Gemeinsame Verantwortlichkeit",
            value: "DSGVO_GV"
        },
        {
            disabled: false,
            id: "DSGVO_AV",
            label:  "DSGVO - Alleinige Verantwortlichkeit",
            value: "DSGVO_AV"
        },
        {
            disabled: false,
            id: "KDG_GV",
            label:  "KDG - Gemeinsame Verantwortlichkeit",
            value: "KDG_GV"
        },
        {
            disabled: false,
            id: "KDG_AV",
            label:  "KDG - Alleinige Verantwortlichkeit",
            value: "KDG_AV"
        },
        {
            disabled: true,
            id: "DSG_EKD_GV",
            label:  "DSG-EKD - Gemeinsame Verantwortlichkeit",
            value: "DSG_EKD_GV"
        },
        {
            disabled: false,
            id: "DSG_EKD_AV",
            label:  "DSG-EKD - Alleinige Verantwortlichkeit",
            value: "DSG_EKD_AV"
        }
    ]

    return (
        <Box width='medium' pad={{bottom: 'medium'}}>
            <Box gap='medium' pad='medium' round='small' border={{color: 'border', size: 'xsmall'}}>
                <Button primary disabled={!props.hasDataChanged} margin='xsmall' label={props.saveButtonText ?? 'Speichern'}
                        onClick={() => props.onSave()}/>
                <Box margin='small' border={{side: 'top', color: 'border', size: 'xsmall'}}/>
                <>
                    <Box direction='row' gap='small'>
                        <Book/>
                        <Heading fill level='4' margin='none'>{lawTitle}</Heading>
                    </Box>
                    <Paragraph fill>{lawDescription}</Paragraph>
                </>
                <Box
                    pad={{'horizontal': 'large', 'vertical': 'medium'}}
                    background={'background-selected'}
                    round={'small'}
                >
                    <RadioButtonGroup
                        name='dataPrivacyLaw'
                        options={dataPrivacyOptions}
                        value={props.selectedDataPrivacyLaw}
                        onChange={(event) => props.setDataPrivacyLaw(event.target.value as SelectedDataPrivacyLawType)}
                    />
                </Box>
                <Box margin='small' border={{side: 'bottom', color: 'border', size: 'xsmall'}}/>
                <>
                    <Box direction='row' gap='small'>
                        <Compliance/>
                        <Heading level='4' margin='none'>{finishTitle}</Heading>
                    </Box>
                    <Paragraph>{finishDescription}</Paragraph>
                </>
                <Stack anchor='top'>
                    <Box
                        pad='medium'
                        gap='medium'
                        background={'background-selected'}
                        round={'small'}
                    >
                        <Box
                            background={'background-selected-job-box'}
                            elevation='small'
                            round='small'
                            direction='row'
                            pad='small'
                            gap='small'
                            justify='between'
                            onClick={() => {
                                props.openOfficerModal()
                            }}>
                            <Text
                                color={!props.hasDataPrivacyOfficer ? normalizeColor('status-error', unovisionTheme) : ''}
                                weight={!props.hasDataPrivacyOfficer ? 'bold' : 'normal'}
                            >
                                Datenschutzbeauftragte
                                benennen
                            </Text>
                            {props.hasDataPrivacyOfficer ? <StatusGood color='status-ok'/> : <FormEdit
                                color={'status-error'}/>}
                        </Box>
                        {props.hasDataProcessingDescription !== undefined ?
                            <Box
                                background={'background-selected-job-box'}
                                elevation='small'
                                round='small'
                                direction='row'
                                pad='small'
                                gap='small'
                                justify='between'
                                onClick={() => {
                                    props.openDataProcessingDescriptionModal()
                                }}>
                                <Text>
                                    <div>Prozessabschnitte hinzufügen</div>
                                    <div>optional</div>
                                </Text>
                                {props.hasDataProcessingDescription ? <StatusGood color='status-ok'/> : <FormEdit/>}
                            </Box> : null
                        }
                        <Box
                            background={'background-selected-job-box'}
                            elevation='small'
                            round='small'
                            direction='row'
                            pad='small'
                            gap='small'
                            justify='between'
                            onClick={() => {
                                props.openMainAddressModal()
                            }}>
                            <Text
                                color={!props.hasCampaignMainAddress ? normalizeColor('status-error', unovisionTheme) : ''}
                                weight={!props.hasCampaignMainAddress ? 'bold' : 'normal'}
                            >
                                Standorte setzen
                            </Text>
                            {props.hasCampaignMainAddress ? <StatusGood color='status-ok'/> : <FormEdit
                                color={'status-error'}/>}
                        </Box>
                    </Box>
                    {!props.hasDataPrivacyOfficer || !props.hasCampaignMainAddress ?
                        <ErrorBadge showIcon margin={{'top': '-1.5em'}} label={`Bitte\u00A0ausfüllen`}/> : null}
                </Stack>
                <Box margin='small' border={{side: 'bottom', color: 'border', size: 'xsmall'}}/>
                <>
                    <Box direction='row' gap='small'>
                        <Support/>
                        <Heading level='4' margin='none'>{helpTitle}</Heading>
                    </Box>
                    <Paragraph>{helpDescription}</Paragraph>
                </>
                <BaseButton
                    label='Datenschutz Anwalt anfragen'
                    onClick={() => alert('Diese Funktion steht Ihnen demnächst zur Verfügung.')}
                />
            </Box>
        </Box>
    )
}

export default DataPrivacySideBar

import React, { useCallback } from 'react'
import { AttachDetachListView, NEW_PATH } from './AttachDetachListView'
import { useHistory, useRouteMatch } from 'react-router-dom'

type Props = {
    selectedOptionsTitle: string,
    selectedOptionsSubtitle: string,
    selectedOptions: { id: number, name: string, subTitle?: string, isEmpty: boolean, isHighlighted?: boolean }[],
    handleAttach: (selectedId: number) => void,
    handleDetach: (selectedId: number) => void,
    allOptions: { id: number, name: string, subTitle?: string, isHighlighted?: boolean }[],
    optionsTitle: string,
    optionsSubtitle: string,
    optionAddButtonLabel: string,
    performHighlight?: (selectedId: number) => void,
    highlightColor?: string,
    highlightedOptionDescription?: string,
}

export const AttachDetachListViewRouter: React.FC<Props> = (props: Props) => {

    const history = useHistory()
    const {url} = useRouteMatch()

    const handleEdit = useCallback((id) => {
        history.push(`${url}/${id}`)
    }, [history, url])

    const handleAdd = () => {
        history.push(`${url}/${NEW_PATH}`)
    }

    return (
        <AttachDetachListView
            {...props}
            handleEdit={handleEdit}
            handleAdd={handleAdd}
        />
    )
}
import { Card, CardBody, Heading } from 'grommet'
import React, { ReactNode, useRef } from 'react'
import { Paging, SimplePaginator } from './SimplePaginator'
import { LoadingOverlay } from '../layout/LoadingOverlay'

interface TablePageProps {
    title: ReactNode;
    nextPage?: () => void;
    previousPage?: () => void;
    paging?: Paging;
    massActions?: ReactNode;
    loading?: boolean;
    children: ReactNode;
}

export const TablePage = ({
                              title,
                              nextPage,
                              previousPage,
                              paging,
                              massActions,
                              children,
                              loading,
                          }: TablePageProps) => {

    const boxRef = useRef<HTMLDivElement | null>(null)

    return (
        <>
            {loading && <LoadingOverlay targetRef={boxRef}/>}
            <Heading size={'small'} margin={{top: '0', bottom: '20px'}}>{title}</Heading>
            {nextPage && previousPage && paging &&
            <SimplePaginator onNextPage={nextPage} onPrevPage={previousPage} paging={paging}/>}
            {massActions}
            {children}
            {nextPage && previousPage && paging &&
            <SimplePaginator onNextPage={nextPage} onPrevPage={previousPage} paging={paging}/>}
        </>
    )
}

interface FilterableTablePageProps extends TablePageProps {
    filterFormTitle: ReactNode;
    filterForm: ReactNode;
}

export const FilterableTablePage = ({
                                        title,
                                        filterForm,
                                        filterFormTitle,
                                        nextPage,
                                        previousPage,
                                        paging,
                                        massActions,
                                        children,
                                        loading,
                                    }: FilterableTablePageProps) => {

    const boxRef = useRef<HTMLDivElement | null>(null)

    return (
        <>
            <Heading size={'small'} margin={{top: '0', bottom: '20px'}}>{title}</Heading>
            {loading && <LoadingOverlay targetRef={boxRef}/>}
            <Card background={'light-1'} flex={false}>
                <CardBody pad={'medium'}>
                    <Heading size={'xxsmall'} margin={{top: '0'}}>{filterFormTitle}</Heading>
                    {filterForm}
                </CardBody>
            </Card>
            {
                nextPage && previousPage && paging &&
                <SimplePaginator onNextPage={nextPage} onPrevPage={previousPage} paging={paging}/>
            }
            {massActions}
            {children}
            {
                nextPage && previousPage && paging &&
                <SimplePaginator onNextPage={nextPage} onPrevPage={previousPage} paging={paging}/>
            }
        </>
    )
}

import React, { useCallback, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Box, Button, Form, FormField } from 'grommet'
import { ResetFormType, resetPassword } from '../../../api/recovery'
import { handleServiceCall } from '../../../api/common'
import { required } from '../../../components/inputs/validation'
import { LoginBox } from '../login/LoginBox'
import { PasswordInput } from '../../../components/inputs/PasswordInput'
import { ControlButtons } from '../../../components/buttons/ControlButtons'
import { BasePage } from '../../../components/layout/BasePage'

interface ResetParams {
    token: string;
}

export const Reset = () => {
    const {token} = useParams<ResetParams>()
    const [value, setValue] = useState<ResetFormType>({token, password: ''})
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const handleChange = useCallback(setValue, [setValue])

    const handleSubmit = useCallback(async({value}) => {
        await handleServiceCall(
            async() => {
                await resetPassword(value)
                setSubmitted(true)
            },
            setLoading,
            () => alert('Der Link zur Passwortwiederherstellung ist abgelaufen. '),
            errorCode => alert(`Es ist ein Fehler aufgetreten: ${errorCode}`),
        )
    }, [setSubmitted])

    return (
        <BasePage>
            <LoginBox pad='medium' direction='column'>
                <Form<ResetFormType>
                    value={value}
                    onChange={handleChange}
                    onSubmit={handleSubmit}>

                    <h1>Neues Passwort setzen</h1>

                    {!submitted ? (
                        <>
                            <FormField
                                label='Neues Passwort'
                                name='password'
                                validate={required}>

                                <PasswordInput
                                    name='password'
                                />
                            </FormField>

                            <ControlButtons>
                                <Box pad='xsmall'>
                                    <Link to='/'>Zum Login</Link>
                                </Box>

                                <Button
                                    primary
                                    disabled={loading}
                                    type='submit'
                                    label='Setzen'
                                />
                            </ControlButtons>
                        </>
                    ) : (
                        <>
                            <Box>
                                Ihr Passwort wurde geändert. Falls Sie sich dennoch nicht einloggen können, wiederholen
                                Sie
                                bitte den Prozess. Sollten Sie weiterhin Probleme haben, wenden Sie sich bitte an:
                                <a href={'mailto:support@alphacure.de'}>support@alphacure.de</a>
                            </Box>

                            <Box pad={{top: 'xsmall'}}>
                                <Link to='/'>Zur Startseite</Link>
                            </Box>
                        </>
                    )}
                </Form>
            </LoginBox>
        </BasePage>
    )
}

import React, { PropsWithChildren } from 'react'
import { Box, ResponsiveContext } from 'grommet'
import { BoxProps } from 'grommet/es6'

export type BannerColProps = BoxProps & PropsWithChildren<{
    displaySeparator?: boolean
}>

export const BannerCol: React.FC<BannerColProps> = (props: BannerColProps) => {
    const {
        displaySeparator,
        children,
        pad = {horizontal: 'medium', vertical: 'xxsmall'},
        margin = {vertical: 'medium'},
        ...rest
    } = props
    return <ResponsiveContext.Consumer>
        {screenSize => {
            return (
                <Box
                    {...rest}
                    pad={pad}
                    margin={margin}
                    border={displaySeparator ? {
                        color: 'light-5',
                        size: 'xsmall',
                        side: screenSize !== 'small' ? 'right' : 'bottom'
                    } : undefined}>
                    {children}
                </Box>
            )
        }
        }
    </ResponsiveContext.Consumer>
}

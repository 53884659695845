import React from 'react'
import { BannerContainer } from '../../components/layout/BannerContainer'
import { BannerCol } from '../../components/layout/BannerCol'
import { Box, ResponsiveContext, Text } from 'grommet'
import { JobApplicationCounterType } from './JobApplicationsContent'
import { CounterBadgeContainer } from '../../components/common/CounterBadgeContainer'
import { UserList } from 'phosphor-react'
import { unovisionTheme } from '../../theme/theme'

type JobApplicationsBannerProps = {
    counter: JobApplicationCounterType
}

export const JobApplicationsBanner: React.FC<JobApplicationsBannerProps> = (props: JobApplicationsBannerProps) =>
    <ResponsiveContext.Consumer>
        {screenSize =>
            <BannerContainer title='Bewerbungen'>
                <BannerCol displaySeparator justify='center'>
                    <Box direction='row' align='center' gap='small'>
                        {screenSize !== 'small' &&
                        <UserList size={32} color={unovisionTheme.global.colors.brand.light}/>}
                        <Text size='large'>Bewerbungen ihrer Anzeige Region</Text>
                    </Box>
                </BannerCol>
                <BannerCol direction='row' wrap>
                    <CounterBadgeContainer
                        label='Bewerbungen Gesamt'
                        background='status-warning'
                        counter={props.counter.total}
                    />
                    <CounterBadgeContainer
                        label='Reaktionen erforderlich'
                        background='status-ok'
                        counter={props.counter.reaction}
                    />
                    <CounterBadgeContainer
                        label='Bewerbungen abgeschlossen'
                        background='accent-2'
                        counter={props.counter.finished}
                    />
                    <CounterBadgeContainer
                        label='Warten auf Bewerber'
                        background='status-error'
                        counter={props.counter.waiting}
                    />
                </BannerCol>
            </BannerContainer>
        }
    </ResponsiveContext.Consumer>

import { unovisionClient } from './unovisionClient'
import { MediaDTO } from './media'
import { AddressModel } from '../models/AddressModel'
import { JobType } from './jobs'
import { JobBenefitType } from './jobBenefits'

export const AD_CONTEXT_URL = '/api/advertisement'

enum AdStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export type AdType = {
    id: number,
    title: string,
    textHeader?: string,
    textFooter?: string,
    image?: MediaDTO,
    video?: MediaDTO,
    status: AdStatus,
    jobs: JobType[]
    selectedJobBenefits: JobBenefitType[]
}

export type UpdateAdType = {
    title?: string,
    textHeader?: string,
    textFooter?: string,
}

export const createAd = async(campaignId: number): Promise<AdType> => {
    const response = await unovisionClient.post<AdType>(`${AD_CONTEXT_URL}/`, {campaignId: campaignId, fromAdvertisementId: null})
    return response.data
}

export const getAd = async(adId: number): Promise<AdType> => {
    const response = await unovisionClient.get<AdType>(`${AD_CONTEXT_URL}/${adId}`)
    return response.data
}

export const updateAd = async(adId: number, adType: UpdateAdType): Promise<AdType> => {
    const response = await unovisionClient.put<AdType>(`${AD_CONTEXT_URL}/${adId}`, adType)
    return response.data
}

export const updateAdJobs = async(adId: number, update: { jobIds: number[] }): Promise<AdType> => {
    const response = await unovisionClient.put<AdType>(`${AD_CONTEXT_URL}/${adId}/jobs`, update)
    return response.data
}

export const updateAdJobBenefits = async(adId: number, updateAd: { selectedJobBenefits: {jobBenefitId: number, position: number}[] }): Promise<AdType> => {
    const response = await unovisionClient.put<AdType>(`${AD_CONTEXT_URL}/${adId}/job-benefits`, updateAd)
    return response.data
}

export const updateAdImage = async(adId: number, mediaId: string): Promise<AdType> => {
    const response = await unovisionClient.put<AdType>(`${AD_CONTEXT_URL}/${adId}/image`, {mediaId: mediaId})
    return response.data
}

export const updateAdVideo = async(adId: number, mediaId: string): Promise<AdType> => {
    const response = await unovisionClient.put<AdType>(`${AD_CONTEXT_URL}/${adId}/video`, {mediaId: mediaId})
    return response.data
}

export const updateAdStatus = async(adId: number, active: boolean): Promise<AdType> => {
    const response = await unovisionClient.put<AdType>(`${AD_CONTEXT_URL}/${adId}/status`, {status: active ? AdStatus.ACTIVE : AdStatus.INACTIVE})
    return response.data
}

export const attachAdSubsidiary = async(adId: number, subsidiaryId: number): Promise<AddressModel[]> => {
    const response = await unovisionClient.post<AddressModel[]>(`${AD_CONTEXT_URL}/${adId}/subsidiary`, {subsidiary: subsidiaryId})
    return response.data
}

export const detachAdSubsidiary = async(adId: number, subsidiaryId: number): Promise<AddressModel[]> => {
    const response = await unovisionClient.delete<AddressModel[]>(`${AD_CONTEXT_URL}/${adId}/subsidiary/${subsidiaryId}`)
    return response.data
}

export const getAdSubsidiaries = async(adId: number): Promise<AddressModel[]> => {
    const response = await unovisionClient.get<AddressModel[]>(`${AD_CONTEXT_URL}/${adId}/subsidiary`)
    return response.data
}

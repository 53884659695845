import {AdModel} from '../../../../models/AdModel'
import React, {useCallback, useEffect, useState} from 'react'
import {getCampaignMainAddress, getCampaignSubsidiaries} from '../../../../api/campaigns'
import {AddressModel} from '../../../../models/AddressModel'
import {attachAdSubsidiary, detachAdSubsidiary, getAdSubsidiaries} from '../../../../api/ads'
import {addMainAddressToCampaign, removeMainAddressFromCampaign,} from "../../../../api/campaignManagement";
import {useCurrentCampaign} from "../../../../hooks/useCurrentCampaign";
import {AttachDetachListViewRouter} from './AttachDetachListViewRouter'

export const AdDetailSubsidiaries = ({currentAd}: { currentAd: AdModel }) => {

    const currentCampaign = useCurrentCampaign()
    const [mainAddressIds, setMainAddressIds] = useState<number[] | undefined>(undefined);

    const [adSubsidiaries, setAdSubsidiaries] = useState<AddressModel[]>([])
    const [allSubsidiaries, setAllSubsidiaries] = useState<AddressModel[]>([])

    const handleAttach = useCallback((subsidiaryId: number) => {
        attachAdSubsidiary(currentAd.id, subsidiaryId).then(result => {
            result && setAdSubsidiaries(result)
        })
    }, [currentAd.id])

    const handleDetach = useCallback((subsidiaryId: number) => {
        detachAdSubsidiary(currentAd.id, subsidiaryId).then(result => {
            result && setAdSubsidiaries(result)
        })
    }, [currentAd.id])

    useEffect(() => {
        getAdSubsidiaries(currentAd.id).then(result => {
            setAdSubsidiaries(result)
        })
        currentCampaign && getCampaignSubsidiaries(currentCampaign.id).then(result => {
            setAllSubsidiaries(result)
        })

    }, [currentAd.id])

    useEffect(() => {
        loadCampaignMainAddresses()
    }, [])

    function loadCampaignMainAddresses() {
        currentCampaign && getCampaignMainAddress(currentCampaign.id)
            .then(value => setMainAddressIds(value.map(address => address.id!)))
            .catch(reason => console.log(reason))
    }

    function isMainAddressSelected(addressId: number): boolean {
        return mainAddressIds?.includes(addressId)!!
    }

    async function addMainAddress(addressId: number) {
        if (!currentCampaign) return

        let updatedMainAddresses: number[] = []

        if (mainAddressIds !== undefined) updatedMainAddresses = updatedMainAddresses.concat(mainAddressIds)
        updatedMainAddresses.push(addressId)

        setMainAddressIds(updatedMainAddresses)

        try {
            await addMainAddressToCampaign(currentCampaign.id, {addressId})
        } catch (error: any) {
            console.log("An error occurred while adding a main address: ", error)
        }
    }

    async function removeMainAddress(addressId: number) {
        if (!currentCampaign) return

        let updatedMainAddresses = mainAddressIds?.filter(value => value !== addressId)

        setMainAddressIds(updatedMainAddresses)
        try {
            await removeMainAddressFromCampaign(currentCampaign.id, addressId)
        } catch (error: any) {
            console.log("An error occurred while removing a main address: ", error)
        }
    }

    async function toggleAddressAsCampaignMainAddressSelected(addressId: number) {
        if (!isMainAddressSelected(addressId)) {
            await addMainAddress(addressId)
        } else {
            await removeMainAddress(addressId)
        }
    }

    const selectedSubsidiaries = adSubsidiaries.map((data, index) => {
        const addressAdditionString = data.addressAddition ? ' ' + data.addressAddition : ''
        return {
            id: data.id || index,
            name: data.companyName || '',
            subTitle: data.street + ' ' + data.streetNumber + addressAdditionString + ', ' + data.postcode + ' ' + data.city,
            isEmpty: false,
            isHighlighted: data.id ? isMainAddressSelected(data.id) : false
        }
    })

    const allOptions = allSubsidiaries
        .filter(subsidiary => !adSubsidiaries
            .find(adSubsidiary => adSubsidiary.id === subsidiary.id))?.map((data, index) => {
            const addressAdditionString = data.addressAddition ? ' ' + data.addressAddition : ''
            return {
                id: data.id || index,
                name: data.companyName || '',
                subTitle: data.street + ' ' + data.streetNumber + addressAdditionString + ', ' + data.postcode + ' ' + data.city,
                isHighlighted: data.id ? isMainAddressSelected(data.id) : false
            }
        }) || []


    return <AttachDetachListViewRouter
        selectedOptionsTitle={selectedSubsidiaries.length + ' Standorte/Einrichtungen ausgewählt,'}
        selectedOptionsSubtitle={'die in der Anzeige beworben werden'}
        selectedOptions={selectedSubsidiaries}
        handleAttach={handleAttach}
        handleDetach={handleDetach}
        allOptions={allOptions}
        optionsTitle={'Verfügbare Standorte/Einrichtungen,'}
        optionsSubtitle={'die beworben werden können'}
        optionAddButtonLabel={'Neuen Standort hinzufügen'}
        // TODO: Properly hide the Button
        // performHighlight={toggleAddressAsCampaignMainAddressSelected}
        highlightColor={'background-selected'}
        highlightedOptionDescription={'Als Haupt-Adresse ausgewählt'}
    />
}


import React from 'react'
import { SUBSCRIBE_STEPS, SubscribePayload, SubscribeStepsType } from '../Subscribe'
import { Box, CheckBox, Heading, Text } from 'grommet'
import { VideoPlayer } from '../../../../components/media/VideoPlayer'
import { BaseButton } from '../../../../components/buttons/BaseButton'

const optionsTitle = 'Interessenformular'
const optionsDescription = 'Vielen Dank, für Ihr Vertrauen und Interesse an unseren Dienstleistungen. Damit Sie starten können, bitte\n' +
    '            beantworten Sie folgende Fragen:'
const agencyModelText = 'Ich möchte so schnell wie möglich starten und mit Unovision gemeinsam eine Kampagne umsetzen'
const softwareModelText = 'Die Software nutzen und benachrichtigt werden sobald es losgeht'
const sampleTitle = 'Beispiele anderer Kunden'
const sampleDescription = 'Kurz erklärt, wie unsere Software funktioniert:'

type SubscribeOptionStepProps = {
    input: SubscribePayload,
    stepperCb: (step: SubscribeStepsType) => void
}
// Todo Video links
export const SubscribeOptionsStep = (props: SubscribeOptionStepProps) =>
    <Box
        fill
        direction='row'
        wrap='reverse'
        justify='around'
        align='baseline'
        pad='large'
        animation='fadeIn'
        height={{'min': '60vh'}}
    >
        <Box
            width='large'
            pad='medium'
            gap='medium'
        >
            <>
                <Heading level='4' margin='none'>{sampleTitle}</Heading>
                <Text>{sampleDescription}</Text>
            </>
            <Box direction='row-responsive' gap='small'>
                <VideoPlayer src={''}/>
                <VideoPlayer src={''}/>
                <VideoPlayer src={''}/>
            </Box>
        </Box>
        <Box
            width='medium'
            pad='medium'
            gap='medium'
        >
            <>
                <Heading level='3' margin='none'>{optionsTitle}</Heading>
                <Text>{optionsDescription}</Text>
            </>
            <CheckBox
                name='isWorkWithUnovisionWanted'
                label={agencyModelText}
                checked={props.input.isWorkWithUnovisionWanted}
            />
            <CheckBox
                name='isPlatformLaunchInfoWanted'
                label={softwareModelText}
                checked={props.input.isPlatformLaunchInfoWanted}
            />
            <BaseButton
                label={'Weiter'}
                alignSelf='end'
                onClick={() => props.stepperCb(SUBSCRIBE_STEPS.COMPANY)}/>
        </Box>
    </Box>

import React from 'react'
import { SUBSCRIBE_STEPS, SubscribePayload, SubscribeStepsType } from '../Subscribe'
import { Anchor, Box, CheckBox, FormField, Heading, Text, TextInput } from 'grommet'
import { required, validateEmail, validatePhone } from '../../../../components/inputs/validation'
import { BaseButton } from '../../../../components/buttons/BaseButton'

// Todo Text content
const contactStepTitle = 'Ansprechpartner'
const contactInfoTitle = 'Wie können wir Sie am besten erreichen?'
const contactInfoSubtitle = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr.'
const contactInfoDescription = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.'


type SubscribeContactStepProps = {
    input: SubscribePayload,
    stepperCb: (step: SubscribeStepsType) => void
}

// Todo Check links
const gdprText = <Text>Hiermit bestätige ich die <Anchor href='https://unovision.de/datenschutzerklarung/'
                                                         label='AGB'/> und die <Anchor
    href='https://unovision.de/datenschutzerklarung/' label='Datenschutzbestimmung'/>
</Text>


export const SubscribeContactStep = (props: SubscribeContactStepProps) =>
    <Box
        direction='row'
        wrap
        justify='around'
        pad={{'horizontal': 'large'}}
        animation='fadeIn'
    >
        <Box
            width='medium'
            pad='medium'
            gap='medium'
            justify='center'
        >
            <>
                <Heading level='4' margin='none'>{contactInfoTitle}</Heading>
                <Text>{contactInfoSubtitle}</Text>
            </>
            <Text>{contactInfoDescription}</Text>
        </Box>
        <Box
            width='medium'
            pad='medium'
            gap='medium'
        >
            <>
                <Heading level='3'>{contactStepTitle}</Heading>
                <FormField
                    name='contactFirstname'
                    label='Vorname*'
                    validate={required}
                >
                    <TextInput name='contactFirstname' value={props.input.contactFirstname}/>
                </FormField>
                <FormField
                    name='contactLastname'
                    label='Nachname*'
                    validate={required}
                >
                    <TextInput name='contactLastname' value={props.input.contactLastname}/>
                </FormField>
                <FormField
                    name='contactEmail'
                    label='Emailaddresse*'
                    validate={required.concat(validateEmail as any)}
                >
                    <TextInput name='contactEmail' value={props.input.contactEmail}/>
                </FormField>
                <FormField
                    name='contactPhone'
                    label='Telefonnummer*'
                    validate={required.concat(validatePhone as any)}
                >
                    <TextInput name='contactPhone' value={props.input.contactPhone}/>
                </FormField>
                <Box direction='row' gap='small'>
                    <CheckBox
                        name='isPrivacyAgreementConfirmed'
                        checked={props.input.isPrivacyAgreementConfirmed}
                        required/>
                    {gdprText}
                </Box>
            </>
            <Box
                direction='row-responsive'
                gap='large'
                justify='between'
            >
                <BaseButton
                    label={'Zurück'}
                    onClick={() => props.stepperCb(SUBSCRIBE_STEPS.COMPANY)}
                />
                <BaseButton
                    label={'Absenden'}
                    type='submit'
                    onClick={() => props.stepperCb(SUBSCRIBE_STEPS.CONTACT)}
                />
            </Box>
        </Box>
    </Box>

import {
    addGeoTarget as callAddGeoTarget,
    GeoTargetType,
    getGeoTargets as callGetGeoTargets,
    GpsLocationType,
    removeGeoTarget as callRemoveGeoTarget
} from '../api/campaignManagement'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

export const useGeoTargets = () => {

    function getGeoTargets(campaignId: number): Promise<GeoTargetType[]> {
        return callGetGeoTargets(campaignId).then(value => value.geoTargets)
    }

    async function addGeoTargetToCampaignFromAddress(campaignId: number, addressString: string, givenName?: string): Promise<void> {

        const results = await geocodeByAddress(addressString)

        const latLng = await getLatLng(results[0])
        console.log('Successful retrieval of GPS coordinates for ' + addressString, latLng)

        return callAddGeoTarget(campaignId, {
            givenName: givenName,
            addressString: addressString,
            gpsLocation: {latitude: latLng.lat, longitude: latLng.lng}
        })
    }

    async function addGeoTargetToCampaign(campaignId: number, addressString: string, gpsLocation: GpsLocationType, givenName?: string): Promise<void> {

        await callAddGeoTarget(campaignId, {
            givenName: givenName,
            addressString: addressString,
            gpsLocation: {latitude: gpsLocation.latitude, longitude: gpsLocation.longitude}
        })
    }

    function removeGeoTargetFromCampaign(campaignId: number, index: number): Promise<void> {
        return callRemoveGeoTarget(campaignId, index)
    }

    return {
        getGeoTargets: getGeoTargets,
        addGeoTargetToCampaignFromAddress: addGeoTargetToCampaignFromAddress,
        addGeoTargetToCampaign: addGeoTargetToCampaign,
        removeGeoTargetFromCampaign: removeGeoTargetFromCampaign
    }
}

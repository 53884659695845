import React, { useCallback, useEffect, useState } from 'react'
import { useCurrentCampaign } from '../../hooks/useCurrentCampaign'
import { AddressModel } from '../../models/AddressModel'
import { createCampaignSubsidiary, getCampaignSubsidiaries } from '../../api/campaigns'
import { attachAdSubsidiary } from '../../api/ads'
import { addMainAddressToCampaign } from '../../api/campaignManagement'
import { deleteSubsidiary, updateSubsidiary } from '../../api/subsidiaries'
import { useHistory, useParams } from 'react-router-dom'
import { AdDetailSubsidiaryEdit } from '../ads/adDetail/content/AdDetailSubsidiaryEdit'
import { AdModel } from '../../models/AdModel'
import { NEW_PATH } from '../ads/adDetail/content/AttachDetachListView'

type Props = {
    currentAd: AdModel
}
export const DetailSubsidiaryEditPage: React.FC<Props> = (props: Props) => {

    const history = useHistory()
    const {subsidiaryId} = useParams<{ subsidiaryId: string }>()
    const currentCampaign = useCurrentCampaign()
    const [subsidiary, setSubsidiary] = useState<AddressModel | undefined>(undefined)
    const [isNew, setIsNew] = useState(true)

    useEffect(() => {
        loadSubsidiary()
    }, [props.currentAd])

    const loadSubsidiary = () => {
        if (subsidiaryId !== NEW_PATH) {
            setIsNew(false)
            currentCampaign && getCampaignSubsidiaries(currentCampaign.id).then(res => {
                setSubsidiary(res.find(s => s.id === +subsidiaryId))
            })
        }
    }

    const handleSubmit = async (address: AddressModel) => {
        if(currentCampaign && isNew) {
            const subsidiary = await createCampaignSubsidiary(currentCampaign.id, address)
            const subsidiaries = await attachAdSubsidiary(props.currentAd.id, subsidiary.id!)
            if (subsidiaries.length === 1) await addMainAddressToCampaign(currentCampaign.id, {addressId: subsidiary.id!})
            history.goBack()
            return
        }
        updateSubsidiary(address).then(() => {
            history.goBack()
        })
    }

    const handleDelete = useCallback(() => {
        deleteSubsidiary(subsidiary?.id!!).then(() => {
            history.goBack()
        })
    }, [history, subsidiary])

    return (
        <AdDetailSubsidiaryEdit
            subsidiary={subsidiary}
            onSubmit={handleSubmit}
            onDelete={subsidiary !== undefined ? handleDelete : undefined}
        />
    )
}

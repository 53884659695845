import React from 'react'
import { Box, Text } from 'grommet'
import { Location, Trash } from 'grommet-icons'

type GeoTargetListItemProps = {
    title?: string
    subtitle?: string
    onDeleteClick: () => void
    onSelect: () => void
    iconColor?: any
}

export const GeoTargetListItem = (input: GeoTargetListItemProps) => {

    function stopPropagation(e: any) {
        let evt = e ? e : window.event
        if(evt.stopPropagation) evt.stopPropagation()
        if(evt.cancelBubble != null) evt.cancelBubble = true
    }

    return (
        <Box
            direction='row'
            justify='between'
            align='center'
            gap='large'
            background='white'
            onClick={input.onSelect}
        >
            <Box
                direction='row'
                align='center'
                pad='xsmall'
                gap='xsmall'
            >
                <Location size='large' color={input.iconColor}/>
                <Box>
                    <Text weight='bold' size={'medium'}>
                        {input.title ? input.title : 'No name given'}
                    </Text>
                    <Text size={'small'}>
                        {input.subtitle}
                    </Text>
                </Box>
            </Box>
            <Box
                pad='small'
                onClick={(e) => {
                    stopPropagation(e)
                    input.onDeleteClick()
                }}>
                <Trash size='medium' color='brand'/>
            </Box>
        </Box>
    )
}


import React, {useEffect, useState} from 'react'
import {BannerContainer} from '../../components/layout/BannerContainer'
import {Box, ResponsiveContext, Spinner, Text} from 'grommet'
import {BannerCol} from '../../components/layout/BannerCol'
import {ListBullets} from 'phosphor-react'
import {CounterBadgeContainer} from '../../components/common/CounterBadgeContainer'
import {unovisionTheme} from '../../theme/theme'
import {
    getDataPrivacyByCampaignId,
    getEmployerDescriptionByCampaignId,
    getGeoTargets
} from '../../api/campaignManagement'
import {CheckListItem} from '../../components/text/CheckListItem'
import {getDataPrivacyProperties} from "../../helpers/dataPrivacyProperties";

type AdsBannerProps = {
    onlineCounter: number
    offlineCounter: number
    campaignId: number
}

export const AdsBanner = (props: AdsBannerProps) => {

    // Todo Check if FacebookSite is connected
    const [hasFacebookSite, setHasFacebookSite] = useState(true)
    const [hasEmployerDescription, setHasEmployerDescription] = useState(false)
    const [hasGeoTargets, setHasGeoTargets] = useState(false)
    const [hasDataPrivacy, setHasDatePrivacy] = useState(false)

    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        const employerDescription = getEmployerDescriptionByCampaignId(props.campaignId).then(res => {
            let result = true
            res.description.forEach(des => {
                if(des.text === '') return result = false
            })
            setHasEmployerDescription(result)
        })
        const dataPrivacy = getDataPrivacyByCampaignId(props.campaignId).then(res => {
            const dataPrivacyOfficers = res.dataPrivacyDTO?.dataPrivacyOfficers
            const hasEnoughMainAddresses = (res.dataPrivacyDTO?.campaignMainAddresses?.length ?? 0) >= getDataPrivacyProperties(res.dataPrivacyDTO?.selectedDataPrivacyLaw).requiredAmountOfMainAddresses
            if(dataPrivacyOfficers && dataPrivacyOfficers.length > 0 && hasEnoughMainAddresses) setHasDatePrivacy(true)
        })
        const geoTargets = getGeoTargets(props.campaignId).then(res => res.geoTargets.length > 0 && setHasGeoTargets(true))

        Promise.all([employerDescription, dataPrivacy, geoTargets]).then(() => setIsLoaded(true))
    }, [props.campaignId])

    return (
        <ResponsiveContext.Consumer>
            {screenSize =>
                <BannerContainer title='Ihre Stellenanzeigenliste'>
                    <BannerCol displaySeparator justify='center'>
                        <Box direction='row' align='center' gap='small'>
                            {screenSize !== 'small' &&
                            <ListBullets size={32} color={unovisionTheme.global.colors.brand.light}/>}
                            <Text size='large'>Stellenanzeigen ihrer Anzeige Region</Text>
                        </Box>
                    </BannerCol>
                    <BannerCol direction='row' wrap displaySeparator>
                        <CounterBadgeContainer
                            label='Stellenanzeigen Online'
                            background='status-ok'
                            counter={props.onlineCounter}
                        />
                        <CounterBadgeContainer
                            label='Stellenanzeigen Offline'
                            background='status-error'
                            counter={props.offlineCounter}
                        />
                    </BannerCol>
                    <BannerCol direction='row' wrap align='center'>
                        {isLoaded ?
                            <>
                                <Box>
                                    {(screenSize !== 'small' || !hasFacebookSite) &&
                                    <CheckListItem label='Facebookseite aktiv' isChecked={hasFacebookSite}/>}
                                    {(screenSize !== 'small' || !hasGeoTargets) &&
                                    <CheckListItem label='Ausspielorte definiert' isChecked={hasGeoTargets}/>}
                                </Box>
                                <Box>
                                    {(screenSize !== 'small' || !hasDataPrivacy) &&
                                    <CheckListItem label='Datenschutzinfo ausgefüllt' isChecked={hasDataPrivacy}/>}
                                    {(screenSize !== 'small' || !hasGeoTargets) &&
                                    <CheckListItem label='Arbeitgeberbeschreibung ausgefüllt'
                                                   isChecked={hasEmployerDescription}/>}
                                </Box>
                            </>
                            : <Spinner/>
                        }
                    </BannerCol>
                </BannerContainer>
            }
        </ResponsiveContext.Consumer>
    )
}

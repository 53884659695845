import { TableCell, Text } from 'grommet'
import { FormEdit, FormTrash } from 'grommet-icons'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { deleteUser, UserType } from '../../../api/users'
import { UnauthorizedException } from '../../../api/UnauthorizedException'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { ConfirmDialog, useConfirm } from '../../../components/common/ConfirmDialog'
import { HoverTableRow } from '../../../components/common/HoverTableRow'
import { UserDialog } from './UserDialog'
import {TenantType} from "../../../api/tenant";

const FormTrashStyled = styled(FormTrash)`
  vertical-align: bottom;
  cursor: pointer;
`

const FormEditStyled = styled(FormEdit)`
  vertical-align: bottom;
  cursor: pointer;
  margin-right: 3px;
`

export const UserRow = ({
                            user,
                            allTenants,
                            reload,
                        }: { user: UserType, allTenants: TenantType[], reload: () => void }) => {
    const setCurrentUser = useCurrentUser()[1]

    const handleDelete = useCallback(async() => {
        if(user.username) {
            try {
                await deleteUser(user.username)
                reload()
            } catch(e) {
                if(e instanceof UnauthorizedException) {
                    setCurrentUser(null)
                }
            }
        }
    }, [reload, user.username, setCurrentUser])

    const {confirmVisible, handleConfirmOpen, handleConfirmOk, handleConfirmCancel} = useConfirm(handleDelete)

    return (
        <HoverTableRow>
            <TableCell direction={'row'}>
                {confirmVisible && (
                    <ConfirmDialog label={'Backoffice User löschen?'} onCancel={handleConfirmCancel}
                                   onOk={handleConfirmOk}>
                        <Text>
                            Wollen sie wirklich den Benutzer '{user.username}' löschen?
                        </Text>
                    </ConfirmDialog>
                )}
                {user.username !== 'admin' ? <FormTrashStyled onClick={handleConfirmOpen}/> : null}
                <UserDialog type={'edit'} formValues={user} reload={reload} allTenants={allTenants}
                            render={(handler: () => void) => <>
                                <FormEditStyled onClick={handler}/>
                            </>}/>
            </TableCell>
            <TableCell>
                {user.username}
            </TableCell>
            <TableCell>
                {user.firstname}
            </TableCell>
            <TableCell>
                {user.name}
            </TableCell>
            <TableCell>
                {user.email}
            </TableCell>
            <TableCell>
                {allTenants.find(client => client.id === user.clientId)?.name}
            </TableCell>
            <TableCell>
                {user.roles?.join(', ')}
            </TableCell>
            <TableCell>
                {user.enabled ? 'Yes' : 'No'}
            </TableCell>
        </HoverTableRow>
    )
}

import React, { useRef } from 'react'
import { Box, Stack, Text } from 'grommet'
import { Placeholder, YoutubeLogo } from 'phosphor-react'
import styled from 'styled-components'
import { unovisionTheme } from '../../theme/theme'

export type VideoPlayerProps = {
    src?: string,
    type?: string,
    poster?: string,
    round?: string
}

export const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
    const {src, type = 'video/mp4', poster, round} = props

    const ref = useRef<HTMLVideoElement>(null)

    return src ?
        <Box round={round} overflow={'hidden'} background={'video-background'}>
            <HTMLVideoElement
                ref={ref}
                controls={true}
                poster={poster}>
                <source
                    src={src}
                    type={type}
                />
                Ihr Browser unterstützt dieses Video nicht.
            </HTMLVideoElement>
        </Box> : <Box height='medium' justify='center' align='center' border={{size: 'xsmall', color: 'light-6'}}>
            <Stack anchor='center'>
                <Placeholder size={50} color={unovisionTheme.global.colors['light-5']}/>
                <YoutubeLogo size={24}/>
            </Stack>
            <Text>Noch kein Video ausgewählt</Text>
        </Box>
}

const HTMLVideoElement = styled.video`
video::-webkit-media-controls-panel: {
    display: flex !important;
    opacity: 1 !important;
}    
`

import React from 'react'
import { Box, Text, BoxProps } from 'grommet'
import { unovisionTheme } from '../../theme/theme'
import { normalizeColor } from 'grommet/utils'
import { CircleAlert } from 'grommet-icons'

interface ErrorBadgeProps extends BoxProps{
    label: string
    showIcon?: boolean
}

export const ErrorBadge: React.FC<ErrorBadgeProps> = (props: ErrorBadgeProps) => {
    return <Box
        {...props}
        align={'center'}
        round={'medium'}
        direction='row'
        pad='xsmall'
        background={normalizeColor('status-error', unovisionTheme)}
    >
        {props.showIcon && <CircleAlert color={'white'}/>}
        <Text margin={{left: 'small', right: 'small'}}>
            {props.label}
        </Text>
    </Box>
}

import React, { ReactNode } from 'react'
import { Box, Text } from 'grommet'
import { unovisionTheme } from '../../theme/theme'
import { IconContext } from 'phosphor-react'

export type ResponsiveSidebarCategoryProps = {
    title: string,
    icon?: ReactNode,
    sidebarItems: ReactNode[],
}

export const ResponsiveSidebarCategory: React.FC<ResponsiveSidebarCategoryProps> = (props: ResponsiveSidebarCategoryProps) =>
    <Box pad={{horizontal: 'medium', vertical: 'small'}}>
        <Box
            pad={{top: 'xsmall'}}
            direction='row'
            gap='small'
            align='center'
        >
            <IconContext.Provider value={{color: unovisionTheme.global.colors['dark-4'], size: 24}}>
                {props.icon}
            </IconContext.Provider>
            <Text size='large' weight='bold' color={unovisionTheme.global.colors['dark-4']}>{props.title}</Text>
        </Box>
        <Box border={{color: 'border', side: 'top'}} pad={{bottom: 'small'}}/>
        {props.sidebarItems.map((sidebarItem: ReactNode, idx: number) => <Box key={idx}>{sidebarItem}</Box>)}
    </Box>

import React, { useEffect, useState } from 'react'
import { SUBSCRIBE_STEPS, SubscribePayload, SubscribeStepsType } from '../Subscribe'
import { Box, FormField, Heading, Text, TextInput } from 'grommet'
import { required } from '../../../../components/inputs/validation'
import { BaseButton } from '../../../../components/buttons/BaseButton'

// Todo Text content
const companyTitle = 'Unternehmensdetails bestätigen'
const companyInfoTitle = 'Wie es weiter gehen wird'
const companyInfoSubtitle = 'Kurz erklärt, was die nächsten Schritte sind'
const companyInfoDescription = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'

type SubscribeFormStepProps = {
    input: SubscribePayload,
    stepperCb: (step: SubscribeStepsType) => void
}

export const SubscribeCompanyStep = (props: SubscribeFormStepProps) => {

    // Validate just the input of this step to enable/disable next button
    const [valid, setValid] = useState(false)
    useEffect(() => {
        setValid(props.input.companyName.trim().length !== 0
            && props.input.street.trim().length !== 0
            && props.input.streetNumber.trim().length !== 0
            && props.input.postcode.trim().length !== 0
            && props.input.city.trim().length !== 0)
    }, [props.input])

    return (
        <Box
            direction='row'
            wrap
            justify='around'
            pad={{'horizontal': 'large'}}
            animation='fadeIn'
        >
            <Box
                width='medium'
                pad='medium'
                gap='medium'
                justify='center'
            >
                <>
                    <Heading level='4' margin='none'>{companyInfoTitle}</Heading>
                    <Text>{companyInfoSubtitle}</Text>
                </>
                <Text>{companyInfoDescription}</Text>
            </Box>
            <Box
                width='medium'
                pad='medium'
                gap='medium'
            >
                <>
                    <Heading level='3'>{companyTitle}</Heading>
                    <FormField
                        name='companyName'
                        label='Unternehmensname*'
                        validate={required}
                    >
                        <TextInput name='companyName' value={props.input.companyName}/>
                    </FormField>
                    <Box
                        direction='row'
                        gap='small'
                        justify='between'
                    >
                        <FormField
                            name='street'
                            width='medium'
                            label='Straße*'
                            validate={required}
                        >
                            <TextInput name='street' value={props.input.street}/>
                        </FormField>
                        <FormField
                            name='streetNumber'
                            label='Hausnummer*'
                            validate={required}
                        >
                            <TextInput name='streetNumber' value={props.input.streetNumber}/>
                        </FormField>
                    </Box>
                    <FormField name='addressAddition' label='Adresszusatz'>
                        <TextInput name='addressAddition' value={props.input.addressAddition}/>
                    </FormField>
                    <FormField
                        name='postcode'
                        label='Postleitzahl*'
                        validate={required}
                    >
                        <TextInput name='postcode' value={props.input.postcode}/>
                    </FormField>
                    <FormField
                        name='city'
                        label='Stadt*'
                        validate={required}
                    >
                        <TextInput name='city' value={props.input.city}/>
                    </FormField>
                </>
                <Box
                    direction='row-responsive'
                    gap='large'
                    justify='between'
                >
                    <BaseButton
                        label={'Zurück'}
                        onClick={() => props.stepperCb(SUBSCRIBE_STEPS.OPTIONS)}
                    />
                    <BaseButton
                        label={'Weiter'}
                        disabled={!valid}
                        onClick={() => props.stepperCb(SUBSCRIBE_STEPS.CONTACT)}
                    />
                </Box>
            </Box>
        </Box>
    )
}

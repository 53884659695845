import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Card, CardBody, Form, FormField, TextInput } from 'grommet'
import { Close } from 'grommet-icons'
import { handleServiceCall } from '../../../api/common'
import { getProfile, ProfileType, updateProfile } from '../../../api/users'
import { TablePage } from '../../../components/common/FilterableTablePage'
import { StaticFormField } from '../../../components/common/ModeFormField'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { ControlButtons } from '../../../components/buttons/ControlButtons'

const StyledClose = styled(Close)`
  position: absolute;
  right: 12px;
  top: 12px;
`

const StyledCard = styled(Card)`
  position: relative;
  margin-bottom: 20px;
`

export const Profile = () => {
    const [, setCurrentUser] = useCurrentUser()
    const [loading, setLoading] = useState(false)
    const [saved, setSaved] = useState(false)
    const [profile, setProfile] = useState<ProfileType>({
        username: '',
        firstname: '',
        name: '',
        email: '',
        password: '',
    })

    const loadProfile = useCallback(async() => {
        handleServiceCall(
            async() => setProfile(await getProfile()),
            setLoading,
            () => setCurrentUser(null)
        )
    }, [setLoading, setCurrentUser, setProfile])

    useEffect(() => {
        loadProfile()
    }, [loadProfile])

    const handleSubmit = useCallback(async({value}: { value: ProfileType }) => {
        handleServiceCall(
            async() => {
                await updateProfile(value)
                await setSaved(true)
            },
            setLoading,
            () => setCurrentUser(null)
        )
    }, [setLoading, setCurrentUser])

    const handleCancel = useCallback(async() => {
        loadProfile()
    }, [loadProfile])

    return (
        <TablePage title={'Profil bearbeiten'} loading={loading}>
            {saved && (
                <StyledCard background={'rgb(23, 235, 160)'}>
                    <CardBody pad='small'>
                        Das Profil wurde gespeichert. <StyledClose onClick={() => setSaved(false)}/>
                    </CardBody>
                </StyledCard>
            )}

            <Form<ProfileType>
                value={profile}
                onChange={(value: ProfileType) => setProfile(value)}
                onSubmit={handleSubmit}
                onReset={handleCancel}>

                <StaticFormField
                    name={'username'}
                    label={'Username'}
                    display={profile?.username}
                />

                <FormField name={'firstname'} label={'Vorname'}>
                    <TextInput name={'firstname'}/>
                </FormField>

                <FormField name={'name'} label={'Nachname'}>
                    <TextInput name={'name'}/>
                </FormField>

                <FormField name={'email'} label={'Email'}>
                    <TextInput name={'email'}/>
                </FormField>

                <FormField name={'password'} label={'Password'}
                           help={'Leer lassen, um das vorige Passwort zu behalten.'}>
                    <TextInput type={'password'} name={'password'}/>
                </FormField>

                <ControlButtons>
                    <Button type={'submit'} primary label={'Speichern'}/>
                    <Button type={'reset'} label={'Zurücksetzen'}/>
                </ControlButtons>
            </Form>
        </TablePage>
    )
}

import React, {useEffect} from 'react'
import {BasePage, PageWithBreadcrumbsProps} from "../../components/layout/BasePage";
import {MessageDetailContent} from "./MessageDetailContent";

export const MessageDetailPage: React.FC<PageWithBreadcrumbsProps> = ({crumbs}: PageWithBreadcrumbsProps) => {
    return (
        <BasePage crumbs={crumbs} title='Nachrichtdetails' noPad>
            <MessageDetailContent/>
        </BasePage>
    )
}

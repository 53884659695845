import React from 'react'
import {
    IdentificationBadge,
    Star,
    Chats,
    UserGear,
    Alarm,
    CircleWavyCheck,
    Funnel,
} from 'phosphor-react'
import { JobApplicationFilterType } from './JobApplicationsContent'
import { ResponsiveSidebar } from '../../components/sidebar/ResponsiveSidebar'
import { ResponsiveSidebarCategory } from '../../components/sidebar/ResponsiveSidebarCategory'
import { ResponsiveSidebarItem } from '../../components/sidebar/ResponsiveSidebarItem'
import { unovisionTheme } from '../../theme/theme'
import { useCampaignJobs } from '../../hooks/useCampaignJobs'

type JobApplicationsSidebarProps = {
    activeFilters: JobApplicationFilterType
    updateFilters: (filter: JobApplicationFilterType) => void
}

export const JobApplicationsSidebar = (props: JobApplicationsSidebarProps) => {

    const {allCampaignJobs} = useCampaignJobs()

    let filterCounter = 0
    props.activeFilters.favorite && filterCounter++
    props.activeFilters.job && filterCounter++
    props.activeFilters.status && filterCounter++

    const jobSidebarItems = allCampaignJobs?.map((job) =>
        <ResponsiveSidebarItem
            active={props.activeFilters.job === job}
            label={job.name}
            icon={<IdentificationBadge color={props.activeFilters.job === job ?
                'white' : unovisionTheme.global.colors.brand.light}/>}
            onClick={() => props.updateFilters({job: job})}/>
    ) || []

    return (
        <ResponsiveSidebar icon={<Funnel size={20} weight='bold' color='white'/>}
                           counter={filterCounter}
                           background={filterCounter > 0 ? 'orange' : undefined}
                           label='Filter'>
            <ResponsiveSidebarCategory
                title='Nach Job filtern'
                icon={<Funnel/>}
                sidebarItems={jobSidebarItems}/>
            <ResponsiveSidebarCategory
                icon={<Funnel/>}
                title={'Nach Favorit filtern'}
                sidebarItems={
                    [<ResponsiveSidebarItem
                        label={'Favorit'}
                        icon={<Star
                            color={props.activeFilters.favorite ?
                                'white' : unovisionTheme.global.colors.brand.light}/>}
                        active={props.activeFilters.favorite}
                        onClick={() => props.updateFilters({favorite: !props.activeFilters.favorite})}/>]
                }/>
            <ResponsiveSidebarCategory
                icon={<Funnel/>}
                title={'Nach Status filtern'}
                sidebarItems={[
                    <ResponsiveSidebarItem
                        label={'Neue Nachricht'}
                        icon={<Chats color={props.activeFilters.status === 'CONVERSATION_APPLICANT_SENT_A_MESSAGE' ?
                            'white' : unovisionTheme.global.colors.brand.light}/>}
                        active={props.activeFilters.status === 'CONVERSATION_APPLICANT_SENT_A_MESSAGE'}
                        onClick={() => props.updateFilters({status: 'CONVERSATION_APPLICANT_SENT_A_MESSAGE'})}/>,
                    <ResponsiveSidebarItem
                        label={'Neue Bewerbung'}
                        icon={<UserGear color={props.activeFilters.status === 'APPLICATION_NEW_APPLICANT' ?
                            'white' : unovisionTheme.global.colors.brand.light}/>}
                        active={props.activeFilters.status === 'APPLICATION_NEW_APPLICANT'}
                        onClick={() => props.updateFilters({status: 'APPLICATION_NEW_APPLICANT'})}/>,
                    <ResponsiveSidebarItem
                        label={'Ihre Reaktion erforderlich'}
                        icon={<Alarm color={props.activeFilters.status === 'APPLICATION_APPLICANT_IS_WAITING' ?
                            'white' : unovisionTheme.global.colors.brand.light}/>}
                        active={props.activeFilters.status === 'APPLICATION_APPLICANT_IS_WAITING'}
                        onClick={() => props.updateFilters({status: 'APPLICATION_APPLICANT_IS_WAITING'})}/>,
                    <ResponsiveSidebarItem
                        label={'Bewerber eingestellt'}
                        icon={<CircleWavyCheck color={props.activeFilters.status === 'APPLICATION_APPLICANT_IS_HIRED' ?
                            'white' : unovisionTheme.global.colors.brand.light}/>}
                        active={props.activeFilters.status === 'APPLICATION_APPLICANT_IS_HIRED'}
                        onClick={() => props.updateFilters({status: 'APPLICATION_APPLICANT_IS_HIRED'})}/>
                ]
                }/>
        </ResponsiveSidebar>
    )
}

import React from 'react'
import { Box, ResponsiveContext, Text } from 'grommet'
import { Check, IconContext } from 'phosphor-react'
import { DisableableBox } from '../common/DisableableBox'
import { unovisionTheme } from '../../theme/theme'

export type ResponsiveSidebarItemProps = {
    label: string,
    onClick: () => void,
    icon?: JSX.Element,
    active?: boolean,
    disabled?: boolean,
    hideCheckMark?: boolean
}

export const ResponsiveSidebarItem: React.FC<ResponsiveSidebarItemProps> = (props: ResponsiveSidebarItemProps) =>
    <ResponsiveContext.Consumer>
        {screenSize =>
            <DisableableBox
                disabled={props.disabled}
                onClick={props.onClick}
                hoverIndicator={!props.active ? 'light-1' : ''}
                background={props.active ? 'brand' : ''}
                width='medium'
                round='xsmall'
                pad={screenSize !== 'small' ? {horizontal: 'small', vertical: 'xsmall'} : 'medium'}
            >
                <Box direction='row' justify='between'>
                    <Box direction='row' gap='xsmall'>
                        <IconContext.Provider value={props.active ?
                            {size: 24, color: 'white'} :
                            {
                                size: 24,
                                color: unovisionTheme.global.colors.brand.light
                            }}>
                            {props.icon}
                        </IconContext.Provider>
                        <Text>{props.label}</Text>
                    </Box>
                    {props.active && !props.hideCheckMark ? <Check color='white'/> : undefined}
                </Box>
            </DisableableBox>
        }
    </ResponsiveContext.Consumer>


import React, {useContext, useEffect, useState} from 'react'
import {Avatar, Box, Button, Heading} from 'grommet'
import { BaseButton } from '../../components/buttons/BaseButton'
import { LoadingOverlay } from '../../components/layout/LoadingOverlay'
import { useHistory } from 'react-router-dom'
import { CampaignContext } from '../../store/CampaignContext'
import {CampaignDialog} from "../admin/campaign/CampaignDialog";
import {MetaBar} from "../../components/navigation/MetaBar";
import {useCurrentUser} from "../../hooks/useCurrentUser";
import {getClientById, getCurrentClientId, TenantType} from "../../api/tenant";

export const SelectCampaignPage: React.FC = () => {
    const campaignContext = useContext(CampaignContext)
    const [currentUser, setCurrentUser] = useCurrentUser()
    const [currentClient, setCurrentClient] = useState<TenantType | undefined>(undefined)
    const history = useHistory()

    useEffect(() => {
        loadCurrentClient()
    }, [currentUser])

    const loadCurrentClient = async() => {
        if(currentUser) {
            const currentClientId = getCurrentClientId(currentUser)
            setCurrentClient(await getClientById(currentClientId))
        }
    }

    if(campaignContext.campaigns && campaignContext.campaigns.length === 0) {
        return <CampaignDialog type={'create'} reload={() => { campaignContext.reloadCampaigns() }} render={(handler: () => void) => <>
            <Box width={'small'} flex={false}><Button primary onClick={handler} label={'Neue Anzeigenregion'}/></Box>
        </>}/>
    }

    if(!campaignContext.currentCampaign || !campaignContext.campaigns || !currentClient) return <LoadingOverlay visible/>

    if(campaignContext.campaigns.length <= 1) history.push(`/campaign/${campaignContext.currentCampaign.id}/dashboard`)

    return (
        <Box fill>
            <MetaBar hideBackButton title={`Klient: ${currentClient.name}`} mobileTitle={currentClient.name}/>
            <Box fill justify='center' align='center'>
                <Heading level='3'>Wählen Sie Ihre Anzeigen Region</Heading>
                <Box direction='row' gap='medium'>
                    {campaignContext.campaigns.map((campaign, idx) =>
                        <Box key={idx} gap='medium' align='center'>
                            <Avatar src={campaign.avatarUrl}/>
                            <BaseButton primary
                                        label={campaign.name}
                                        onClick={() => {
                                            campaignContext.setCurrentCampaign(campaign)
                                            history.push(`/campaign/${campaign.id}/dashboard`)
                                        }}/>
                        </Box>
                    )
                    }
                </Box>
            </Box>
        </Box>
    )
}

import {LoginFormType} from "./Login";
import React, {useState} from "react";
import {Modal} from "../../../components/layout/Modal";
import {Box, Button, Form, FormField} from "grommet";
import {ControlButtons} from "../../../components/buttons/ControlButtons";
import {Link} from "react-router-dom";
import {LoadingOverlay} from "../../../components/layout/LoadingOverlay";
import {authenticate} from "../../../api/common";
import {useCurrentUser} from "../../../hooks/useCurrentUser";

type LoginModalType = {
    onSuccess: () => void;
    clientId?: string;
}

export const LoginModal: React.FC<LoginModalType> = (input) => {

    const [currentUser, setCurrentUser] = useCurrentUser()
    const [loginData, setLoginData] = useState<LoginFormType>({username: '', password: ''})
    const [loading, setLoading] = useState(false)

    const onSubmit = async (loginData: LoginFormType) => {
        setLoading(true)
        const user = await authenticate(loginData.username, loginData.password, input.clientId)
        setCurrentUser(user)
        setLoading(false)
        input.onSuccess()
    }

    return (
        <Modal headless>
            <Box justify='center' align='center'>
                <Box
                    pad='medium'
                    direction='column'
                    width='medium'
                    background='light-2'
                    round='small'
                    border={{color: 'light-3', size: 'small'}}
                >
                    <Form<LoginFormType>
                        value={loginData}
                        onChange={setLoginData}
                        onSubmit={() => onSubmit(loginData)}>

                        <h1>Login</h1>

                        <FormField
                            name='username'
                            label='Benutzername'
                            required
                        />

                        <FormField
                            name='password'
                            label='Passwort'
                            type='password'
                            required
                        />

                        <ControlButtons>
                            <Box pad='xsmall'>
                                <Link to='recovery'>
                                    Passwort vergessen?
                                </Link>
                            </Box>

                            <Button
                                primary
                                type='submit'
                                label='Login'
                            />
                        </ControlButtons>
                    </Form>

                    <LoadingOverlay
                        visible={loading}
                    />
                </Box>
            </Box>
        </Modal>
    )
}

import { unovisionClient } from './unovisionClient'
import { CompanyAddressType, ContactType } from './CommonTypes'

const CONTEXT_URL = '/api/account/'

export interface Subscription extends CreateSubscription {
    id: string
}

export type CreateSubscription = ContactType & CompanyAddressType & {
    isWorkWithUnovisionWanted: boolean
    isPlatformLaunchInfoWanted: boolean
    isPrivacyAgreementConfirmed: boolean
}

export const createSubscription = async(createSubscription: CreateSubscription): Promise<Subscription> => {
    const response = await unovisionClient
        .post<Subscription>(CONTEXT_URL + 'subscribe', {createSubscription: createSubscription})
    return response.data
}

import React, { PropsWithChildren } from 'react'
import { Box } from 'grommet'
import { BoxProps } from 'grommet/es6'

export const RoundBox = (props: PropsWithChildren<BoxProps>) => {
    const {children, justify = 'between', ...rest} = props
    return <Box {...rest}
                align={'center'}
                pad={'medium'}
                direction={'row'}
                justify={justify}
                width={'100%'}
                round={'small'}>
        {children}
    </Box>
}

import React, {PropsWithChildren} from 'react'
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom'
import {Grid, Main} from 'grommet'
import {isUnovision} from '../../api/roles'
import {useCurrentUser} from '../../hooks/useCurrentUser'
import {Sidebar} from '../../components/layout/Sidebar'
import {Navigation} from './navigation/Navigation'
import {User} from './user/User'
import {Profile} from './user/Profile'
import {Tenant} from './tenant/Tenant'
import {Campaign} from "./campaign/Campaign";

export const Admin: React.FC = () => {
    const [currentUser] = useCurrentUser()
    const {path} = useRouteMatch()

    const Routes = isUnovision(currentUser) ? AdminRoutes : OperatorRoutes

    return (
        <Grid
            fill
            rows={['auto', 'auto']}
            columns={['200px', 'auto']}
            areas={[
                {name: 'sidebar', start: [0, 0], end: [0, 1]},
                {name: 'main', start: [1, 0], end: [1, 1]},
            ]}>

            <Sidebar gridArea='sidebar'>
                <Navigation/>
            </Sidebar>

            <Main gridArea='main' pad='medium'>
                <Routes path={path}/>
            </Main>
        </Grid>
    )
}

const AdminRoutes: React.FC<{ path: string }> = ({path}) => (
    <OperatorRoutes path={path}>
        <Route exact path={`${path}/users`}>
            <User/>
        </Route>
        <Route exact path={`${path}/tenants`}>
            <Tenant/>
        </Route>
        <Route exact path={`${path}/campaigns`}>
            <Campaign/>
        </Route>
        <Route path={`/admin`}>
            <Redirect to={`/admin/users`}/>
        </Route>
    </OperatorRoutes>
)

const OperatorRoutes: React.FC<PropsWithChildren<{ path: string }>> = ({children, path}) => (
    <Switch>
        <Route exact path={`${path}/profile`}>
            <Profile/>
        </Route>

        {children}

        <Route path={`/admin`}>
            <Redirect to={`admin/profile`}/>
        </Route>
    </Switch>
)

import { Box, Text } from 'grommet'
import React, { PropsWithChildren } from 'react'
import { BoxProps } from 'grommet/es6'

export const ChatBotPreviewBoxChild: React.FC<PropsWithChildren<BoxProps>> = (props: PropsWithChildren<BoxProps>) => {
    return <Box background={'background-chatbot-preview-box'}
                round={'xsmall'}
                pad={'small'}
                color={'text-dark-gray'}>
        <Text style={{whiteSpace: 'pre-line'}}>{props.children}</Text>
    </Box>
}

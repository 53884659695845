import React from 'react'
import { AdDetailContent } from './AdDetailContent'
import { BasePage, PageWithBreadcrumbsProps } from '../../../components/layout/BasePage'
import { AdDetailContextProvider } from './AdDetailContext'

export const AdDetailPage: React.FC<PageWithBreadcrumbsProps> = (props: PageWithBreadcrumbsProps) =>
    <BasePage
        crumbs={props.crumbs} noPad title={'Stellenanzeige'}>
        <AdDetailContextProvider>
            <AdDetailContent/>
        </AdDetailContextProvider>
    </BasePage>

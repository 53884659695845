import React from 'react'
import { GeoTargetListItem } from './GeoTargetListItem'
import { Box } from 'grommet'
import { GeoTargetType } from '../../../api/campaignManagement'

type GeoTargetListContainerProps = {
    geoTargets?: GeoTargetType[]
    onGeoTargetSelectHandler: (geoTarget: GeoTargetType) => void
    onGeoTargetDeleteHandler: (index: number) => void
    geoTargetColors?: any[]
}

export const GeoTargetListContainer = (input: GeoTargetListContainerProps) => {

    return (
        <Box pad='xsmall' direction='column' gap='xsmall' background={'background-back'}>
            {
                input.geoTargets && input.geoTargets.map((location, index) =>
                    <GeoTargetListItem key={index} title={location.givenName} subtitle={location.addressString}
                                       onSelect={() => input.onGeoTargetSelectHandler(location)}
                                       onDeleteClick={() => input.onGeoTargetDeleteHandler(location.index)}
                                       iconColor={input.geoTargetColors && input.geoTargetColors[index]}
                    />
                )
            }
        </Box>
    )
}

import { unovisionClient } from './unovisionClient'

const CONTEXT_MEDIA = '/api/media'

export type MediaDTO = {
    id: string,
    fileName: string,
    url: string,
    mediaType: string
}

export const uploadMedia = async(referredById: number, file: any, handleProgress: (progress: number) => void): Promise<MediaDTO> => {
    const data = new FormData()
    data.append('data', file)

    const config = {
        onUploadProgress: (progressEvent: any) => {
            if(progressEvent.total && progressEvent.loaded) {
                handleProgress(Math.round(100 / progressEvent.total * progressEvent.loaded))
            }
        }
    }
    const result = await unovisionClient.post(`${CONTEXT_MEDIA}/${referredById}`, data, config)
    return result.data
}

export const deleteMedia = async(id: string): Promise<void> => {
    const result = await unovisionClient.delete(`${CONTEXT_MEDIA}/${id}`)
    return result.data
}

export const videosForReferredId = async(referredById: number): Promise<MediaDTO[]> => {
    const result = await unovisionClient.get(`${CONTEXT_MEDIA}/referred-by/${referredById}/videos`)
    return result.data
}

export const imagesForReferredId = async(referredById: number): Promise<MediaDTO[]> => {
    const result = await unovisionClient.get(`${CONTEXT_MEDIA}/referred-by/${referredById}/images`)
    return result.data
}

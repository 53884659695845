import React, { useEffect, useState } from 'react'
import { ContentLayout } from '../../components/layout/ContentLayout'
import { AdsBanner } from './AdsBanner'
import { AdsSidebar } from './AdsSidebar'
import { useCurrentCampaign } from '../../hooks/useCurrentCampaign'
import { Box, ResponsiveContext, Text } from 'grommet'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { SearchInput } from '../../components/inputs/SearchInput'
import { AdListItemContainer } from './AdListItemContainer'
import { BaseButton } from '../../components/buttons/BaseButton'
import { PlusCircle } from 'phosphor-react'
import { unovisionTheme } from '../../theme/theme'
import { AdType, createAd } from '../../api/ads'
import { getAdsByCampaignId } from '../../api/campaigns'

export type AdsFilterType = {
    active?: boolean
    searchTerm?: string
}

export const AdsContent = () => {
    const currentCampaign = useCurrentCampaign()
    const history = useHistory()
    const {url} = useRouteMatch()

    const [allAds, setAllAds] = useState<AdType[]>([])
    const [onlineCounter, setOnlineCounter] = useState(0)

    const [filteredAds, setFilteredAds] = useState<AdType[]>([])
    const [activeFilters, setActiveFilters] = useState<AdsFilterType>({active: undefined, searchTerm: ''})
    const [searchInput, setSearchInput] = useState<string>('')

    useEffect(() => {
        currentCampaign && getAdsByCampaignId(currentCampaign.id).then((adsResponse) => {
            setAllAds(adsResponse.advertisements)
            setFilteredAds(adsResponse.advertisements)
            setOnlineCounter(adsResponse.advertisements.filter((adType: AdType) => adType.status === 'ACTIVE').length)

        })
    }, [currentCampaign])

    const updateFilters = (filters: AdsFilterType, ads: AdType[] = allAds) => {
        let filtersCopy = filters
        if(filtersCopy.active === activeFilters.active) filtersCopy.active = undefined
        let updatedFilters = {...activeFilters, ...filtersCopy}

        const displayedAds: AdType[] = []
        ads.forEach(ad => {
            const isAdActive = ad.status === 'ACTIVE'
            if((updatedFilters.active === undefined || updatedFilters.active === isAdActive) &&
                (updatedFilters.searchTerm === '' || `${ad.title}`
                    .toLowerCase().indexOf(updatedFilters.searchTerm!!.trim().toLowerCase()) > -1)) {
                displayedAds.push(ad)

            }
        })
        setFilteredAds(displayedAds)
        setActiveFilters(updatedFilters)
    }

    const handleSearchChange = (e: any) => {
        const {value} = e.target
        setSearchInput(value)
        updateFilters({searchTerm: value})
    }

    const handleCreateAd = () => {
        currentCampaign && createAd(currentCampaign.id).then(res => {
            const updatedAds = [...allAds]
            updatedAds.push(res)
            setAllAds(updatedAds)
            updateFilters(activeFilters, updatedAds)
        }).catch(err => console.log(err))
    }

    if(!currentCampaign) return null

    return (
        <ContentLayout sidebar={<AdsSidebar activeFilters={activeFilters} updateFilters={updateFilters}/>}
                       banner={<AdsBanner
                           onlineCounter={onlineCounter}
                           offlineCounter={allAds.length - onlineCounter}
                           campaignId={currentCampaign.id}
                       />}>
            <ResponsiveContext.Consumer>
                {screenSize =>
                    <Box gap='small' fill>
                        <Box width={screenSize === 'small' ? '100%' : 'small'}>
                            <SearchInput value={searchInput} onChange={handleSearchChange}/>
                        </Box>
                        <Box width='fit-content' alignSelf='end'>
                            <BaseButton label='Neue Stellenanzeige'
                                        icon={<PlusCircle size={24} color={unovisionTheme.global.colors.brand.light}/>}
                                        onClick={handleCreateAd}
                            />
                        </Box>
                        <Text>Eine Übersicht Ihrer Stellenanzeigen in dieser Anzeigenregion</Text>
                        {filteredAds.map((ad, idx) =>
                            <AdListItemContainer
                                key={idx}
                                ad={ad}
                                onClick={() => history.push(`${url}/${ad.id}`)}/>
                        )}
                    </Box>
                }
            </ResponsiveContext.Consumer>
        </ContentLayout>
    )
}

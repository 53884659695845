import {Modal} from "../../components/layout/Modal";
import {XeniooPlatformComponent} from "./XeniooPlatformComponent";
import {CampaignType} from "../../api/campaigns";

export type Input = {
    campaignId: number
    onClickClose: () => void
}

export const XeniooPlatformModal: React.FC<Input> = (input) => {
    return (
        <Modal onClickClose={input.onClickClose} full={true}>
            <XeniooPlatformComponent campaignId={input.campaignId} navigationSection={"/build"}/>
        </Modal>
    )
}

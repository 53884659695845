import { Box, Button, Grid, ResponsiveContext, Text, TextProps } from 'grommet'
import { Add, AddCircle, Edit, Subtract, Organization } from 'grommet-icons'
import React, { PropsWithChildren } from 'react'
import { BaseButton } from '../../../../components/buttons/BaseButton'
import { RoundDashedBorderBox } from '../../../../components/common/RoundDashedBorderBox'
import { RoundBox } from '../../../../components/common/RoundBox'
import styled from 'styled-components'

export type OptionType = {
    id: number
    name: string
    subTitle?: string
    isEmpty: boolean
    isHighlighted?: boolean
}

type AttachDetachListViewProps = {
    selectedOptionsTitle: string,
    selectedOptionsSubtitle: string,
    selectedOptions: OptionType[],
    handleAttach: (selectedId: number) => void,
    handleDetach: (selectedId: number) => void,
    allOptions: { id: number, name: string, subTitle?: string, isHighlighted?: boolean }[],
    optionsTitle: string,
    optionsSubtitle: string,
    optionAddButtonLabel: string,
    performHighlight?: (selectedId: number) => void,
    highlightColor?: string,
    highlightedOptionDescription?: string,
    handleEdit: (id: any) => void
    handleAdd?: () => void
}

export const NEW_PATH = 'new'
const defaultHighlightColor = 'status-ok'

export const AttachDetachListView = (props: AttachDetachListViewProps) => {

    const highlightColor = props.highlightColor ?? defaultHighlightColor

    const isShowHighlightDescription = (isHighlighted?: boolean): boolean => {
        return isHighlighted!! && props.highlightedOptionDescription !== undefined
    }

    const addNewOptionButton = <Box>
        <BaseButton icon={<AddCircle size={'medium'} color={'brand'}/>}
                    onClick={props.handleAdd}
                    label={<Text color={'brand'}>{props.optionAddButtonLabel}</Text>}
                    alignSelf={'end'}/>
    </Box>

    return <ResponsiveContext.Consumer>
        {screenSize => {
            const isScreenSizeSmall = screenSize === 'small'
            return <Box height={{min: 'auto'}}
                        direction={'column'}
                        gap={'medium'}>
                <RoundDashedBorderBox>
                    <Heading title={props.selectedOptionsTitle} subTitle={props.selectedOptionsSubtitle}
                             isSmallScreen={isScreenSizeSmall}/>
                    {props.selectedOptions && props.selectedOptions.map((data, index) =>
                        data.isEmpty ?
                            <RoundBox key={'empty_' + index}
                                      justify={'center'}
                                      background={'background-back'}>
                                <Text size='large' color={'text-light-gray'}>Noch frei</Text>
                            </RoundBox> :
                            <Box width={'100%'}>
                                {isShowHighlightDescription(data.isHighlighted) && <Text size={'xsmall'} style={{fontStyle: "italic"}}>{props.highlightedOptionDescription}</Text> }
                                <RoundBox key={'selected_' + data.id}
                                          background={ data.isHighlighted ? highlightColor : 'background-back'}>
                                    <OptionsText title={data.name} subTitle={data.subTitle}
                                                 isSmallScreen={isScreenSizeSmall} color={'brand'}/>
                                    <IconContainer isSmallScreen={isScreenSizeSmall}>
                                        <IconButton
                                            icon={<Edit size={isScreenSizeSmall ? '20px' : '14px'}/>}
                                            handleOnClick={() => props.handleEdit(data.id!!)}
                                        />
                                        <IconButton
                                            icon={<Subtract size={isScreenSizeSmall ? '20px' : '14px'}/>}
                                            handleOnClick={() => props.handleDetach(data.id!!)}
                                        />
                                        {
                                            props.performHighlight && <IconButton
                                                icon={<Organization size={isScreenSizeSmall ? '20px' : '14px'}/>}
                                                handleOnClick={() => props.performHighlight!!(data.id!!)}
                                            />
                                        }
                                    </IconContainer>
                                </RoundBox>
                            </Box>)}
                </RoundDashedBorderBox>

                <Box pad={'medium'}
                     align={'center'}
                     gap={'small'}>
                    {isScreenSizeSmall || props.allOptions?.length <= 0 ?
                        <Box direction={'column'} justify={'center'} pad={'small'}>
                            {props.allOptions?.length > 0 && <Heading title={props.optionsTitle}
                                                                      subTitle={props.optionsSubtitle}
                                                                      isSmallScreen={isScreenSizeSmall}/>}
                            {addNewOptionButton}
                        </Box> :
                        <Grid align={'center'}
                              pad={'small'}
                              width={'100%'}
                              columns={['1/4', '1/2', '1/4']}>
                            <Box/>
                            <Heading title={props.optionsTitle} subTitle={props.optionsSubtitle}
                                     isSmallScreen={isScreenSizeSmall}/>
                            {addNewOptionButton}
                        </Grid>}

                    {props.allOptions?.filter(option => !props.selectedOptions.find(o => o.id === option.id)).map(data =>
                        <Box width={'100%'}>
                            {isShowHighlightDescription(data.isHighlighted) && <Text size={'xsmall'} style={{fontStyle: "italic"}}>{props.highlightedOptionDescription}</Text> }
                            <RoundBox key={data.id}
                                      border={'all'}
                                      background={ data.isHighlighted ? highlightColor : ''}>
                                <OptionsText title={data.name} subTitle={data.subTitle} isSmallScreen={isScreenSizeSmall}/>
                                <IconContainer isSmallScreen={isScreenSizeSmall}>
                                    <IconButton
                                        icon={<Edit size={isScreenSizeSmall ? '20px' : '14px'}/>}
                                        handleOnClick={() => props.handleEdit(data.id!!)}
                                    />
                                    <IconButton
                                        icon={<Add size={isScreenSizeSmall ? '20px' : '14px'}/>}
                                        handleOnClick={() => props.handleAttach(data.id!!)}
                                    />
                                    {
                                        props.performHighlight && <IconButton
                                            icon={<Organization size={isScreenSizeSmall ? '20px' : '14px'}/>}
                                            handleOnClick={() => props.performHighlight!!(data.id!!)}
                                        />
                                    }
                                </IconContainer>
                            </RoundBox>
                        </Box>
                    )}
                </Box>
            </Box>
        }}
    </ResponsiveContext.Consumer>
}

const BrandText = ({children, ...rest}: PropsWithChildren<TextProps>) => {
    return <Text {...rest} color='brand' weight='bold'>
        {children}
    </Text>
}

const Heading = ({title, subTitle, isSmallScreen}: { title: string, subTitle: string, isSmallScreen: boolean }) => {
    return <Box align={'center'} margin={{vertical: 'medium'}}>
        <BrandText textAlign={'center'} size={isSmallScreen ? 'large' : 'xlarge'}>{title}</BrandText>
        <BrandText size={isSmallScreen ? 'medium' : 'large'}>{subTitle}</BrandText>
    </Box>
}

const OptionsText = (props: { title: string, subTitle?: string, isSmallScreen: boolean, color?: string }) => {
    const {title, subTitle, isSmallScreen, color} = props
    return <Box direction={isSmallScreen ? 'column' : 'row'}
                gap={isSmallScreen ? 'undefined' : 'small'}
                align={isSmallScreen ? 'start' : 'center'}>
        <Text size={isSmallScreen ? 'large' : 'xlarge'} color={color || undefined}>
            {title}
        </Text>
        {subTitle ? <Text color={color || undefined} size={isSmallScreen ? 'small' : 'large'}>{subTitle}</Text> : null}
    </Box>
}

const IconContainer = ({children, isSmallScreen}: PropsWithChildren<{ isSmallScreen: boolean }>) => {
    return <Box direction={'row'}
                gap={isSmallScreen ? 'medium' : 'small'}>
        {children}
    </Box>
}

const IconButton = ({icon, handleOnClick}: { icon: JSX.Element, handleOnClick: () => void }) => {
    return <StyledIconButton
        plain={false}
        icon={icon}
        color={'dark-1'}
        onClick={handleOnClick}
    />
}

const StyledIconButton = styled(Button)`
    padding: 7px;
    border: 1px solid;
    border-radius: 28px;
`

import { AdModel } from '../../../../models/AdModel'
import React, { useCallback } from 'react'
import { useAdDetailContext } from '../AdDetailContext'
import { useCampaignJobs } from '../../../../hooks/useCampaignJobs'
import { AttachDetachListViewRouter } from './AttachDetachListViewRouter'

const MAX_NUMBER_OF_JOBS = 3

export const AdDetailJobs = ({currentAd}: { currentAd: AdModel }) => {
    const {allCampaignJobs} = useCampaignJobs()
    const {updateCurrentAdJobs} = useAdDetailContext()

    const handleAttach = useCallback((jobId: number) => {
        if(!currentAd.jobs.find(value => value.id === jobId) && currentAd.jobs.length < MAX_NUMBER_OF_JOBS) {
            const job = allCampaignJobs?.find(j => j.id === jobId)
            if(job) {
                currentAd.jobs.push(job)
                updateCurrentAdJobs(currentAd.jobs)
            }
        }
    }, [allCampaignJobs, currentAd.jobs, updateCurrentAdJobs])

    const handleDetach = useCallback((jobId: number) => {
        currentAd.jobs = currentAd.jobs.filter(j => j.id !== jobId)
        updateCurrentAdJobs(currentAd.jobs)
    }, [currentAd, updateCurrentAdJobs])

    const selectedJobs = currentAd.jobs.map(job => {
        return {id: job.id, name: job.name, isEmpty: false}
    })

    const allOptions = allCampaignJobs?.map(j => {
        return {id: j.id, name: j.name}
    }) || []

    return <AttachDetachListViewRouter
        selectedOptionsTitle={currentAd.jobs?.length + '/' + MAX_NUMBER_OF_JOBS + ' Aktive Jobs,'}
        selectedOptionsSubtitle={'die in der Anzeige beworben werden'}
        selectedOptions={Object.assign([{isEmpty: true}, {isEmpty: true}, {isEmpty: true}], selectedJobs)}
        handleAttach={handleAttach}
        handleDetach={handleDetach}
        allOptions={allOptions}
        optionsTitle={'Verfügbare Jobs,'}
        optionsSubtitle={'die beworben werden können'}
        optionAddButtonLabel={'Neuen Job hinzufügen'}/>
}

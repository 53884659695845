import React, { ReactNode, useState } from 'react'
import { Box, Form } from 'grommet'
import { SubscribeOptionsStep } from './steps/SubscribeOptionsStep'
import { SubscribeCompanyStep } from './steps/SubscribeCompanyStep'
import { SubscribeSuccessStep } from './steps/SubscribeSuccessStep'
import { SubscribeContactStep } from './steps/SubscribeContactStep'
import { capitalize } from '../../../helpers/capitalize'
import { ErrorBadge } from '../../../components/layout/ErrorBadge'
import { createSubscription } from '../../../api/subscribe'

export type SubscribePayload = {
    companyName: string,
    street: string,
    streetNumber: string,
    addressAddition: string,
    city: string,
    postcode: string,
    contactFirstname: string,
    contactLastname: string,
    contactEmail: string,
    contactPhone: string,
    isPlatformLaunchInfoWanted: boolean,
    isWorkWithUnovisionWanted: boolean,
    isPrivacyAgreementConfirmed: boolean
}

export enum SUBSCRIBE_STEPS {
    OPTIONS,
    COMPANY,
    CONTACT,
    SUCCESS
}

export type SubscribeStepsType =
    SUBSCRIBE_STEPS.OPTIONS
    | SUBSCRIBE_STEPS.COMPANY
    | SUBSCRIBE_STEPS.CONTACT
    | SUBSCRIBE_STEPS.SUCCESS


export const Subscribe: React.FC = () => {

    let prefillPayload: SubscribePayload | null = null

    // Extracting prefill Data
    const params = new URLSearchParams(window.location.search)
    const prefillBase64 = params.get('prefill')
    if(prefillBase64) {
        try {
            prefillPayload = JSON.parse(Buffer.from(prefillBase64, 'base64').toString('utf8'))
        } catch {
            console.error('Wrong prefill query data received')
        }
    }

    const initialData: SubscribePayload = {
        companyName: prefillPayload?.companyName || '',
        street: prefillPayload?.street || '',
        streetNumber: prefillPayload?.streetNumber || '',
        addressAddition: '',
        postcode: prefillPayload?.postcode || '',
        city: prefillPayload?.city || '',
        contactFirstname: '',
        contactLastname: '',
        contactEmail: prefillPayload?.contactEmail || '',
        contactPhone: '',
        isWorkWithUnovisionWanted: true,
        isPlatformLaunchInfoWanted: true,
        isPrivacyAgreementConfirmed: false
    }

    const [input, setInput] = useState<SubscribePayload>(initialData)
    const [currentStep, setCurrentStep] = useState<SubscribeStepsType>(SUBSCRIBE_STEPS.OPTIONS)
    const [error, setError] = useState<ReactNode | null>(null)

    const onSubmit = (values: SubscribePayload) => {
        //Cleaning up User Input
        const submitFormValues = {
            companyName: values.companyName.trim(),
            street: capitalize(values.street.trim()),
            streetNumber: values.streetNumber.trim(),
            addressAddition: capitalize(values.addressAddition.trim()),
            city: capitalize(values.city.trim()),
            postcode: values.postcode.trim(),
            contactFirstname: capitalize(values.contactFirstname.trim()),
            contactLastname: capitalize(values.contactLastname.trim()),
            contactEmail: values.contactEmail.trim().toLowerCase(),
            contactPhone: values.contactPhone.trim(),
            isWorkWithUnovisionWanted: values.isWorkWithUnovisionWanted,
            isPlatformLaunchInfoWanted: values.isPlatformLaunchInfoWanted,
            isPrivacyAgreementConfirmed: values.isPrivacyAgreementConfirmed
        }
        createSubscription(submitFormValues).then((response) => {
                setError(null)
                return setCurrentStep(SUBSCRIBE_STEPS.SUCCESS)
            },
            (error) => {
                switch(error.data.errorCode) {
                    case 'NO_PRIVACY_AGREEMENT':
                        return setError(<ErrorBadge
                            label='Der Benutzer muss die AGBs und Datenschutzbedingungen akzeptieren.'/>)
                    case 'SUBSCRIPTION_EMAIL_FORMAT_ERROR':
                        return setError(<ErrorBadge label='Die eingetragene Emailaddresse hat ein ungültiges Format.'/>)
                    case 'EXISTING_SUBSCRIPTION_EMAIL':
                        return setError(<ErrorBadge
                            label='Mit dieser Emailaddresse wurde bereits eine Anmeldung durchgeführt.'/>)
                    default:
                        return console.error('Undefined Response: ', error.code)
                }
            })
    }

    const getCurrentFormStep = () => {
        switch(currentStep) {
            case SUBSCRIBE_STEPS.OPTIONS:
                return <SubscribeOptionsStep input={input} stepperCb={setCurrentStep}/>
            case SUBSCRIBE_STEPS.COMPANY:
                return <SubscribeCompanyStep input={input} stepperCb={setCurrentStep}/>
            case SUBSCRIBE_STEPS.CONTACT:
                return <SubscribeContactStep input={input} stepperCb={setCurrentStep}/>
            case SUBSCRIBE_STEPS.SUCCESS:
                return <SubscribeSuccessStep/>
            default:
                return <SubscribeOptionsStep input={input} stepperCb={setCurrentStep}/>
        }
    }

    return (
        <Form<SubscribePayload>
            value={input}
            validate='blur'
            onChange={(nextValue: any) => setInput(nextValue)}
            onSubmit={({value}) => onSubmit(value)}
        >
            {getCurrentFormStep()}
            {error}
            <Box height='xxsmall'/>
        </Form>
    )
}

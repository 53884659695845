import React, {useEffect, useState} from 'react'
import {Box, Heading} from 'grommet'
import {BaseButton} from '../../components/buttons/BaseButton'
import {LoadingOverlay} from '../../components/layout/LoadingOverlay'
import {useHistory} from 'react-router-dom'
import {ClientModel} from '../../models/ClientModel'
import {getAllClientsAsSuperuser, getMyClients} from '../../api/tenant'
import {LoginModal} from "../auth/login/LoginModal";
import {useCurrentUser} from "../../hooks/useCurrentUser";
import {isUnovision} from "../../api/roles";

export const SelectClientPage: React.FC = () => {
    const [clients, setClients] = useState<ClientModel[] | undefined>(undefined)
    const [currentUser, setCurrentUser, userAccessToken] = useCurrentUser()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [error,setError] = useState<any>()

    const [selectedClient, setSelectedClient] = useState<ClientModel | undefined>(undefined)

    const history = useHistory()

    useEffect(() => {
        loadClients()
            .catch(error => setError(error))
            .finally(() => setIsLoading(false))
    }, [])

    const loadClients = async() => {
        let clients = undefined;
        if (isUnovision(currentUser)) {
            clients = await getAllClientsAsSuperuser()
        } else {
            clients  = await getMyClients()
        }

        if (clients.length === 1) {
            selectClient(clients[0])
        } else {
            setClients(clients)
        }
    }

    const selectClient = async (client: ClientModel) => {
        setSelectedClient(client)
    }

    //TODO Quick and dirty Fix. Make it nice
    const renderAdminViewButton = () => {
        return (
            <Box>
                <BaseButton label="Admin View" onClick={() => history.push('/admin')} alignSelf={"end"} margin={{top: "small", right: "small"}}/>
            </Box>
        )
    }

    if(selectedClient) return <LoginModal onSuccess={() => {history.push("/select-campaign")}} clientId={selectedClient.id}/>

    if(isLoading) return <LoadingOverlay visible/>

    if(clients && clients.length === 0) {
        return (
            <>
                {isUnovision(currentUser) && renderAdminViewButton()}
                <Box fill justify='center' align='center'>
                    <Heading level='3'>Dieser Nutzer hat keine Klienten</Heading>
                </Box>
            </>
        )
    }

    return (
        <Box fill justify='center' align='center'>
            <Heading level='3'>Wählen Sie Ihren Klienten</Heading>
            <Box direction='column' gap='medium'>
                {clients!.map((client, id) =>
                    <Box key={id} gap='medium' align='center'>
                        <BaseButton primary
                                    label={client.name}
                                    onClick={() => {
                                        selectClient(client)
                                    }}/>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

import { ContentLayout } from '../../../components/layout/ContentLayout'
import { Box } from 'grommet'
import { LoadingOverlay } from '../../../components/layout/LoadingOverlay'
import { AdDetailBanner } from './AdDetailBanner'
import { AdDetailSidebar } from './AdDetailSidebar'
import { useAdDetailContext } from './AdDetailContext'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { AdDetailTextEdit } from './content/AdDetailTextEdit'
import { AdDetailFacebookPreview } from './content/AdDetailFacebookPreview'
import { AdDetailVideos } from './content/AdDetailVideos'
import { AdDetailImages } from './content/AdDetailImages'
import { AdDetailJobs } from './content/AdDetailJobs'
import { AdDetailJobEdit } from './content/AdDetailJobEdit'
import { AdDetailSubsidiaries } from './content/AdDetailSubsidiaries'
import { DetailSubsidiaryEditPage } from '../../dashboard/DetailSubsidiaryEditPage'

export const AdDetailContent = () => {
    const {currentAd} = useAdDetailContext()
    let {path} = useRouteMatch()

    return <>
        {currentAd && <ContentLayout sidebar={<AdDetailSidebar/>}
                                     banner={<AdDetailBanner currentAd={currentAd}/>}>
            <Box fill>
                <Switch>
                    <Route exact path={path}>
                        <AdDetailFacebookPreview currentAd={currentAd}/>
                    </Route>
                    <Route exact path={`${path}/jobs`}>
                        <AdDetailJobs currentAd={currentAd}/>
                    </Route>
                    <Route exact path={`${path}/jobs/:jobId`}>
                        <AdDetailJobEdit currentAd={currentAd}/>
                    </Route>
                    <Route exact path={`${path}/subsidiaries/`}>
                        <AdDetailSubsidiaries currentAd={currentAd}/>
                    </Route>
                    <Route exact path={`${path}/subsidiaries/:subsidiaryId`}>
                        <DetailSubsidiaryEditPage currentAd={currentAd}/>
                    </Route>
                    <Route path={`${path}/edit-title`}>
                        <AdDetailTextEdit currentAd={currentAd}/>
                    </Route>
                    <Route path={`${path}/edit-image`}>
                        <AdDetailImages currentAd={currentAd}/>
                    </Route>
                    <Route path={`${path}/edit-video`}>
                        <AdDetailVideos currentAd={currentAd}/>
                    </Route>
                    <Route path={`${path}/edit-text`}>
                        <AdDetailTextEdit currentAd={currentAd}/>
                    </Route>
                </Switch>
            </Box>
        </ContentLayout>}
        <LoadingOverlay visible={false}/>
    </>
}

import React, { useEffect, useState } from 'react'
import { BasePage } from '../../components/layout/BasePage'
import { Box } from 'grommet'
import { DataPrivacyMarkdown } from '../dashboard/dataPrivacy/markdown/DataPrivacyMarkdown'
import { SelectedDataPrivacyLawType } from '../dashboard/dataPrivacy/DataPrivacyContent'
import { useHistory, useParams } from 'react-router-dom'
import {
    getPublicDataPrivacy
} from '../../api/campaignManagement'
import { AddressModel } from '../../models/AddressModel'
import { ContactModel } from '../../models/ContactModel'
import { JobModel } from '../../models/JobModel'
import { LoadingOverlay } from '../../components/layout/LoadingOverlay'

export const PublicDataPrivacyPage: React.FC = () => {
    let params = useParams<{ dataPrivacyId: string }>()
    const dataPrivacyId = params.dataPrivacyId
    const history = useHistory()

    const [dataPrivacyLaw, setDataPrivacyLaw] = useState<SelectedDataPrivacyLawType>('DSGVO_AV')
    const [subsidiaries, setSubsidiaries] = useState<AddressModel[]>([])
    const [dataPrivacyOfficers, setDataPrivacyOfficers] = useState<ContactModel[]>([])
    const [jobs, setJobs] = useState<JobModel[]>([])
    const [updatedTimestamp, setUpdatedTimestamp] = useState<Date>()
    const [campaignMainAddresses, setCampaignMainAddresses] = useState<AddressModel[] | undefined>(undefined)
    const [dataProcessingDescription, setDataProcessingDescription] = useState<string | undefined>(undefined)

    const [isLoaded, setIsLoaded] = useState(false)

    // Loads Data from BE and initializes the states
    useEffect(() => {
        if(dataPrivacyId) {
            getPublicDataPrivacy(dataPrivacyId).then(res => {
                if(res.dataPrivacyDTO) {
                    setDataPrivacyLaw(res.dataPrivacyDTO.selectedDataPrivacyLaw as SelectedDataPrivacyLawType)
                    setSubsidiaries(res.dataPrivacyDTO.subsidiaries || [])
                    setDataPrivacyOfficers(res.dataPrivacyDTO.dataPrivacyOfficers || [])
                    setDataProcessingDescription(res.dataPrivacyDTO.dataProcessingDescription)
                    setJobs(res.jobList)
                    setUpdatedTimestamp(res.dataPrivacyDTO.updatedDate)
                    setCampaignMainAddresses(res.dataPrivacyDTO.campaignMainAddresses)
                    setIsLoaded(true)
                } else {
                    console.log("Requested data privacy not found: ", dataPrivacyId)
                    history.push('/')
                }
            }).catch((err) => {
                    console.error(err)
                    history.push('/')
                }
            )
        }
    }, [dataPrivacyId, history])

    return (<>
            {isLoaded &&
            <BasePage title={'Datenschutzerklärung'}>
                <Box align='center'>
                    <Box width='large'>
                        <DataPrivacyMarkdown
                            selectedDataPrivacyLaw={dataPrivacyLaw}
                            dataPrivacyOfficers={dataPrivacyOfficers}
                            subsidiaries={subsidiaries}
                            jobList={jobs}
                            dataProcessingDescription={dataProcessingDescription}
                            updatedDate={updatedTimestamp}
                            campaignMainAddresses={campaignMainAddresses}
                        />
                    </Box>
                </Box>
            </BasePage>}
            <LoadingOverlay
                visible={!isLoaded}
            />
        </>
    )
}

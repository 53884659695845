import { Box, Form, FormField, Heading, Paragraph, TextInput } from 'grommet'
import * as React from 'react'
import { useCallback, useState } from 'react'
import { Modal, ModalContent, ModalHeading, ModalProps } from '../../../../components/layout/Modal'
import { required, validateEmail, validatePhone } from '../../../../components/inputs/validation'
import { ContactModel, FormContactModel, toContacts, toFormContacts } from '../../../../models/ContactModel'
import { DataPrivacyOfficerModalList } from './DataPrivacyOfficerModalList'
import { BaseButton } from '../../../../components/buttons/BaseButton'

type DataPrivacyOfficerModalProps = ModalProps & {
    campaignName: string,
    dataPrivacyOfficers?: ContactModel[],
    setDataPrivacyOfficers: (value: ContactModel[]) => void
}

export const DataPrivacyOfficerModal: React.FC<DataPrivacyOfficerModalProps> = ({
                                                                                    campaignName,
                                                                                    dataPrivacyOfficers,
                                                                                    setDataPrivacyOfficers,
                                                                                    ...rest
                                                                                }) => {

    // Mapping to flat hierarchy necessary - Grommet-Form doesn't support nested form values yet
    const formDataPrivacyOfficers = toFormContacts(dataPrivacyOfficers)
    const setFormDataPrivacyOfficers = useCallback(
        (formContacts: FormContactModel[]) => {
            setDataPrivacyOfficers(toContacts(formContacts))
        },
        [setDataPrivacyOfficers],
    )

    const initialData: FormContactModel = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        companyName: '',
        street: '',
        streetNumber: '',
        addressAddition: '',
        postcode: '',
        city: ''
    }

    const [input, setInput] = useState<FormContactModel>(initialData)
    const [isEdit, setIsEdit] = useState(false)
    const [valid, setValid] = useState(true)

    const onEditClicked = useCallback((edit: FormContactModel) => {
        document.getElementById('form-start')?.scrollIntoView({behavior: 'smooth'})
        setIsEdit(true)
        setInput(edit)
    }, [])

    const onRemoveClicked = useCallback((value: FormContactModel) => {
        const indexToRemove = formDataPrivacyOfficers.indexOf(value)
        setFormDataPrivacyOfficers(formDataPrivacyOfficers.filter((value, index) => {
            return indexToRemove !== index
        }))
    }, [formDataPrivacyOfficers, setFormDataPrivacyOfficers])

    const onSubmit = () => {
        if(isEdit) {
            // Update officer
            const officerIndex = formDataPrivacyOfficers.findIndex(officer => officer.contactId === input.contactId)
            let updatedOfficers = formDataPrivacyOfficers.slice(0)
            updatedOfficers[officerIndex] = input
            setFormDataPrivacyOfficers(updatedOfficers)
            setIsEdit(false)
        } else {
            // Add officer
            setFormDataPrivacyOfficers(formDataPrivacyOfficers.concat([input]))
        }
        setInput(initialData)
    }

    return (
        <Modal {...rest}>
            <ModalHeading>
                <Heading level={4}>Datenschutzbeauftragte benennen in Anzeigen-Region {campaignName}</Heading>
            </ModalHeading>

            <ModalContent>
                <Paragraph color={'accent-1'}>Trage <strong>alle Datenschutzbeauftragte</strong>,
                    deiner Anzeigen-Region hier ein</Paragraph>
                <Form<FormContactModel>
                    value={input}
                    validate='change'
                    onValidate={validationResults => {
                        setValid(validationResults.valid)
                    }}
                    onSubmit={onSubmit}
                    onChange={(nextValue: FormContactModel) => {
                        setInput(nextValue)
                    }}>
                    <Box>
                        <Heading level={4} id='form-start'>Name & Kontakdaten</Heading>
                        <Box direction={'row'}
                             gap='small'>
                            <FormField
                                name='firstname'
                                label='Vorname*'
                                validate={required}
                                width={'50%'}
                            >
                                <TextInput name='firstname'/>
                            </FormField>
                            <FormField
                                name='lastname'
                                label='Nachname*'
                                validate={required}
                                width={'50%'}
                            >
                                <TextInput name='lastname'/>
                            </FormField>
                        </Box>
                        <FormField
                            name='email'
                            label='Emailaddresse*'
                            validate={required.concat(validateEmail as any)}
                        />
                        <FormField
                            name='phone'
                            label='Telefonnummer*'
                            validate={required.concat(validatePhone as any)}
                        />
                    </Box>
                    <Box>
                        <Heading level={4}>Anschrift</Heading>
                        <FormField
                            name='companyName'
                            label='Unternehmensname'
                        />
                        <Box
                            direction='row'
                            gap='small'
                            justify='between'
                        >
                            <FormField
                                name='street'
                                label='Straße*'
                                width='large'
                                validate={required}
                            >
                                <TextInput name='street'/>
                            </FormField>
                            <FormField
                                name='streetNumber'
                                label='Hausnummer*'
                                validate={required}
                            >
                                <TextInput name='streetNumber'/>
                            </FormField>
                        </Box>
                        <FormField
                            name='addressAddition'
                            label='Adresszusatz'
                        />
                        <FormField
                            name='postcode'
                            label='Postleitzahl*'
                            validate={required}
                        />
                        <FormField
                            name='city'
                            label='Stadt*'
                            validate={required}
                        />
                    </Box>
                    <Box direction={'row'} width={'100%'} justify={'end'}>
                        <BaseButton primary
                                    label={isEdit ? 'Änderung übernehmen' : 'Kontakt hinzufügen'}
                                    disabled={!valid}
                                    type={'submit'}/>
                    </Box>
                </Form>
                <DataPrivacyOfficerModalList dataPrivacyOfficers={formDataPrivacyOfficers}
                                             onEditClicked={onEditClicked}
                                             onRemoveClicked={onRemoveClicked}/>
            </ModalContent>
        </Modal>
    )
}
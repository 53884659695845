import { Box, Text } from 'grommet'
import { Heading } from '../text/Heading'
import React from 'react'
import { BoxProps } from 'grommet/es6'
import { Checkmark } from '../../resources/icons/svg'
import styled from 'styled-components'

export type CheckmarkClueListBoxProps = BoxProps & {
    clueList: string[],
    title: string,
    subTitle: string
}

export const CheckmarkClueListBox: React.FC<CheckmarkClueListBoxProps> = (props: CheckmarkClueListBoxProps) => {
    return <Box {...props}
                width={'medium'}
                direction={'column'}
                background={'background-highlight-box'}
                round={'xsmall'}
                gap={'xsmall'}
                pad={{horizontal: 'medium', vertical: 'small'}}>
        <TipsHeading level={4}>{props.title}</TipsHeading>

        <Text size={'small'} color={'text-info'}>
            {props.subTitle}
        </Text>

        <Box gap={'small'}>
            {props.clueList.map((tip, index) =>
                <Box key={index}
                     pad={{horizontal: '15px', vertical: '10px'}}
                     background={'background'}
                     direction={'row'}
                     gap={'small'}
                     align={'center'}>
                    <Checkmark color={'status-ok'}/>
                    <Text size={'small'}
                          weight={'bold'}
                          color={'text-info'}>
                        {tip}
                    </Text>
                </Box>)}
        </Box>
    </Box>
}

const TipsHeading = styled(Heading)`
    color: ${(props) => props.theme.global.colors['text-info']};
`

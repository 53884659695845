import { AdModel } from '../../../../models/AdModel'
import { Box, Form, FormField, Text, TextArea, TextInput, Grid } from 'grommet'
import { useHistory, useParams } from 'react-router-dom'
import { getJobDescription, JobDescriptionType } from '../../../../api/jobs'
import React, { useCallback, useEffect, useState } from 'react'
import { JobModel } from '../../../../models/JobModel'
import { required } from '../../../../components/inputs/validation'
import { BaseButton } from '../../../../components/buttons/BaseButton'
import { useAdDetailContext } from '../AdDetailContext'
import { NEW_PATH } from './AttachDetachListView'
import {ChatBotPreviewBoxChild} from "../../../../components/common/ChatBotPreviewBoxChild";
import {ChatBotPreviewBox} from "../../../../components/common/ChatBotPreviewBox";

export const JOB_NAME_MAX_LENGTH = 25
export const JOB_DESCRIPTION_MAX_LENGTH = 300

export const AdDetailJobEdit = ({currentAd}: { currentAd: AdModel }) => {
    const history = useHistory()
    const {availableJobs, saveJobViaContext} = useAdDetailContext()
    const {jobId} = useParams<{ jobId: string }>()
    const [jobDescriptionList, setJobDescriptionList] = useState<JobDescriptionType[]>([{text: ''}, {text: ''}, {text: ''}, {text: ''}])
    const [job, setJob] = useState<JobModel>({name: ''})
    const [isNew, setIsNew] = useState(true)

    const handleJobTitleChanged = useCallback((event) => {
        job.name = event.target.value
        setJob({...job})
    }, [job])

    const handleJobDescriptionChanged = useCallback((event, idx) => {
        const newItems = jobDescriptionList.slice()
        newItems[idx].text = event.target.value
        setJobDescriptionList(newItems)
    }, [jobDescriptionList])

    useEffect(() => {
        if(jobId !== NEW_PATH) {
            setIsNew(false)
            const job = availableJobs && availableJobs.find(j => j.id === +jobId)
            if(job) {
                getJobDescription(job.id).then(setJobDescriptionList)
                setJob(job)
            }
        }
    }, [currentAd.jobs, jobId, availableJobs])

    const onSubmit = () => {
        saveJobViaContext(isNew, job, jobDescriptionList)
        history.goBack()
    }

    return (
        <Grid
              columns={['2fr', '1fr']}
              rows={['flex']}
              justifyContent={'center'}
              gap={'large'}>
            <Form validate={'submit'}
                  onSubmit={onSubmit}>
                <FormField name={'jobTitle'}
                           label={'Klick mich zum editieren'}
                           validate={required}
                           info={<Text alignSelf={'end'}>{job.name.length} / {JOB_NAME_MAX_LENGTH}</Text>}>
                    <TextInput
                        name={'jobTitle'}
                        maxLength={JOB_NAME_MAX_LENGTH}
                        placeholder='Jobtitel hier eintragen'
                        size={'xlarge'}
                        value={job.name}
                        onChange={handleJobTitleChanged}
                        autoFocus
                    />
                </FormField>
                {jobDescriptionList && jobDescriptionList.map((jDescription, idx) => (
                    <FormField name={'jobDescription_' + idx}
                               label={'Klick mich zum editieren'}
                               validate={required}
                               info={<Text alignSelf={'end'}>{jDescription.text.length} / {JOB_DESCRIPTION_MAX_LENGTH}</Text>}>
                        <TextArea
                            fill
                            plain
                            maxLength={JOB_DESCRIPTION_MAX_LENGTH}
                            name={'jobDescription_' + idx}
                            placeholder={'Jobbeschreibung hier eintragen'}
                            size={'large'}
                            resize={false}
                            value={jDescription.text}
                            onChange={event => handleJobDescriptionChanged(event, idx)}
                        />
                    </FormField>
                ))}
                <Box direction={'row'} width={'100%'} justify={'end'}>
                    <BaseButton primary
                                label={isNew ? 'Job hinzufügen' : 'Änderung übernehmen'}
                                type={'submit'}/>
                </Box>
            </Form>
            <ChatBotPreviewBox>
                {jobDescriptionList.map((item, index) => {
                    if (item.text === "") return null
                    return <ChatBotPreviewBoxChild key={index}>
                        {item.text}
                    </ChatBotPreviewBoxChild>
                })}
            </ChatBotPreviewBox>
        </Grid>
    )
}

import React, { useEffect, useState } from 'react'
import { Box, Tab, Tabs, } from 'grommet'
import { ContentLayout } from '../../components/layout/ContentLayout'
import { JobApplicationsSidebar } from './JobApplicationsSidebar'
import { JobApplicationsBanner } from './JobApplicationsBanner'
import { JobApplicationListContainer } from './JobApplicationListContainer'
import {
    JOB_APPLICATION_STATUS_DETAILS,
    JobApplicationStatusType
} from './JobApplicationStatus'
import {
    JobApplicationType,
} from '../../api/jobApplications'
import { JobType } from '../../api/jobs'
import { LoadingOverlay } from '../../components/layout/LoadingOverlay'
import { RichTabTitle } from '../../components/tabs/RichTabTitle'
import { SearchInput } from '../../components/inputs/SearchInput'
import { useCurrentCampaign } from '../../hooks/useCurrentCampaign'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useJobApplications, useUpdateJobApplications } from '../../hooks/useJobApplications'

export type JobApplicationCounterType = { total: number, reaction: number, waiting: number, finished: number }

export type JobApplicationFilterType = {
    searchTerm?: string,
    favorite?: boolean,
    job?: JobType,
    status?: JobApplicationStatusType
}

export const JobApplicationsContent = () => {

    const history = useHistory()
    const {url} = useRouteMatch()
    const currentCampaign = useCurrentCampaign()
    const jobApplications = useJobApplications()
    const updateJobApplications = useUpdateJobApplications()

    const [reactionJobApplications, setReactionJobApplications] = useState<JobApplicationType[]>([])
    const [waitingJobApplications, setWaitingJobApplications] = useState<JobApplicationType[]>([])
    const [finishedJobApplications, setFinishedJobApplications] = useState<JobApplicationType[]>([])

    const [searchInput, setSearchInput] = useState<string>('')
    const [jobApplicationsCounter, setJobApplicationsCounter] = useState<JobApplicationCounterType>({
        total: 0,
        reaction: 0,
        waiting: 0,
        finished: 0
    })
    const [isLoaded, setIsLoaded] = useState(false)
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0)

    const [activeJobApplicationFilters, setActiveJobApplicationFilters] = useState<JobApplicationFilterType>({
        searchTerm: '',
        favorite: false
    })

    useEffect(() => {
        updateJobApplications()
        // eslint-disable-next-line
    }, [currentCampaign])

    useEffect(() => {
        setJobApplicationsCounter(organizeAndFilterJobApplications(jobApplications, activeJobApplicationFilters))
        setIsLoaded(true)
        // eslint-disable-next-line
    }, [jobApplications])

    const handleVisitApplicantDetail = (jobApplicationId: string) => {
        history.push(`${url}/${jobApplicationId}`)
    }

    const updateFilters = (props: JobApplicationFilterType) => {
        let filters = props
        if(props.job === activeJobApplicationFilters.job) filters.job = undefined
        if(props.status === activeJobApplicationFilters.status) filters.status = undefined
        const updatedFilter = {...activeJobApplicationFilters, ...filters}
        organizeAndFilterJobApplications(jobApplications, updatedFilter)

        setActiveJobApplicationFilters(updatedFilter)
    }

    const organizeAndFilterJobApplications = (jobApplications: JobApplicationType[], filter: JobApplicationFilterType): JobApplicationCounterType => {
        const reactionJobApplications: JobApplicationType[] = []
        const waitingJobApplications: JobApplicationType[] = []
        const finishedJobApplications: JobApplicationType[] = []

        jobApplications.forEach((jobApplication) => {
            if((filter.job === undefined || filter.job.id === jobApplication.job?.id) &&
                (filter.status === undefined || filter.status === jobApplication.applicationStatus) &&
                (!filter.favorite || jobApplication.isFavorite) &&
                (filter.searchTerm === '' || `${jobApplication.applicant.firstName} ${jobApplication.applicant.lastName}`
                    .toLowerCase().indexOf(filter.searchTerm!!.trim().toLowerCase()) > -1)) {
                if(JOB_APPLICATION_STATUS_DETAILS[jobApplication.applicationStatus].tray === 'REACTION') {
                    reactionJobApplications.push(jobApplication)
                } else if(JOB_APPLICATION_STATUS_DETAILS[jobApplication.applicationStatus].tray === 'WAITING') {
                    waitingJobApplications.push(jobApplication)
                } else if(JOB_APPLICATION_STATUS_DETAILS[jobApplication.applicationStatus].tray === 'FINISHED') {
                    finishedJobApplications.push(jobApplication)
                }
            }
        })

        setReactionJobApplications(reactionJobApplications)
        setWaitingJobApplications(waitingJobApplications)
        setFinishedJobApplications(finishedJobApplications)

        return {
            total: jobApplications.length,
            reaction: reactionJobApplications.length,
            waiting: waitingJobApplications.length,
            finished: finishedJobApplications.length
        }
    }

    const onActivateTab = (nextIndex: number) => setActiveTabIndex(nextIndex)

    const handleSearchChange = (e: any) => {
        const {value} = e.target
        setSearchInput(value)
        updateFilters({searchTerm: value})
    }

    return <>
        {isLoaded && <ContentLayout sidebar={<JobApplicationsSidebar activeFilters={activeJobApplicationFilters}
                                                                     updateFilters={updateFilters}/>}
                                    banner={<JobApplicationsBanner counter={jobApplicationsCounter}/>}>

            <Box fill gap='medium'>
                <Box width='small'>
                    <SearchInput
                        value={searchInput}
                        onChange={handleSearchChange}
                    />
                </Box>
                <Tabs onActive={onActivateTab} alignControls='start'>
                    <Tab title={
                        <RichTabTitle
                            label='Reaktion erforderlich'
                            isActive={activeTabIndex === 0}
                            counter={reactionJobApplications.length}
                            badge={reactionJobApplications.length > 0}/>}>
                        <JobApplicationListContainer
                            organizedJobApplications={reactionJobApplications}
                            handleVisitApplicantDetail={handleVisitApplicantDetail}
                        />
                    </Tab>
                    <Tab title={
                        <RichTabTitle
                            label='Warten auf Bewerber'
                            isActive={activeTabIndex === 1}
                            counter={waitingJobApplications.length}
                        />}>
                        <JobApplicationListContainer
                            organizedJobApplications={waitingJobApplications}
                            handleVisitApplicantDetail={handleVisitApplicantDetail}
                        />
                    </Tab>
                    <Tab title={
                        <RichTabTitle
                            label='Bewerbung abgeschlossen'
                            isActive={activeTabIndex === 2}
                            counter={finishedJobApplications.length}
                        />}>
                        <JobApplicationListContainer
                            organizedJobApplications={finishedJobApplications}
                            handleVisitApplicantDetail={handleVisitApplicantDetail}
                        />
                    </Tab>
                </Tabs>
            </Box>
        </ContentLayout>}
        <LoadingOverlay visible={!isLoaded}/>
    </>
}

import { Box, Button, Table, TableBody, TableCell, TableHeader, TableRow } from 'grommet'
import React, { useCallback, useEffect, useState } from 'react'
import { getAllUsers, UserType } from '../../../api/users'
import { UnauthorizedException } from '../../../api/UnauthorizedException'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { UserDialog } from './UserDialog'
import { UserRow } from './UserRow'
import {getMyClients, getClientUserRelation, TenantType, getAllClientsAsSuperuser} from "../../../api/tenant";

export const User = () => {
    const [backofficeUsers, setBackofficeUsers] = useState<UserType[] | null>(null)
    const setCurrentUser = useCurrentUser()[1]
    const [allClients, setAllClients] = useState<TenantType[]>([])


    const reloadConfigs = useCallback(async() => {

        try {
            const allUsers = await getAllUsers();
            let allClientsResponse = await getAllClientsAsSuperuser();
            let clientUserRelation = await getClientUserRelation();

            // populate clientId for each user
            allUsers.forEach(user => {
                    let matchedRelation = clientUserRelation.find(value => value.id.username === user.username);
                    if(matchedRelation) user.clientId = matchedRelation.id.clientId
                });

            setBackofficeUsers(allUsers);
            setAllClients(allClientsResponse);
        } catch(e) {
            if(e instanceof UnauthorizedException) {
                setCurrentUser(null)
            }
            console.log("Unknown error", e)
        }
    }, [setBackofficeUsers, setCurrentUser])

    useEffect(() => {
        reloadConfigs()
    }, [reloadConfigs])

    return (
        <>
            <h1>Benutzer</h1>
            <UserDialog type={'create'} reload={reloadConfigs} allTenants={allClients} render={(handler: () => void) => <>
                <Box width={'small'} flex={false}><Button primary onClick={handler} label={'Neuer Benutzer'}/></Box>
            </>}/>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableCell>
                            Aktionen
                        </TableCell>
                        <TableCell>
                            Username
                        </TableCell>
                        <TableCell>
                            Vorname
                        </TableCell>
                        <TableCell>
                            Name
                        </TableCell>
                        <TableCell>
                            eMail
                        </TableCell>
                        <TableCell>
                            Kunde
                        </TableCell>
                        <TableCell>
                            Rollen
                        </TableCell>
                        <TableCell>
                            Aktiviert
                        </TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {backofficeUsers == null
                        ? <TableRow><TableCell colSpan={7}>Loading...</TableCell></TableRow>
                        : backofficeUsers.map(user => (
                            <UserRow
                                key={user.username}
                                user={user}
                                allTenants={allClients}
                                reload={reloadConfigs}/>
                        ))}
                </TableBody>
            </Table>
        </>
    )
}

import { Box, Button, Table, TableBody, TableCell, TableHeader, TableRow } from 'grommet'
import React, { useCallback, useEffect, useState } from 'react'
import { UnauthorizedException } from '../../../api/UnauthorizedException'
import { TenantDialog } from './TenantDialog'
import { TenantRow } from './TenantRow'
import {getMyClients, getAllClientsAsSuperuser, TenantType} from '../../../api/tenant'

export const Tenant = () => {
    const [allTenants, setAllTenants] = useState<TenantType[] | null>(null)

    const reloadConfigs = useCallback(async() => {
        try {
            setAllTenants(await getAllClientsAsSuperuser())
        } catch(e) {
            if(e instanceof UnauthorizedException) {
                setAllTenants(null)
            } else if ( e instanceof Error) {
                console.log(e.message)
            } else {
                console.log('Unknown error occured', e)
            }
        }
    }, [])

    useEffect(() => {
        reloadConfigs()
    }, [reloadConfigs])

    return (
        <>
            <h1>Kunden</h1>
            <TenantDialog type={'create'} reload={reloadConfigs} render={(handler: () => void) => <>
                <Box width={'small'} flex={false}><Button primary onClick={handler} label={'Neuer Kunde'}/></Box>
            </>}/>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableCell>
                            Aktionen
                        </TableCell>
                        <TableCell>
                            Kunde
                        </TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {allTenants == null
                        ? <TableRow><TableCell colSpan={7}>Loading...</TableCell></TableRow>
                        : allTenants.map(tenant => (
                            <TenantRow
                                key={tenant.id}
                                tenant={tenant}
                                reload={reloadConfigs}/>
                        ))}
                </TableBody>
            </Table>
        </>
    )
}

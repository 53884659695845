import React from 'react'
import { Box, BoxProps } from 'grommet'

export const Sidebar: React.FC<BoxProps> = (props: BoxProps) => (
    <Box
        overflow={{vertical: 'auto', horizontal: 'hidden'}}
        border={{color: 'background-back', size: 'small', side: 'right'}}
        {...props}
    />
)

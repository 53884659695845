import React from 'react'
import { Avatar as GAvatar, AvatarProps as GAvatarProps } from 'grommet'
import { BoxProps } from 'grommet/components/Box'

export type AvatarProps = BoxProps & GAvatarProps & JSX.IntrinsicElements['div'];

export const Avatar: React.FC<AvatarProps> = (props: AvatarProps) => (
    <GAvatar
        border
        size='medium'
        background='accent-1'
        {...props}
    />
)

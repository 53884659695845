import { Notification, StatusType } from 'grommet'
import React from 'react'

type Props = {
    message: string
    status: StatusType
    time: number | undefined
    expiryDate: Date
}
export const ExpireTimeBasedNotification: React.FC<Props> = (props: Props) => {

    const hasAlertExpired = (): boolean => {
        return props.expiryDate.getTime() < new Date().getTime()
    }

    return (
        !hasAlertExpired() ?
            <Notification
                toast
                title="Notification"
                message={props.message}
                status={props.status}
                time={props.time}
                onClose={() => {}}
            /> : null
    )
}

import { Box, Form, FormField, ResponsiveContext, Text, TextArea, TextInput } from 'grommet'
import { StatusGoodSmall } from 'grommet-icons'
import { AdModel } from '../../../../models/AdModel'
import { AdDetailFacebookPreview } from './AdDetailFacebookPreview'
import React, { useCallback, useEffect, useState } from 'react'
import { BaseButton } from '../../../../components/buttons/BaseButton'
import { PlusCircle, Trash } from 'phosphor-react'
import { required } from '../../../../components/inputs/validation'
import { useAdDetailContext } from '../AdDetailContext'
import { SaveJobBenefitType } from '../../../../api/jobBenefits'

const TEXT_MAX_LENGTH = 300
const TITLE_MAX_LENGTH = 50
const MAX_NUMBER_OF_BENEFITS = 6

type EditTextModel = {
    title: string,
    textHeader?: string,
    textFooter?: string
}

export const AdDetailTextEdit = ({currentAd}: { currentAd: AdModel }) => {
    const [input, setInput] = useState<EditTextModel>({
        title: currentAd.title,
        textHeader: currentAd.textHeader,
        textFooter: currentAd.textFooter
    })
    const {availableJobBenefits, updateCurrentAdAndJobBenefits} = useAdDetailContext()
    const [selectedJobBenefits, setSelectedJobBenefits] = useState<SaveJobBenefitType[]>(currentAd.selectedJobBenefits)

    const onSubmit = () => {
        updateCurrentAdAndJobBenefits(input, selectedJobBenefits)
    }

    useEffect(() => {
        setSelectedJobBenefits(currentAd.selectedJobBenefits)
    }, [currentAd])

    const handleSuggestionSelected = useCallback((suggestion, index) => {
        const newBenefits = selectedJobBenefits.slice()
        newBenefits[index] = suggestion.value
        setSelectedJobBenefits(newBenefits)
    }, [selectedJobBenefits])

    const handleNewBenefitClicked = useCallback(() => {
        if(selectedJobBenefits.length < MAX_NUMBER_OF_BENEFITS) {
            const newBenefits = selectedJobBenefits.concat([{
                jobBenefitId: undefined,
                text: ''
            }])
            setSelectedJobBenefits(newBenefits)
        }
    }, [selectedJobBenefits])

    const handleTextChanged = useCallback((target, index) => {
        const newBenefits = selectedJobBenefits.slice()
        newBenefits[index].text = target.value
        setSelectedJobBenefits(newBenefits)
    }, [selectedJobBenefits])

    const handleRemoveClicked = useCallback((index) => {
        const newBenefits = selectedJobBenefits.slice()
        newBenefits.splice(index, 1)
        setSelectedJobBenefits(newBenefits)
    }, [selectedJobBenefits])

    const suggestions = availableJobBenefits?.filter(ajb =>
        !selectedJobBenefits.find(jb =>
            jb.jobBenefitId === ajb.id)).map(jb => {
        return {label: jb.text, value: jb}
    })

    return <ResponsiveContext.Consumer>
        {screenSize => {
            const isScreenSizeSmall = screenSize === 'small'
            return <Box direction={'row'}
                        gap={'large'}>
                <Box direction={'column'}
                     gap={'small'}
                     flex={'grow'}>
                    <Text color={'dark-4'}>
                        Hier kannst Du Deine Anzeige bearbeiten. <strong>Einfach ein Textfeld anklicken.</strong>
                    </Text>

                    <Form<EditTextModel>
                        value={input}
                        validate={'submit'}
                        onSubmit={onSubmit}
                        onChange={(nextValue: EditTextModel) => {
                            setInput(nextValue)
                        }}>
                        <FormField
                            name='title'
                            validate={required}
                            info={<Text alignSelf={'end'}>{input?.title?.length} / {TITLE_MAX_LENGTH}</Text>}>
                            <TextInput
                                plain
                                name='title'
                                size={'large'}
                                value={input?.title}
                                maxLength={TITLE_MAX_LENGTH}
                            />
                        </FormField>

                        <FormField
                            name='textHeader'
                            maxLength={TEXT_MAX_LENGTH}
                            info={<Text alignSelf={'end'}>{input?.textHeader?.length} / {TEXT_MAX_LENGTH}</Text>}>
                            <TextArea
                                fill
                                plain
                                name='textHeader'
                                size={'large'}
                                resize={false}
                                value={input?.textHeader}
                                maxLength={TEXT_MAX_LENGTH}
                            />
                        </FormField>

                        <Box direction={'column'} margin={{horizontal: 'medium', vertical: 'small'}} gap={'small'}>
                            <Text> Benefits eintragen oder auswählen </Text>
                            {selectedJobBenefits.map((benefit, index) => {
                                return (
                                    <Box key={index}
                                         direction={'row'}
                                         gap={'small'}
                                         align={'center'}
                                         width={'medium'}>
                                        <StatusGoodSmall size={'small'}/>
                                        <FormField width={'medium'}
                                                   margin={'none'}>
                                            <TextInput value={benefit.text}
                                                       onChange={({target}) => handleTextChanged(target, index)}
                                                       suggestions={suggestions}
                                                       onSuggestionSelect={({suggestion}) => handleSuggestionSelected(suggestion, index)}
                                            />
                                        </FormField>
                                        <Trash size={'26px'}
                                               onClick={() => handleRemoveClicked(index)}/>
                                    </Box>
                                )
                            })}
                            <Box direction={'row'}
                                 justify={'end'}
                                 width={'medium'}
                                 gap={'medium'}>
                                <Text>{selectedJobBenefits.length}/{MAX_NUMBER_OF_BENEFITS} Benefits</Text>
                                <PlusCircle size={'26px'}
                                            onClick={handleNewBenefitClicked}/>
                            </Box>

                        </Box>
                        <FormField
                            name='textFooter'
                            maxLength={TEXT_MAX_LENGTH}
                            info={<Text alignSelf={'end'}>{input?.textFooter?.length} / {TEXT_MAX_LENGTH}</Text>}>
                            <TextArea
                                fill
                                plain
                                size={'large'}
                                resize={false}
                                name='textFooter'
                                value={input?.textFooter}
                                maxLength={TEXT_MAX_LENGTH}
                            />
                        </FormField>
                        <Box direction={'row'}
                             width={'100%'}
                             justify={'center'}>
                            <BaseButton primary
                                        label={'Änderung übernehmen'}
                                        type={'submit'}/>
                        </Box>
                    </Form>
                </Box>
                {!isScreenSizeSmall && <Box direction={'column'} gap={'small'} width={{min: 'small'}}>
                    <Text>Vorschau</Text>
                    <AdDetailFacebookPreview currentAd={currentAd}/>
                </Box>}
            </Box>
        }}
    </ResponsiveContext.Consumer>
}

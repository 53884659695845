import React, { ReactNode, useEffect, useState } from 'react'
import { CampaignType, getAllCampaigns } from '../api/campaigns'
import { CampaignModel } from '../models/CampaignModel'
import { JobApplicationsContextProvider } from './JobApplicationsContext'

// @ts-ignore
export const CampaignContext = React.createContext<Options>({})

export type StorePropType = {
    children: ReactNode
}

type Options = {
    campaigns: CampaignModel[] | undefined
    currentCampaign: CampaignModel | undefined
    setCurrentCampaign: (campaignModel: CampaignModel) => void
    reloadCampaigns: () => void
}

export const CampaignContextProvider = ({children}: StorePropType) => {
    const [currentCampaign, setCurrentCampaign] = useState<CampaignModel>()
    const [campaigns, setCampaigns] = useState<CampaignModel[] | undefined>(undefined)

    function currentCampaignChanged(campaign: CampaignModel) {
        setCurrentCampaign(campaign)
    }

    useEffect(() => {
        reloadCampaigns()
    }, [setCampaigns])

    const reloadCampaigns = () => {
        getAllCampaigns().then(response => {
            if(!response.campaigns) return

            const campaignModels = response.campaigns.map(mapCampaignDtoToModel)
            setCampaigns(campaignModels)
            currentCampaignChanged(campaignModels[0])
        })
    }

    return (
        <CampaignContext.Provider value={{
            campaigns,
            reloadCampaigns,
            currentCampaign,
            setCurrentCampaign: currentCampaignChanged,
        }}>
            <JobApplicationsContextProvider>
                {children}
            </JobApplicationsContextProvider>
        </CampaignContext.Provider>
    )
}

function mapCampaignDtoToModel(dto: CampaignType): CampaignModel {
    return {
        id: dto.id,
        name: dto.name,
        description: dto.description,
        avatarUrl: dto.avatarUrl,
        //TODO openTaskCount and applicantCreditCount should be loaded separately
        openTaskCount: 1,
        applicantCreditCount: 2,
    }
}

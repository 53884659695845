import { Box, List, RadioButton, Text } from 'grommet'
import { AdModel } from '../../../../models/AdModel'
import React, { useCallback, useEffect, useState } from 'react'
import { UploadDropZone } from '../../../../components/media/UploadDropZone'
import { useCurrentCampaign } from '../../../../hooks/useCurrentCampaign'
import { deleteMedia, MediaDTO, videosForReferredId } from '../../../../api/media'
import { VideoPlayer } from '../../../../components/media/VideoPlayer'
import { useAdDetailContext } from '../AdDetailContext'
import { Trash } from 'phosphor-react'

export const AdDetailVideos = ({currentAd}: { currentAd: AdModel }) => {
    const campaign = useCurrentCampaign()
    const {updateCurrentAdVideo} = useAdDetailContext()
    const [mediaDTOs, setMediaDTOs] = useState<MediaDTO[] | undefined>()

    useEffect(() => {
        if(campaign) {
            videosForReferredId(campaign.id).then(response => {
                response && setMediaDTOs(response)
            })
        }
    }, [campaign])

    const handleUpload = useCallback((response: MediaDTO) => {
        setMediaDTOs(mediaDTOs?.concat([response]))
    }, [setMediaDTOs, mediaDTOs])

    const handleDelete = useCallback((id) => {
        deleteMedia(id).then(() => {
            setMediaDTOs(mediaDTOs?.filter(data => data.id !== id))
        })
    }, [mediaDTOs])

    return <Box height={{min: 'auto'}}
                direction={'column'}
                gap={'medium'}>
        <UploadDropZone accept={'video/*'}
                        subTitle={'(Es werden nur Videos vom Typ *.mp4 akzeptiert)'}
                        handleUpload={handleUpload}/>
        <Box direction={'column'}>
            <Text>Wählen Sie ein Video für Ihre Stellenanzeige aus:</Text>
            <List data={mediaDTOs}
                  onClickItem={({item}: { item?: MediaDTO }) => {
                      item && updateCurrentAdVideo(item.id)
                  }}
                  action={(item) => (
                      currentAd.video?.id !== item.id ?
                          <Trash size={'24px'}
                                 onClick={(event) => {
                                     event.stopPropagation()
                                     handleDelete(item.id)
                                 }}/> : null
                  )}>
                {(data: MediaDTO) => (
                    <Box direction={'row'} gap={'medium'} align={'center'}>
                        <RadioButton name={''} checked={currentAd.video?.id === data.id} readOnly/>
                        <Box width={'small'}><VideoPlayer src={data.url} type={data.mediaType}/></Box>
                        <Text>Dateiname: {data.fileName}</Text>
                    </Box>
                )}
            </List>
        </Box>
    </Box>
}

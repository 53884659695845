import React from 'react'
import { BasePage, PageWithBreadcrumbsProps } from '../../../components/layout/BasePage'
import { ApplicantDetailContent } from './ApplicantDetailContent'

export const ApplicantDetailPage: React.FC<PageWithBreadcrumbsProps> = ({crumbs}: PageWithBreadcrumbsProps) => {
    return (
        <BasePage crumbs={crumbs} title='Bewerberdetails' noPad>
            <ApplicantDetailContent/>
        </BasePage>
    )
}

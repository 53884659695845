import React, { useState } from 'react'
import { Map, SearchAdvanced } from 'grommet-icons'
import { Box, TextInput } from 'grommet'
import PlacesAutocomplete from 'react-places-autocomplete'
import { BaseButton } from '../../../components/buttons/BaseButton'

type GeoTargetSearchInputProps = {
    onResultSelected: (addressString: string) => void
}

export const GeoTargetSearchInput = (input: GeoTargetSearchInputProps) => {

    const [searchInput, setSearchInput] = useState<string>('')

    const handleSelect = (address: string, placeId: string) => {
        setSearchInput(address)
        input.onResultSelected(address)
    }

    return (
        <Box pad='xsmall' direction='row' gap='small' background={'background-back'}>
            <PlacesAutocomplete
                value={searchInput}
                onChange={setSearchInput}
                onSelect={handleSelect}
            >
                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                    <Box width='large'>
                        <Box direction='row' align='center' pad={{'horizontal': 'small'}}
                             gap='small'>
                            <SearchAdvanced/>
                            <TextInput
                                {...getInputProps({
                                    className: 'location-search-input',
                                })}
                                plain
                                size='medium'
                                placeholder='Hier eine Adresse eintragen...'
                            />
                            <BaseButton primary label={'Anzeigen'} size={'medium'}
                                        icon={<Map color='white' size='small'/>}
                                        onClick={() => input.onResultSelected(searchInput)}/>
                        </Box>

                        <Box className='autocomplete-dropdown-container' gap={'xxsmall'}>
                            {loading && <Box pad={'small'} background={'white'}>Loading...</Box>}
                            {suggestions.map((suggestion, index) => {
                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                                return (
                                    <Box {...getSuggestionItemProps(suggestion, {className})} key={index}>
                                        <Box background={suggestion.active ? 'background-selected' : 'white'}
                                             pad={'small'}>
                                            {suggestion.description}
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                )}
            </PlacesAutocomplete>
        </Box>
    )
}

export class NoPermissionException {

    message: string
    name: string

    constructor(message: string) {
        this.message = message
        this.name = 'NoPermissionException'
    }
}

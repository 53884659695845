import * as React from 'react'
import { Box, Heading, Text } from 'grommet'
import { unovisionTheme } from '../../../../theme/theme'
import { BaseButton } from '../../../../components/buttons/BaseButton'
import { FormContactModel } from '../../../../models/ContactModel'

type DataPrivacyOfficerModalListProps = {
    dataPrivacyOfficers: FormContactModel[],
    onEditClicked: (value: FormContactModel) => void,
    onRemoveClicked: (value: FormContactModel) => void
}

export const DataPrivacyOfficerModalList: React.FC<DataPrivacyOfficerModalListProps> = ({
                                                                                            dataPrivacyOfficers,
                                                                                            onEditClicked,
                                                                                            onRemoveClicked
                                                                                        }) => {
    return (
        <Box margin={{top: 'medium'}} gap={'small'}>
            <Heading level={4}>In dieser Anzeigen-Region enthalten ({dataPrivacyOfficers.length}):</Heading>
            {dataPrivacyOfficers.map((dataPrivacyOfficer, index) => {
                return <Box key={index}
                            background={unovisionTheme.global.colors['background-light-blue']}
                            round={'small'}
                            pad={'medium'}
                            gap={'small'}>
                    <Box direction={'row'}
                         justify={'between'}>
                        <Box>
                            <Text weight={'bold'}>{dataPrivacyOfficer.firstname} {dataPrivacyOfficer.lastname}</Text>
                            <Text>{dataPrivacyOfficer.email}</Text>
                            <Text>{dataPrivacyOfficer.phone}</Text>
                        </Box>
                        <Box width={'50%'}>
                            <Text weight={'bold'}>{dataPrivacyOfficer.companyName}</Text>
                            <Text>{dataPrivacyOfficer.street} {dataPrivacyOfficer.streetNumber}</Text>
                            <Text>{dataPrivacyOfficer.addressAddition}</Text>
                            <Text>{dataPrivacyOfficer.postcode} {dataPrivacyOfficer.city}</Text>
                        </Box>
                    </Box>
                    <Box direction={'row'} justify={'end'} gap={'small'}>
                        <BaseButton label={'Ändern'}
                                    onClick={() => onEditClicked(dataPrivacyOfficer)}/>
                        <BaseButton label={'aus Region entfernen'}
                                    onClick={() => onRemoveClicked(dataPrivacyOfficer)}/>
                    </Box>
                </Box>
            })}
        </Box>
    )
}

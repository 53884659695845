import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { MediaDTO, uploadMedia } from '../../api/media'
import { useCurrentCampaign } from '../../hooks/useCurrentCampaign'
import { Heading } from '../text/Heading'
import { BaseButton } from '../buttons/BaseButton'
import { Box, Meter, Text } from 'grommet'
import { CloudArrowUp } from 'phosphor-react'
import { LoadingOverlay } from '../layout/LoadingOverlay'

const errorMessagesMap: { [key: string]: string; } = {
    'file-invalid-type': 'Die Datei hat einen ungültigen Dateityp',
    'file-too-large': 'Die Datei ist zu groß',
    'too-many-files': 'Zu viele Dateien'
}

const getColor = (props: any) => {
    if(props.isDragAccept) {
        return '#00e676'
    }
    if(props.isDragReject) {
        return '#ff1744'
    }
    if(props.isDragActive) {
        return '#2196f3'
    }
    return '#eeeeee'
}

const Container = styled(Box)`
  border-color: ${props => getColor(props)};
  border-style: dashed;
`

type UploadDropZoneProps = {
    accept: string | string[],
    subTitle: string,
    handleUpload: (response: MediaDTO) => void
}

export const UploadDropZone = ({accept, subTitle, handleUpload}: UploadDropZoneProps) => {
    const currentCampaign = useCurrentCampaign()
    const [isUploading, setUploading] = useState(false)
    const [progress, setProgress] = useState()

    const handleProgress = useCallback((progress) => {
        setProgress(progress)
    }, [])

    const onDrop = useCallback(files => {
        if(currentCampaign) {
            setUploading(true)
            files.forEach((file: any) => {
                uploadMedia(currentCampaign.id, file, handleProgress).then(response => {
                        if(response) {
                            handleUpload(response)
                        }
                        setUploading(false)
                        setProgress(undefined)
                    }
                ).catch(error => {
                    console.log(error)
                    setUploading(false)
                    setProgress(undefined)
                })
            })
        }
    }, [currentCampaign, handleUpload, setUploading, handleProgress])

    const {
        open,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        fileRejections,
    } = useDropzone({
        onDropAccepted: onDrop,
        accept: accept,
        maxFiles: 1,
        maxSize: 524288000,
        noClick: true
    })

    return (
        <Container direction={'column'}
                   align={'center'}
                   pad={'medium'}
                   round={'small'}
                   background={'background-light-blue'}
                   gap={'small'}
                   {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
            <input {...getInputProps()} />
            <Heading level={3}>Dateien hier ablegen, oder "Datei hochladen" klicken.</Heading>
            <CloudArrowUp size={'48px'}/>
            <BaseButton onClick={open} label={'Datei hochladen'}/>
            <em>{subTitle}</em>
            {fileRejections && fileRejections.map(({file, errors}) => (
                errors.map((error, index) => (
                    <Text color={'status-error'} key={index}>{errorMessagesMap[error.code]}: {file.name}</Text>
                ))
            ))}
            <LoadingOverlay visible={isUploading}/>
            {progress && <Meter value={progress}/>}
        </Container>
    )
}

import { Box, Heading } from 'grommet'
import { PadType } from 'grommet/utils'
import React, { ReactNode, useRef } from 'react'
import { LoadingOverlay } from '../layout/LoadingOverlay'

export type LabeledFormDialogProps = {
    label: ReactNode;
    children: ReactNode;
    width?: string;
    loading?: boolean;
    pad?: PadType;
}

export const LabeledFormDialog = ({label, children, width, loading, pad}: LabeledFormDialogProps) => {

    const boxRef = useRef<HTMLDivElement | null>(null)

    return (
        <Box ref={boxRef} width={width || 'xlarge'} pad={pad || 'medium'} direction={'column'} overflow={'auto'}>
            {loading && <LoadingOverlay targetRef={boxRef}/>}
            <Heading size={'small'} margin={{
                bottom: 'medium',
                top: 'none'
            }}>{label}</Heading>
            {children}
        </Box>
    )
}

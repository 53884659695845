import React from 'react'
import { Box, ResponsiveContext } from 'grommet'
import { ChatCircle, House, ListBullets, UserList } from 'phosphor-react'
import { MainNavigationItem } from './MainNavigationItem'
import { useCurrentCampaign } from '../../hooks/useCurrentCampaign'
import { useShowJobApplicationsBadge } from '../../hooks/useJobApplications'
import {useShowMessagesBadge} from "../../hooks/useMessages";

export const MainNavigation = () => {
    const currentCampaign = useCurrentCampaign()
    const showJobApplicationBadge = useShowJobApplicationsBadge()
    const showMessagesBadge = useShowMessagesBadge()

    return (
        <ResponsiveContext.Consumer>
            {size => {
                const isScreenSizeSmall = size === 'small'
                const iconSize = isScreenSizeSmall ? 24 : 38
                return <Box
                    pad='small'
                    background='brand'
                    align='center'
                    justify='center'
                    direction='row'
                    flex={false}
                    gap={isScreenSizeSmall ? 'xxsmall' : 'medium'}
                >
                    <MainNavigationItem
                        label='Home'
                        to={`/campaign/${currentCampaign?.id}/dashboard`}
                        icon={<House size={iconSize}/>}
                    />
                    <MainNavigationItem
                        label='Stellenanzeigen'
                        icon={<ListBullets size={iconSize}/>}
                        to={`/campaign/${currentCampaign?.id}/advertisements`}
                    />
                    <MainNavigationItem
                        showBadge={showJobApplicationBadge}
                        label='Bewerbungen'
                        icon={<UserList size={iconSize}/>}
                        to={`/campaign/${currentCampaign?.id}/applicant-management`}
                    />
                    <MainNavigationItem
                        showBadge={showMessagesBadge}
                        label='Nachrichten'
                        icon={<ChatCircle size={iconSize}/>}
                        to={`/campaign/${currentCampaign?.id}/messages`}
                    />
                </Box>
            }
            }
        </ResponsiveContext.Consumer>
    )
}

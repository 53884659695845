import { Button, Form, FormField, Layer } from 'grommet'
import React, { useCallback, useState } from 'react'
import { UnauthorizedException } from '../../../api/UnauthorizedException'
import { LabeledFormDialog } from '../../../components/common/LabeledFormDialog'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { required } from '../../../components/inputs/validation'
import { ControlButtons } from '../../../components/buttons/ControlButtons'
import {createTenant, TenantData} from '../../../api/tenant'

type FormTypes = 'edit' | 'create'

interface FormProps {
    onHide: () => void
    reload: () => void
    type: FormTypes
    formValues?: TenantData
}

const buildInitialValueFromFormValues = (formValues: TenantData) => ({
    ...formValues,
})

const buildFlatFormValues = (formValues: TenantData): TenantData => ({
    ...formValues,
})

const FormView =
    ({
         onHide,
         reload,
         type,
         formValues,
     }: FormProps) => {

        const setCurrentUser = useCurrentUser()[1]

        const [value, setValue] = useState<TenantData>((formValues && buildInitialValueFromFormValues(formValues)) as TenantData)

        const handleSubmit = useCallback(async({value}: { value: TenantData }) => {
            try {
                await createTenant({clientName: value.name})
                reload()
                onHide()
            } catch(e) {
                if(e instanceof UnauthorizedException) {
                    setCurrentUser(null)
                }
            }
        }, [reload, onHide, formValues, setCurrentUser, type])

        const handleCancel = useCallback(async() => {
            setValue((formValues && buildInitialValueFromFormValues(formValues)) as TenantData)
            onHide()
        }, [onHide, formValues])

        return (
            <Layer>
                <LabeledFormDialog label={type === 'edit' ? 'Kunde bearbeiten' : 'Kunde erstellen'}>
                    <Form
                        value={value}
                        onChange={(nextValue: TenantData) => setValue(nextValue)}
                        onSubmit={handleSubmit}
                        onReset={handleCancel}>
                        <FormField name={'name'} label={'Kunde'} validate={required}/>
                        <ControlButtons>
                            <Button type={'submit'} primary label={'Submit'}/>
                            <Button type={'reset'} label={'Cancel'}/>
                        </ControlButtons>
                    </Form>
                </LabeledFormDialog>
            </Layer>
        )
    }

interface DialogProps {
    reload: () => void
    type: FormTypes
    formValues?: TenantData
    render: (handler: () => void) => JSX.Element
}

export const TenantDialog =
    ({
         reload,
         type,
         formValues,
         render,
     }: DialogProps) => {
        const [isFormVisible, setFormVisible] = useState(false)

        const handleShow = useCallback(() => {
            setFormVisible(!isFormVisible)
        }, [setFormVisible, isFormVisible])

        return (
            <>
                {isFormVisible && (
                    <FormView formValues={formValues} type={type} onHide={handleShow} reload={reload}/>
                )}
                {render(handleShow)}
            </>
        )
    }

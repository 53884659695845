import React, {useEffect, useState} from 'react'
import {getJobApplicationConversationUrl} from "../../../api/jobApplications";
import {BaseButton} from "../../../components/buttons/BaseButton";
import {Box, Text} from "grommet";

type ApplicantChatComponentProps = {
    applicantId: string
}

export const ApplicantChatComponent = (props: ApplicantChatComponentProps) => {

    const [isDataLoadingFinished, setIsDataLoadingFinished] = useState<boolean>(false)
    const [conversationUrl, setConversationUrl] = useState<string | undefined>(undefined)
    const [takeover, setTakeover] = useState<boolean>(false)

    useEffect(() => {
        setIsDataLoadingFinished(false)
        loadConversationUrl(props.applicantId, takeover)
            .finally(() => setIsDataLoadingFinished(true))
    }, [takeover, props.applicantId])

    const loadConversationUrl = async(applicantId: string, takeover?: boolean) => {
        try {
            const conversationUrlResponse = await getJobApplicationConversationUrl(applicantId, takeover)
            setConversationUrl(conversationUrlResponse.conversationUrl)
        } catch (e) {
            console.log("Something went wrong while retrieving conversation URL for applicant", e)
        }
    }

    if (!isDataLoadingFinished) return <>Loading...</>

    if (!conversationUrl) return <>Fehler beim laden des Chats</>

    return (
        <Box pad={"none"} gap={"medium"} style={{maxWidth: "304px"}}>
            {!takeover &&
                <Box gap={"medium"}>
                    <BaseButton label={"Chat als Operator übernehmen"} onClick={() => setTakeover(true)} alignSelf={"start"}/>
                    <Text size={"small"} >Wenn Sie als Operator übernehmen wird ein aktuell laufender Chatbot ggf. unterbrochen. Der Bewerber muss ab diesem Punkt manuell betreut und befragt werden. Im Operatormodus überschreiben Sie sozusagen den Chatbot und übernehmen selbst.</Text>
                </Box>
            }
            <div style={{height: '70vh'}}>
                <iframe src={conversationUrl} height={'100%'} width={'100%'} referrerPolicy={"no-referrer"}/>
            </div>
        </Box>
    )
}

import {unovisionClient} from './unovisionClient'
import {CAMPAIGN_CONTEXT_URL} from './campaigns'
import {JobApplicationStatusType} from '../ui/jobApplications/JobApplicationStatus'
import {JobApplicationStepType} from '../ui/jobApplications/JobApplicationSteps'
import {JobType} from './jobs'

const CONTEXT_URL = '/job-application'
const API_CONTEXT_URL = '/api' + CONTEXT_URL


export type JobApplicationType = {
    id: string,
    campaignId: number,
    job?: JobType,
    isFavorite: boolean,
    applicant: ApplicantDetails,
    applicationTimestamp: string,
    applicationStatus: JobApplicationStatusType,
    lastApplicantActivityTimestamp: string
}

type JobApplicationResponseType = {
    jobApplications: JobApplicationType[]
}

export type ApplicantDetails = {
    id: string,
    firstName?: string,
    lastName?: string,
    phone?: string,
    email?: string
    description?: string,
    avatarUrl?: string,
}

export type JobApplicationStatusResponse = {
    status: JobApplicationStatusType
}

export type JobApplicationRecordedStepType = {
    jobApplicationStep: JobApplicationStepType
    timestamp: string
}

export type JobApplicationRecordedStepsType = {
    jobApplicationId: string,
    jobApplicationRecordedSteps: JobApplicationRecordedStepType[]
}


export const getJobApplicationsByCampaignId = async(campaignId: number): Promise<JobApplicationResponseType> => {
    const response = await unovisionClient.get<JobApplicationResponseType>(`${CAMPAIGN_CONTEXT_URL}/${campaignId}${CONTEXT_URL}`)
    return response.data
}

export const getJobApplication = async(jobAppId: string): Promise<JobApplicationType> => {
    const response = await unovisionClient.get<JobApplicationType>(`${API_CONTEXT_URL}/${jobAppId}`)
    return response.data
}

export const getJobApplicationRecordedSteps = async(jobApplicationId: string): Promise<JobApplicationRecordedStepsType> => {
    const response = await unovisionClient.get<JobApplicationRecordedStepsType>(`${API_CONTEXT_URL}/${jobApplicationId}/step`)
    return response.data
}

export const addJobApplicationStep = async(jobApplicationId: string, stepToRecord: JobApplicationStepType) => {
    await unovisionClient.post<JobApplicationStepType>(`${API_CONTEXT_URL}/${jobApplicationId}/step`, {
        stepToRecord: stepToRecord
    })
}

export const getJobApplicationStatus = async(jobApplicationId: string): Promise<JobApplicationStatusType> => {
    const response = await unovisionClient.get<JobApplicationStatusResponse>(`${API_CONTEXT_URL}/${jobApplicationId}/status`)
    return response.data.status
}

export const setJobApplicationIsFavorite = async(jobAppId: string, isFavorite: boolean): Promise<JobApplicationType> => {
    const response = await unovisionClient.put<JobApplicationType>(`${API_CONTEXT_URL}/${jobAppId}`, {
        isFavorite: isFavorite
    })
    return response.data
}

export type JobApplicationConversationUrlResponse = {
    conversationUrl: string
}

export const getJobApplicationConversationUrl = async(jobApplicationId: string, takeover?: boolean): Promise<JobApplicationConversationUrlResponse> => {
    let url = `${API_CONTEXT_URL}/${jobApplicationId}/conversation-url`
    if(takeover === true) {
        url += "?takeover=true"
    }
    const response = await unovisionClient.get<JobApplicationConversationUrlResponse>(url)
    return response.data
}

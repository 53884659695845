import { unovisionClient } from './unovisionClient'

const CONTEXT_URL = '/api/users'

const CONTEXT_PROFILE_URL = '/api/profile'

export interface ProfileType {
    username: string;
    firstname: string;
    name: string;
    email: string;
    password?: string;
}

export interface UserType extends ProfileType {
    clientId?: string;
    confirmation_token?: string;
    enabled: boolean;
    roles: Array<string>;
}

export const getAllUsers = async(): Promise<UserType[]> => {
    const response = await unovisionClient.get<UserType[]>(CONTEXT_URL)

    return response.data
}

export const deleteUser = async(username: string): Promise<void> => {
    await unovisionClient.delete(`${CONTEXT_URL}/${username}`)
}

export const updateUser = async(user: UserType): Promise<void> => {
    const response = await unovisionClient.put(CONTEXT_URL, {
        ...user,
        password: user.password || ''
    })

    return response.data
}

export const createUser = async(user: UserType): Promise<void> => {
    const response = await unovisionClient.post(CONTEXT_URL, user)
    return response.data
}

export const getProfile = async(): Promise<ProfileType> => {
    const response = await unovisionClient.get<ProfileType>(CONTEXT_PROFILE_URL)

    return {
        ...response.data,
        password: '',
    }
}


export const updateProfile = async(profile: ProfileType): Promise<void> => {
    await unovisionClient.patch<ProfileType>(CONTEXT_PROFILE_URL, {
        ...profile,
        password: profile.password || '',
    })
}

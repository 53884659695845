import React from 'react'
import { Medal } from 'phosphor-react'
import { Box, ResponsiveContext, Text, } from 'grommet'
import { JobApplicationType } from '../../api/jobApplications'
import { JobApplicationListItemContainer } from './JobApplicationListItemContainer'
import { unovisionTheme } from '../../theme/theme'

type JobApplicationListContainerProps = {
    organizedJobApplications: JobApplicationType[]
    handleVisitApplicantDetail: (jobApplicationId: string) => void
}

export const JobApplicationListContainer = ({
                                                organizedJobApplications,
                                                handleVisitApplicantDetail
                                            }: JobApplicationListContainerProps) => <>
    {organizedJobApplications && organizedJobApplications.length > 0 ?
        <ResponsiveContext.Consumer>
            {screenSize => {
                const isScreenSizeSmall = screenSize === 'small'
                return (
                    <Box gap={isScreenSizeSmall ? 'xlarge' : 'large'} pad={{top: `${isScreenSizeSmall ? 'large' : 'medium'}`}}>
                        {organizedJobApplications.map((jobApplication, idx) =>
                            <JobApplicationListItemContainer
                                key={idx}
                                jobApplication={jobApplication}
                                onClick={() => handleVisitApplicantDetail(jobApplication.id)}
                            />
                        )}
                    </Box>
                )
            }}
        </ResponsiveContext.Consumer>
        : <Box
            margin={{top: 'medium'}}
            border={{color: 'light-3', size: 'small'}}
            round='xsmall'
            justify='center'
            align='center'
            height='medium'
            background='light-2'
            gap='medium'
        >
            <Medal size={64} color={unovisionTheme.global.colors['dark-6']}/>
            <Text size='large' color='dark-6'>Nichts zu tun hier</Text>
        </Box>
    }
</>

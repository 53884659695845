import { useContext } from 'react'
import { JobApplicationsContext } from '../store/JobApplicationsContext'

export const useJobApplications = () => {
    return useContext(JobApplicationsContext).jobApplications
}

export const useUpdateJobApplications = () => {
    return useContext(JobApplicationsContext).updateJobApplications
}

export const useShowJobApplicationsBadge = () => {
    return useContext(JobApplicationsContext).showNotificationBadge
}

import React, {useCallback, useEffect, useState} from 'react'
import {Prompt} from 'react-router-dom'
import {Box} from 'grommet'
import DataPrivacySideBar from './DataPrivacySideBar'
import {
    createDataPrivacy as createDataPrivacyOnBackend,
    CreateDataPrivacyType,
    DataPrivacyDTOType,
    DataPrivacyResponseType,
    getDataPrivacyByCampaignId,
    updateDataPrivacy as updateDataPrivacyOnBackend
} from '../../../api/campaignManagement'
import {DataPrivacyOfficerModal} from './modals/DataPrivacyOfficerModal'
import {CampaignModel} from '../../../models/CampaignModel'
import {DataPrivacyMarkdown} from './markdown/DataPrivacyMarkdown'
import {LoadingOverlay} from '../../../components/layout/LoadingOverlay'
import {AddressModel} from '../../../models/AddressModel'
import {ContactModel} from '../../../models/ContactModel'
import {getCampaignSubsidiaries} from '../../../api/campaigns'
import DataProcessingDescriptionModal from './modals/DataProcessingDescriptionModal'
import {AddressSelectionModal} from './modals/AddressSelectionModal'
import {getDataPrivacyProperties} from "../../../helpers/dataPrivacyProperties";

export type SelectedDataPrivacyLawType = 'DSGVO_GV' | 'DSGVO_AV' | 'KDG_GV' | 'KDG_AV' | 'DSG_EKD_GV' | 'DSG_EKD_AV'
const selectedDataPrivacyLawInitialState: SelectedDataPrivacyLawType = "DSGVO_AV"

export type DataPrivacyTypeProperty = {
    requiredAmountOfMainAddresses: number,
    hasOptionalProcessingDescription: boolean,
}
export const defaultDataPrivacyProperties: DataPrivacyTypeProperty = {
    requiredAmountOfMainAddresses: 1,
    hasOptionalProcessingDescription: false,
}
export const dataPrivacyProperties: Map<SelectedDataPrivacyLawType, Partial<DataPrivacyTypeProperty>> = new Map([
    ["DSGVO_GV", {
        requiredAmountOfMainAddresses: 2,
    }],
    ["KDG_GV", {
        requiredAmountOfMainAddresses: 2,
        hasOptionalProcessingDescription: true,
    }],
    ["DSG_EKD_GV", {
        requiredAmountOfMainAddresses: 2,
    }],
])

type DataPrivacyContentProps = {
    currentCampaign: CampaignModel
}

export const DataPrivacyContent: React.FC<DataPrivacyContentProps> = ({currentCampaign}: DataPrivacyContentProps) => {
    const [storedData, setStoredData] = useState<DataPrivacyResponseType>()

    const [selectedDataPrivacyLaw, setSelectedDataPrivacyLaw] = useState<SelectedDataPrivacyLawType>(selectedDataPrivacyLawInitialState)
    const [subsidiaries, setSubsidiaries] = useState<AddressModel[]>([])
    const [campaignMainAddresses, setCampaignMainAddresses] = useState<AddressModel[] | undefined>(undefined)
    const [dataPrivacyOfficers, setDataPrivacyOfficers] = useState<ContactModel[]>([])
    const [dataProcessingDescription, setDataProcessingDescription] = useState<string | undefined>(undefined)

    const [isDataPrivacySet, setIsDataPrivacySet] = useState<boolean>(false)

    const [isOfficerModalOpen, setOfficerModalOpen] = useState(false)
    const [isDataProcessingDescriptionModalOpen, setIsDataProcessingDescriptionModalOpen] = useState(false)
    const [isMainAddressSelectionModalOpen, setIsMainAddressSelectionModalOpen] = useState<boolean>(false)

    const [hasDataChanged, setHasDataChanged] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    // Loads Data from BE and initializes the states
    useEffect(() => {

        currentCampaign && Promise.all([getDataPrivacyByCampaignId(currentCampaign.id), getCampaignSubsidiaries(currentCampaign.id, true)])
            .then(([dataPrivacy, subsidiaries]) => {
                setStoredData(dataPrivacy)
                setSubsidiaries(subsidiaries)
                if (dataPrivacy.dataPrivacyDTO) {
                    setSelectedDataPrivacyLaw(dataPrivacy.dataPrivacyDTO.selectedDataPrivacyLaw as SelectedDataPrivacyLawType)
                    setDataPrivacyOfficers(dataPrivacy.dataPrivacyDTO.dataPrivacyOfficers || [])
                    setDataProcessingDescription(dataPrivacy.dataPrivacyDTO.dataProcessingDescription)
                    setCampaignMainAddresses(dataPrivacy.dataPrivacyDTO.campaignMainAddresses || [])
                    setIsDataPrivacySet(true)
                }
                setIsLoaded(true)
            })
    }, [currentCampaign])

    // Checks if user has changed anything
    useEffect(() => {
        const subsidiariesHaveChanged = JSON.stringify(storedData?.dataPrivacyDTO?.subsidiaries || []) !== JSON.stringify(subsidiaries)
        const dataPrivacyOfficersHaveChanged = JSON.stringify(storedData?.dataPrivacyDTO?.dataPrivacyOfficers || []) !== JSON.stringify(dataPrivacyOfficers);
        const dataPrivacyDescriptionHaveChanged = hasStringChanged(storedData?.dataPrivacyDTO?.dataProcessingDescription, dataProcessingDescription);
        const selectedPrivacyLawHaveChanged = JSON.stringify(storedData?.dataPrivacyDTO?.selectedDataPrivacyLaw) !== undefined && JSON.stringify(storedData?.dataPrivacyDTO?.selectedDataPrivacyLaw) !== JSON.stringify(selectedDataPrivacyLaw);
        const campaignMainAddressesHaveChanged = JSON.stringify(storedData?.dataPrivacyDTO?.campaignMainAddresses?.map(address => address.id) || []) !== JSON.stringify(campaignMainAddresses?.map(address => address.id));

        setHasDataChanged(subsidiariesHaveChanged || dataPrivacyOfficersHaveChanged || selectedPrivacyLawHaveChanged || dataPrivacyDescriptionHaveChanged || campaignMainAddressesHaveChanged)
    }, [dataPrivacyOfficers, subsidiaries, selectedDataPrivacyLaw, dataProcessingDescription, storedData, campaignMainAddresses])

    const hasStringChanged = (originalString: string | undefined, newString: string | undefined): boolean => {
        originalString = originalString ?? ''
        newString = newString ?? ''

        return originalString.localeCompare(newString) != 0
    }

    // Modal Toggle Functions
    const toggleOfficerModal = useCallback(() => {
        setOfficerModalOpen(!isOfficerModalOpen)
    }, [isOfficerModalOpen])

    const toggleProcessSectionModal = () => {
        setIsDataProcessingDescriptionModalOpen(!isDataProcessingDescriptionModalOpen)
    }

    const handleCloseMainAddressModal = async (mainAddresses: AddressModel[]) => {
        setIsMainAddressSelectionModalOpen(false)
        setCampaignMainAddresses(mainAddresses)
    }

    const updateDataPrivacy = () => {
        const dataPrivacyPayload: DataPrivacyDTOType = {
            id: storedData!.dataPrivacyDTO!.id,
            campaignId: currentCampaign.id,
            selectedDataPrivacyLaw: selectedDataPrivacyLaw,
            dataPrivacyOfficers: dataPrivacyOfficers,
            dataProcessingDescription: dataProcessingDescription,
            subsidiaries: subsidiaries,
            campaignMainAddresses: campaignMainAddresses,
        }
        updateDataPrivacyOnBackend(currentCampaign.id, dataPrivacyPayload).then((res) => {
                setStoredData(res)
                setDataPrivacyOfficers(res.dataPrivacyDTO!!.dataPrivacyOfficers || [])
                setDataProcessingDescription(res.dataPrivacyDTO!!.dataProcessingDescription)
                setSubsidiaries(res.dataPrivacyDTO!!.subsidiaries || [])
                setCampaignMainAddresses(res.dataPrivacyDTO?.campaignMainAddresses || [])
            }
        ).catch((err) => console.log('Error while saving', err))
    }

    const createDataPrivacy = () => {
        const dataPrivacyPayload: CreateDataPrivacyType = {
            campaignId: currentCampaign.id,
            selectedDataPrivacyLaw: selectedDataPrivacyLaw,
            dataPrivacyOfficers: dataPrivacyOfficers,
            dataProcessingDescription: dataProcessingDescription,
            subsidiaries: subsidiaries,
            campaignMainAddresses: campaignMainAddresses,
        }
        createDataPrivacyOnBackend(currentCampaign.id, dataPrivacyPayload).then((res) => {
                setStoredData(res)
                setDataPrivacyOfficers(res.dataPrivacyDTO!!.dataPrivacyOfficers || [])
                setDataProcessingDescription(res.dataPrivacyDTO!!.dataProcessingDescription)
                setSubsidiaries(res.dataPrivacyDTO!!.subsidiaries || [])
                setCampaignMainAddresses(res.dataPrivacyDTO?.campaignMainAddresses || [])
                setIsDataPrivacySet(true)
            }
        ).catch((err) => console.log('Error while saving', err))
    }

    const onSave = () => {
        if (isDataPrivacySet) {
            updateDataPrivacy()
        } else {
            createDataPrivacy()
        }
    }

    const hasCampaignMainAddress = (): boolean => {
        const currentAmountOfMainAddresses = campaignMainAddresses?.length ?? 0
        return currentAmountOfMainAddresses >= getDataPrivacyProperties(selectedDataPrivacyLaw).requiredAmountOfMainAddresses
    }

    const hasDataPrivacyDescription = (): boolean | undefined => {
        if (!getDataPrivacyProperties(selectedDataPrivacyLaw).hasOptionalProcessingDescription) return

        return dataProcessingDescription !== undefined && dataProcessingDescription !== ""
    }

    const getCampaignMainAddressesIds = () => {
      return campaignMainAddresses?.map(address => address.id!)
    }

    return (
        <>
            {isLoaded &&
            <Box direction='row' justify='around' wrap>
                <DataPrivacySideBar
                    hasCampaignMainAddress={hasCampaignMainAddress()}
                    hasDataProcessingDescription={hasDataPrivacyDescription()}
                    hasDataPrivacyOfficer={dataPrivacyOfficers.length > 0}
                    hasDataChanged={hasDataChanged || !isDataPrivacySet}
                    selectedDataPrivacyLaw={selectedDataPrivacyLaw}
                    setDataPrivacyLaw={setSelectedDataPrivacyLaw}
                    openOfficerModal={() => toggleOfficerModal()}
                    openMainAddressModal={() => setIsMainAddressSelectionModalOpen(!isMainAddressSelectionModalOpen)}
                    openDataProcessingDescriptionModal={toggleProcessSectionModal}
                    onSave={onSave}
                    saveButtonText={!isDataPrivacySet? 'Datenschutzerklärung anlegen' : undefined}
                />
                <Box flex={"grow"} direction='row' justify='around'>
                    <Box width='large' elevation='medium' round='small' pad={{'horizontal': 'large', 'vertical': 'small'}}>
                        <DataPrivacyMarkdown
                            selectedDataPrivacyLaw={selectedDataPrivacyLaw}
                            dataPrivacyOfficers={dataPrivacyOfficers}
                            subsidiaries={subsidiaries}
                            jobList={storedData?.jobList}
                            dataProcessingDescription={dataProcessingDescription}
                            updatedDate={storedData?.dataPrivacyDTO?.updatedDate}
                            toggleOfficerModal={toggleOfficerModal}
                            campaignMainAddresses={campaignMainAddresses}
                            toggleDataProcessingDescriptionModal={toggleProcessSectionModal}
                            toggleMainAddressModal={() => setIsMainAddressSelectionModalOpen(!isMainAddressSelectionModalOpen)}
                        />
                    </Box>
                </Box>
                {
                    isOfficerModalOpen && <DataPrivacyOfficerModal
                        campaignName={currentCampaign.name}
                        dataPrivacyOfficers={dataPrivacyOfficers}
                        setDataPrivacyOfficers={setDataPrivacyOfficers}
                        onEsc={toggleOfficerModal}
                        onClickClose={toggleOfficerModal}
                        onClickOutside={toggleOfficerModal}/>
                }
                {isDataProcessingDescriptionModalOpen &&
                    <DataProcessingDescriptionModal
                        campaignName={currentCampaign.name}
                        dataProcessingDescription={dataProcessingDescription}
                        setDataProcessingDescription={setDataProcessingDescription}
                        onCancel={toggleProcessSectionModal}/>
                }
                {isMainAddressSelectionModalOpen &&
                    <AddressSelectionModal
                        campaignId={currentCampaign.id}
                        preselectedAddressIds={getCampaignMainAddressesIds()}
                        onClose={handleCloseMainAddressModal}
                    />
                }
            </Box>
            }
            <Prompt
                when={hasDataChanged}
                message='Sie haben ungespeicherte Änderungen. Sind Sie sicher, dass Sie diese verwerfen möchten?'
            />
            <LoadingOverlay
                visible={!isLoaded}
            />
        </>
    )
}

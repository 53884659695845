import React from 'react'
import { AdsFilterType } from './AdsContent'
import {
    Funnel,
    Play,
    Pause,
} from 'phosphor-react'
import { ResponsiveSidebar } from '../../components/sidebar/ResponsiveSidebar'
import { ResponsiveSidebarCategory } from '../../components/sidebar/ResponsiveSidebarCategory'
import { ResponsiveSidebarItem } from '../../components/sidebar/ResponsiveSidebarItem'
import { unovisionTheme } from '../../theme/theme'

type AdsSidebarProps = {
    activeFilters: AdsFilterType
    updateFilters: (filter: AdsFilterType) => void
}

export const AdsSidebar = (props: AdsSidebarProps) => {

    return (
        <ResponsiveSidebar icon={<Funnel size={20} color='white'/>}
                           counter={props.activeFilters.active !== undefined ? 1 : undefined}
                           background={props.activeFilters.active !== undefined ? 'orange' : undefined}
                           label='Filter'>
            <ResponsiveSidebarCategory
                icon={<Funnel/>}
                title={'Stellenanzeigen filtern'}
                sidebarItems={[
                    <ResponsiveSidebarItem
                        label={'Online'}
                        icon={<Play
                            color={props.activeFilters.active ? 'white' : unovisionTheme.global.colors.brand.light}/>}
                        active={props.activeFilters.active}
                        onClick={() => props.updateFilters({active: true})}/>,
                    <ResponsiveSidebarItem
                        label={'Offline'}
                        icon={<Pause color={props.activeFilters.active !== undefined &&
                        !props.activeFilters.active ? 'white' : unovisionTheme.global.colors.brand.light}/>}
                        active={props.activeFilters.active !== undefined && !props.activeFilters.active}
                        onClick={() => props.updateFilters({active: false})}/>,
                ]
                }/>
        </ResponsiveSidebar>
    )
}
